import { useSelector, useDispatch } from 'react-redux';
import { Authoring } from '@cw/rule-generator';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import wrColors from '../model/wrColors.json'
import features from "../utility/features.json";

const RenderDT = (props) => {
  const State = useSelector(state => state.rootReducer);
  const dispatch = useDispatch()
  const ruleDetails = {
    userDetails: {
      emailId: State.userDetails.emailId,
      user_id: State.userDetails.user_id
    },
    "RMSid": State.oSelectedDetails.oSelectedRMS.id,
    "RSid": State.oSelectedDetails.oSelectedRS.id,
    "DTid": State.oSelectedDetails.oSelectedDT.id,
    "ruleName": State.oSelectedDetails.oSelectedDT.label,
    "version": "",
    "applicationId": State.oSelectedDetails.oAppDetails.applicationId,
    "token": State.requestOptions.headers?.Authorization?.replaceAll("Bearer", "")?.trim(),
  }
  const NavbackToList = () => {
    let oSelectedDetails = { ...State.oSelectedDetails }
    oSelectedDetails["oSelectedDT"] = "";
    props.setShowDetailsPage(false);
    dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })
    dispatch({
      "type": "SET_DETAILS_VIEW", value: {
        "showRuleChain": false,
        "showRuleEditor": false,
        "showViewRules": true,
      }
    })
  }
  // const features = {
  //   DT_QuickAdd: State.featureFlag.DT_QuickAdd,
  //   DT_MassAdd: State.featureFlag.DT_MassAdd,
  //   DT_StaticForm: State.featureFlag.DT_StaticForm,
  //   DT_Export: State.featureFlag.DT_Export,
  //   DT_Import: State.featureFlag.DT_Import,
  //   DT_FileUpload: State.featureFlag.DT_FileUpload,
  //   DT_ActivityLog: State.featureFlag.DT_ActivityLog,
  //   DT_Delete: State.featureFlag.DT_Delete,
  //   DT_Copy: State.featureFlag.DT_Copy,
  //   DT_Paste: State.featureFlag.DT_Paste,
  //   DT_ChangeLog: State.featureFlag.DT_ChangeLog,
  //   // DT_VariantManagement: State.featureFlag.DT_VariantManagement,
  //   DT_VariantView: State.featureFlag.DT_VariantView,
  //   DT_VariantCreation: State.featureFlag.DT_VariantCreation,
  //   DT_TableView: State.featureFlag.DT_View
  // }
  return <>

    <Breadcrumbs aria-label="breadcrumb" style={{ margin: '0.3rem 0.5rem' }}>
      <Link color="inherit" href="/" className="styleLink">
        {State.oSelectedDetails.oAppDetails.label ? State.oSelectedDetails.oAppDetails.label : State.oSelectedDetails.oAppDetails.name}
      </Link>
      <Link color="inherit" className="styleLink">
        {State.oSelectedDetails.oSelectedRMS.label}
      </Link>
      <Link color="inherit" className="styleLink">
        {State.oSelectedDetails.oSelectedRS.label}
      </Link>
      <Link
        color="textPrimary"
        aria-current="page"
        className="styleLink"
        style={{ color: '#007AD4' }}
      >
        {State.oSelectedDetails.oSelectedDT.label}
      </Link>

    </Breadcrumbs>

    <Authoring colors={wrColors} translationDataObjects={State.worktextResponse} Dtdetails={ruleDetails} destinations={State.destinationsList} NavBackHandler={NavbackToList} features={features} /></>
}
export default RenderDT;