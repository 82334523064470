import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Popover, Accordion, AccordionDetails, AccordionSummary, Link, Tooltip, IconButton, Typography, TextField, InputAdornment } from "@mui/material";
import PropTypes from 'prop-types';
import './cw_wr_sideNavHierachy.css';
import PostOrder from '../textrules/util/cw_tr_postOrder';
import Label from '@mui/icons-material/Label';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RMSicon from '../icons/RMSicon.svg';
//import CustomIcons from "../../icons/CustomIcons";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MessageStrip from './cw_wr_MessageStrip';
import MessageDialog from './cw_wr_MessageDialog';
import fetchWrapper from '../utility/fetchWrapper';
import { applicationProperties } from '../utility/applicationConfig'
const useTreeItemStyles = makeStyles((theme) => ({

    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `#007AD4`,
            color: '#ffffff',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            background: 'transparant'
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        width: '100%'
    },
}));
export function StyledTreeItem(props) {


    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            className='styletreeItem'
            label={
                <div className={classes.labelRoot}>
                    {LabelIcon && <LabelIcon color="inherit" className={classes.labelIcon} />}
                    {/* <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography> */}
                    <p style={{ overflowWrap: 'break-word', margin: 0 }} className={classes.labelText}>
                        {labelText}
                    </p>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
                marginLeft: '6px'

            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};
export default function SideNavHierarchy(props) {
    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer)
    let navigate = useNavigate();
    const [expanded, setExpanded] = React.useState([]);

    const [selected, setSelected] = React.useState([]);
    const [aAttributes, setaAttributes] = React.useState([]);
    const [aConditionFields, setaConditionFields] = React.useState([]);
    const [aActionFields, setaActionFields] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverData, setPopoverData] = React.useState([]);
    const [popoverRLData, setPopoverRuleListData] = React.useState([]);
    const [MessageDialogContent, setMessageDialog] = useState({
        "open": false,
        "data": "",
        "onConfirm": {}
    });
    const [MessageStripData, setMessageStrip] = useState({
        "show": false,
        "message": "",
        "type": ""

    })

    const open = Boolean(anchorEl);
    const useStyles = makeStyles({
        root: {
            height: 216,
            flexGrow: 1,
            maxWidth: 400,
        },
    });
    const classes = useStyles();
    const root = {
        "id": 'root',
        "status": true,
        "statusCode": 200,
        "message": null,
        "totalCount": 0,
        "childs": [...props.data]
    }

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };
    let aLinearRMSlist = PostOrder(root)
    // const fnGetHierarchyIds = (id) => {
    //     let oSelectedDetails = { ...State.oSelectedDetails }
    //     aLinearRMSlist.forEach(each => {
    //         if (each.id === id) {
    //             oSelectedDetails["oSelectedRMS"] = each.RMS
    //             oSelectedDetails["oSelectedRS"] = each
    //         }
    //     })
    //     dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })
    //     return oSelectedDetails
    // }
    const fnGetHierarchyIds = (id) => {
        let isDT = false;
        ;
        let oSelectedDT = []
        let oSelectedRL = []
        let oSelectedDetails = { ...State.oSelectedDetails }
        aLinearRMSlist.forEach(each => {
            if (each.id === id) {

                oSelectedDetails["oSelectedRS"] = each
            }
        })




        aLinearRMSlist.forEach((each) => {
            if (each.id === oSelectedDetails["oSelectedRS"].parentId) {

                oSelectedDetails["oSelectedRMS"] = each

            }
        });
        aLinearRMSlist.forEach((each) => {
            if (each.entity === "Decision Table" && each.parentId === oSelectedDetails["oSelectedRS"].id)

                oSelectedDT.push(each);


            // else if (each.entity === "Rule List" && each.parentId === oSelectedDetails["oSelectedRS"].id)
            //     oSelectedRL.push(each);

        });
        oSelectedDetails["oSelectedRS"].childs?.forEach(function (child) {
            if (child.entity === "Rule List") {
                oSelectedRL.push(child);
            }
        })
        dispatch({ "type": "UPDATE_DT", value: oSelectedDT })
        dispatch({ "type": "UPDATE_RL", value: oSelectedRL })
        dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })

        return oSelectedDT;
    }



    const onClickRS = (rs) => {

        fnGetHierarchyIds(rs.id);
        let data = [...popoverData];
        data.forEach(each => {
            if (each.id === rs.id) {
                each["Active"] = true;
            }
            else {
                each["Active"] = false
            }
        })
        setPopoverData(data);

        setAnchorEl(null);
        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": false,
                "showRuleEditor": false,
                "showViewRules": true,
            }
        })

        props.setShowDetailsPage(false);
        props.setShowRuleListTableView(false)
        props.setShowSupportImage(false);
    }

    const handleChangeExpansion = (RMSid) => (event, isExpanded) => {
        let data = [...props.data];
        data.forEach(each => {
            if (each.id === RMSid) {
                each["expand"] = isExpanded ? true : false
            }
        })
    };

    const onNavBackToApplication = () => {
        let applications = [...props.aApplicationsList]
        applications.forEach(each => each['isSelected'] = false)
        props.setnewapplication(applications);
        props.setApplicationNav(true);
        props.setShowDetailsPage(false);
        props.setShowSupportImage(true);
        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": false,
                "showRuleEditor": false,
                "showViewRules": false,
            }
        })
        props.setShowRuleListTableView(false)

    }
    const onSelectRMS = (event, selectedRMS) => {
        let data = [...props.data];



        data.forEach(each => {
            if (each.id === selectedRMS.id) {
                each["Active"] = true;
            }
            else {
                each["Active"] = false;
            }
        })
        props.setDecisionTableHierarchy(data)

        setPopoverData(selectedRMS.childs)
        let aRuleList = [];
        // aLinearRMSlist.forEach((each) => {
        //     if (each.parentId === selectedRMS.id) {

        //         aRuleList.push(each)

        //     }
        // });
        setPopoverRuleListData(aRuleList)
        setAnchorEl(event.currentTarget);
        let oSelectedDetails = { ...State.oSelectedDetails }
        oSelectedDetails["oSelectedRMS"] = selectedRMS
        dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })

    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onClickRuleList = (ruleList) => {
        props.fnGetAllRuleLists(ruleList).then(function () {
            props.setShowRuleListTableView(true)
            props.setShowDetailsPage(false)
        }.bind(this));
        props.fnGetAllRules(ruleList);

    }

    return (


        <div style={{ height: '90vh' }}>
            <div style={{ display: 'flex', alignItems: 'center',marginTop: '1rem' }}>
                <IconButton aria-label="delete" size="small" onClick={onNavBackToApplication}>
                    <ArrowBackIcon fontSize="inherit" />
                </IconButton>
                <Link onClick={onNavBackToApplication} style={{
                    marginLeft: '0.5rem',
                    fontSize: '0.875rem',
                    // textOverflow: "ellipsis",
                    // whiteSpace: "nowrap",
                    overflow: "hidden",
                }}>{State.oSelectedDetails.oAppDetails.label ? State.oSelectedDetails.oAppDetails.label : State.oSelectedDetails.oAppDetails.name}</Link>
            </div>
            <TextField
                variant='outlined'
                style={{ height: '0.8rem', marginbotton: '0.5rem', textAlign: 'center', margin: '0.3rem 0 1rem 0.5rem', width: '12rem', background: '#ffffff' }}
                placeholder="Search"
                className="styleSearch"

                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility">
                                <SearchIcon />
                            </IconButton>

                        </InputAdornment>
                    )
                }} />


            <div>

                <div>
                    <div style={{
                        display: 'flex',  height: '1.5rem', margin: '1rem 0.5rem 0.5rem 0.5rem', fontSize: '0.875rem', borderBottom: '1px solid #E0E0E0'
                    }}>
                        <IconButton fontSize='small' size='small' className='styleButton'style={{marginBottom:'0.25rem'}}>
                            <img

                                src={RMSicon}
                                alt="Logo"
                                style={{ height: "1rem", color: "black", width: "1rem" }}
                            />
                        </IconButton>{State?.translationsDataObject["RULE MAINTANANCE SET_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE MAINTANANCE SET_WR"]?.shortDescription ?? "Rule Maintanance Set"}</div>
                    <div style={{ margin: '0.5rem',cursor: 'pointer' }}>  {root.childs.map((child) => {
                        if (child.entity === 'Rule Maintenance Set') {
                            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', minHeight: '2rem', marginBottom: '0.5rem',paddingLeft:'0.5rem',wordBreak:'break-all', fontWeight: child.Active ? '600' : "400", cursor:'pointer', backgroundColor: child.Active ? '#C3E6FF' : '#FFFFFF'}}
                                onClick={(event) => onSelectRMS(event, child)}>
                                    <p style={{ textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: 'nowrap', letterSpacing: '0.01071em', fontSize: '0.875rem', margin: '0.5rem' }}>{child.label}</p>
                                <IconButton className='styleButton'>
                                    <NavigateNextIcon fontSize='small' /></IconButton></div>
                        }

                    })}
                    </div>
                </div>
                {applicationProperties.general.showRuleList && <div>
                    <div style={{
                        display: 'flex', alignItems: 'center', height: '1.5rem', margin: '1rem 0.5rem 0.5rem 0.5rem', fontSize: '0.875rem', borderBottom: '1px solid #E0E0E0'
                    }}>
                        <IconButton style ={{marginTop:'0.1rem'}} className='styleButton styleListIcon'>
                            <ListAltIcon />
                        </IconButton>{State?.translationsDataObject["RULE LIST_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE LIST_WR"]?.shortDescription ?? "Rule List"} </div>
                    <div style={{ margin: '0.5rem' }}>{root.childs.map((child) => {
                        if (child.entity === 'Rule List') {
                            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', minHeight: '2rem', marginBottom: '0.5rem', backgroundColor: child.Active ? '#C3E6FF' : '#FFFFFF', fontSize: '0.875rem',cursor:'pointer' }} onClick={() => onClickRuleList(child)}>{child.label}</div>
                        }
                    })}
                    </div>
                </div>}
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ width: '13rem' }}>

                    <div>
                        <div style={{
                            display: 'flex', alignItems: 'center', height: '1.5rem', margin: '0.5rem 0.5rem 0.5rem 0.5rem', fontSize: '0.75rem', borderBottom: '1px solid #E0E0E0'
                        }}>
                            <IconButton fontSize='small' size='small' className='styleButton'>
                                <img

                                    src={RMSicon}
                                    alt="Logo"
                                    style={{ height: "1.2rem", color: "black", width: "1rem",marginRight:"4px" }}
                                />
                            </IconButton>{State?.translationsDataObject["RULE SET_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE SET_WR"]?.shortDescription ?? "Rule Set"}</div>
                        <div style={{ margin: '0.5rem' }}>  {popoverData.map((child) => {
                            if (child.entity === 'Rule Set') {
                                return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', minHeight: '2rem', marginBottom: '0.5rem', backgroundColor: child.Active ? '#C3E6FF' : '#FFFFFF', fontSize: '0.875rem',cursor:'pointer'}}
                                    onClick={(event) => onClickRS(child)}>{child.label}
                                    <IconButton className='styleButton'>
                                    <NavigateNextIcon fontSize='small' /></IconButton>
                                </div>
                            }

                        })}
                        </div>
                    </div>
                    {applicationProperties.general.showRuleList && <div>
                        <div style={{
                            display: 'flex', alignItems: 'center', height: '1.5rem', margin: '2.5rem 0.5rem 0.5rem 0.5rem', fontSize: '0.75rem', borderBottom: '1px solid #E0E0E0'
                        }}>
                            <IconButton className='styleButton styleListIcon'>
                                <ListAltIcon />
                            </IconButton>{State?.translationsDataObject["RULE LIST_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE LIST_WR"]?.shortDescription ?? "Rule List"}</div>
                        <div style={{ margin: '0.5rem' }}>  {popoverRLData?.map((child) => {
                            if (child.entity === 'Rule List') {
                                return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', minHeight: '1rem', marginBottom: '0.5rem', backgroundColor: child.Active ? '#C3E6FF' : '#FFFFFF', fontSize: '0.875rem',cursor:'pointer' }} onClick={() => onClickRuleList(child)}>{child.label}</div>
                            }

                        })}
                        </div>
                    </div>}
                </div>
            </Popover>

        </div>


    )
}
