import { Select, MenuItem, IconButton, Popover, Button, ClickAwayListener } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Avatar from './utility/Avatar';
import CustomIcon from "./icons/CustomIcons";
import { useState, useEffect } from "react";
const UserProfile = (props) => {
   
    let navigate = useNavigate();
    const fnSignOut = () => {
        navigate("/");
        props.setShowUserProfile(false);
        props.handleUserProfileAnchorElement(null)
        props.keycloak.logout();
    }

    return (
        <Popover
            placement="bottom"
            open={props.showUserProfile}
            anchorEl={props.userProfileAnchorElement}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: "true",
                    boundariesElement: 'scrollParent'
                },
            }}
        >
            <ClickAwayListener onClickAway={() => props.setShowUserProfile(false)}>
                <div style={{
                    padding: '0.5rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Avatar name={props.userDetails?.displayName} />

                    <p style={{ margin: '0.2rem', fontSize: '1rem' }}>{props.userDetails?.displayName}</p>
                    <p style={{
                        margin: '0.2rem', color: '#999A96',
                        fontSize: '0.875rem'
                    }}>{props.userDetails?.emailId}</p>
                    <Button onClick={() => props.setShowUserProfile(false)} className="styleButton" style={{ color: '#CB4747', fontWeight: '500', fontSize: '0.8rem' }}>Manage Account</Button>
                    <Button style={{ color: '#3026B9', fontWeight: '600', fontSize: '0.8rem' }} startIcon={<CustomIcon style={{ width: '1.2rem', height: '1.2rem' }} icon={"MaterialIcon.MdLogout"} />} onClick={fnSignOut} className="styleButton">Sign Out</Button>
                </div>
            </ClickAwayListener>
        </Popover >
    )
}
export const Settings = (props) => {
    const [language, setlanguage] = useState()
    useEffect(() => {
        setlanguage(props.language)
    }, [props.language])
    return (
        <Popover
            placement="bottom"
            open={props.showSettings}
            onClose={() => props.setShowSettings(false)}
            anchorEl={props.settingsAnchorElement}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: "true",
                    boundariesElement: 'scrollParent'
                },
            }}
        >
            {/* <ClickAwayListener onClickAway={() => props.setShowSettings(false)}> */}
                <div style={{
                    padding: '0.5rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>


                    <p style={{ margin: '0.2rem', fontSize: '1rem' }}>Lanaguages</p>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        style={{ height: '1.5rem' }}
                        name="from selection"
                        onChange={(event) => setlanguage(event.target.value)}
                        value={language}
                    >
                        {props.languages?.map(each => {
                            return (
                                <MenuItem value={each?.langaugeCode} key={each?.langaugeCode}  >{each?.languageDescription}</MenuItem>

                            )
                        })}
                    </Select>
                    <Button onClick={(event) => { props.fnApplyLanguage(event.target.value); props.setlanguage(language) }} className="styleButton" style={{ color: '#CB4747', fontWeight: '500', fontSize: '0.8rem' }}>Apply</Button>
                </div>
            {/* </ClickAwayListener> */}
        </Popover >
    )
}
export default UserProfile;