

const fetchWrapper = (baseurl, options,async) => {
  const updateOptions = { ...options };
  let splitUrl = baseurl.split("/");
  
  let url = baseurl.replace("/" + splitUrl[1], process.env["REACT_APP_" + splitUrl[1]]);
  // let url = baseurl;
 
  
    updateOptions.headers = {
      ...updateOptions.headers,
    
      "Access-Control-Allow-Origin": "*"
    };

    return fetch(url, updateOptions);
 
   
}

export default fetchWrapper;