const FormatStatusColor = (status) => {
    switch (status) {
        case 'Active':
            return '#089B13'
        case 'Draft':
            return '#F47B1E'
        case 'Awaiting Approval':
            return '#64b5f6'
        case 'In Approval':
            return '#9575cd'
        case 'Created':
            return '#089B13'
        case 'Draft Modified':
            return '#FF6F00'
        case 'Draft Created':
            return '#FF6F00'
        default:
            return '#bdbdbd'
    }

}
export default FormatStatusColor