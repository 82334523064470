import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Tooltip from '@mui/material/Tooltip';
import CustomIcon from "../icons/CustomIcons";
import { Modal, Radio, TextField, InputAdornment } from "@mui/material";
import fetchWrapper from '../utility/fetchWrapper';
import IconButton from "@mui/material/IconButton";
import "./cw_wr_lookupTable.css";
import { useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from 'react-redux';
import BusyLoader from "../textrules/components/busyLoader";
const LookUpTable = (props) => {
  const State = useSelector((state) => state.rootReducer);
  const dispatch = useDispatch();
  let aColumns = [];
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [mappedNametemp, setmappedName] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [aSearchResult, setSearchResult] = useState([])
  useEffect(() => { setSearchResult(props.valueHelpData.values) }, [props.valueHelpData.values])
  useEffect(() => {
    let temp = [];
    let displayName = "value", mappedName = "key";
    if (props.valueHelpData.lookupType === "API" || props.valueHelpData.lookupType === "DB") {
      props.valueHelpData.metadata.forEach(each => {
        if (each.isDisplayName) {
          displayName = each.mappedName
        }
        if (each.columnName === props.column.fieldName) {
          mappedName = each.mappedName
        }
      })
    }
    setmappedName(mappedName);
    if (props.options) {
      props.valueHelpData?.values?.forEach((e1) =>
        props.options?.forEach((e2) => {
          if (e1[mappedName] === e2.key) {
            temp.push(e1.id);
          }
        })
      );
    }
    setSelectedRows(temp);
  }, [props.valueHelpData.values]);
  const fnSearchLookupdata = (event) => {
    let aSearchResult = [];
    let displayName = "value", mappedName = "key";
    if (props.valueHelpData.lookupType === "API" || props.valueHelpData.lookupType === "DB") {
      props.valueHelpData.metadata.forEach(each => {
        if (each.isDisplayName) {
          displayName = each.mappedName
        }
        if (each.columnName === props.condition.conditionKey.name) {
          mappedName = each.mappedName
        }
      })
    }
    props.valueHelpData.values.forEach(each => {
      if (each[mappedName].toLowerCase().includes(event.target.value.toLowerCase()) || each[displayName].toLowerCase().includes(event.target.value.toLowerCase())) {
        aSearchResult.push(each)
      }
    })
    setSearchResult(aSearchResult);
  }
  //formatting columns of table
  const getColumns = (columns) => {
    columns.map((col) => {
      let field = col.mappedName;
      let headerName = col.displayName;
      let description = col.description;
      let check =
        aColumns.push({
          id: col.id,
          resizable: false,
          width: col.mappedName === "Radio" ? 50 : 200,
          headerName: headerName,
          field: field,
          label: headerName,
          description: headerName,

          renderCell: (params) =>
            col.mappedName === "Radio" &&
              props.operatorValue !== "EXISTSIN" &&
              props.operatorValue !== "NOTEXISTSIN" &&
              props.source !== "MassAdd" ? (
              <Radio
                color="primary"
                checked={props.tableData.isExpression && props.value ? params.row.isSelected || params.row[mappedNametemp] === props.value.split('{')[1].split('}')[0] : params.row.isSelected || params.row[mappedNametemp] === props.value}
                onChange={() => onValueSlection(params)}
              />
            ) : (
              <p>{params.value}</p>
            ),
        });
    });
  };
  // on click of Add in lookup table
  const addSelctedRows = (selectedRows) => {
    console.log(selectedRows);
    let mappedName = "key",
      displayName = "value";
    let updatedValue = "";
    let updatedValue_Text = "";
    if (props.valueHelpData.lookupType !== "VL") {
      props.valueHelpData.metadata.map((each) => {
        if (each.columnName === props.column.fieldName) {
          mappedName = each.mappedName;
        }
        if (each.isDisplayName) {
          displayName = each.mappedName;
        }
      });
    }
    let aSelectedData = [];
    for (var i = 0; i < selectedRows.length; i++) {
      for (var j = 0; j < props.valueHelpData.values.length; j++) {
        if (props.valueHelpData.values[j].id === selectedRows[i]) {
          aSelectedData.push({
            key: props.valueHelpData.values[j][mappedName],
            value: props.valueHelpData.values[j][displayName],
          });
        }
      }
    }
    if (props.source === 'RuleList') {
      props.onLookupSelection(aSelectedData)
    }
    else if (props.source !== "MassAdd" && props.source !== "MassAddConditions") {
      for (var i = 0; i < aSelectedData.length; i++) {
        if (props.tableData.isExpression) {
          updatedValue =
            updatedValue +
            "'" +
            aSelectedData[i]["value"] +
            "{" +
            aSelectedData[i]["key"] +
            "}'";
        }
        if (i < aSelectedData.length - 1) {
          updatedValue = updatedValue + ",";
        }
      }
      updatedValue = props.operatorValue + "[" + updatedValue + "]";

      if (props.source === "StaticForm") {
        props.handleInputCell(props.column, updatedValue);
        let row = { ...props.StaticFormDetails.rowData };
        row[props.column.columnName] = updatedValue;
        row[props.column.columnName + "_TEXT"] = updatedValue_Text;
        row[props.column.columnName + "_aList"] = aSelectedData;
        props.setStaticFormDetails({
          show: true,
          rowData: row,
        });
        props.fnSetaSaveModalData([row]);
      } else if (props.source === "HeaderFilter") {
        let values = { ...props.HeaderDetails.values };
        values[props.column.columnName + "_aList"] = aSelectedData;
        values[props.column.columnName] = updatedValue;
        //   props.setHeaderDetails({
        //       ...props.HeaderDetails,
        //       "values":values
        //   })
        props.setValue(
          updatedValue,
          props.column,
          true,
          updatedValue_Text,
          props.operatorValue,
          aSelectedData
        );
      } else {
        props.fnChangeDTinputCell(updatedValue, null, null, props.params.row);
        props.tableData.values.map((each) => {
          if (each.id === props.params.row.id) {
            each[props.column.columnName + "_aList"] = aSelectedData;
          }
        });
        props.setVariantInstanceDetails(props.tableData);
        console.log(props.tableData);
      }
    } else if (!props.tableData.isExpression) {
      let tokensTemp = { ...props.tokens };
      tokensTemp[props.column.columnName] = aSelectedData;
      tokensTemp[props.column.columnName + "_showPopover"] = false;
      props.setaChipData(aSelectedData, props.column);
    } else if (
      props.tableData.isExpression &&
      !props.column.propertyDto.isOperatorColumn
    ) {
      props.setaInputOptions(aSelectedData);
    }

    props.handleClose();
  };
  // on row selection in lookup table
  const onValueSlection = (params, event) => {
    console.log(props);
    let mappedName = "key",
      displayName = "value";
    if (props.valueHelpData.lookupType !== "VL") {
      props.valueHelpData.metadata.map((each) => {
        if (each.columnName === props.column.fieldName) {
          mappedName = each.mappedName;
        }
        if (each.isDisplayName) {
          displayName = each.mappedName;
        }
      });
    }

    if (props.source === "MassAddConditions" || props.source === "MassAdd") {
      let temptoken = { ...props.tokens };
      temptoken[props.column.columnName][props.index].value =
        "'" + params.row[displayName] + "{" + params.row[mappedName] + "}'";
      if (!props.tableData.isExpression) {
        temptoken[props.column.columnName][props.index].value = params.row[displayName];
        temptoken[props.column.columnName][props.index].key = params.row[mappedName];
      }
      // props.createConditionMassAddData(temptoken[props.column.columnName],props.column);
      props.setTokens(temptoken);
    } else if (props.source === "StaticForm") {
      if (props.tableData.isExpression) {
        props.handleInputCell(
          props.column,
          props.operatorValue +
          "'" +
          params.row[displayName] +
          "{" +
          params.row[mappedName] +
          "}'"
        );
      } else {
        props.handleInputCell(
          props.column,
          params.row[mappedName],
          params.row[displayName]
        );
      }
    } else if (props.source === "HeaderFilter") {
      if (props.tableData.isExpression) {
        props.setValue(
          "'" + params.row[displayName] + "{" + params.row[mappedName] + "}'",
          props.column,
          true,
          params.row[displayName],
          props.operatorValue
        );
      } else {
        props.setValue(
          params.row[mappedName],
          props.column,
          true,
          params.row[displayName],
          props.operatorValue
        );
      }
    }
    else if (props.source === "RuleList") {
      props.onLookupSelection(params.row[mappedName], params.row[displayName])
    }
    // without expression
    else if (
      props.source !== "MassAddConditions" &&
      !props.tableData.isExpression
    ) {
      props.fnChangeDTinputCell(
        params.row[mappedName],
        params.row[displayName],
        props.operatorValue,
        props.params.row,
        props.column
      );
    } else if (
      props.tableData.isExpression &&
      props.source !== "MassAddConditions" &&
      props.source !== "StaticForm"
    ) {
      props.fnChangeDTinputCell(
        props.operatorValue +
        "'" +
        params.row[displayName] +
        "{" +
        params.row[mappedName] +
        "}'",
        null,
        props.operatorValue,
        props.params.row,
        props.column
      );
    }

    props.handleClose();
  };
  const fnGoToGFC = () => {
    dispatch({ type: 'UPDATE_BUSY_LOADER', value: true })
    fetchWrapper(`/WorkUtilsServices/v1/attribute-master?attributeId=${props.column.columnId}`, State.requestOptions).then(res => res.json())
      .then((oData) => {
        dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
        props.handleClose();
        props.setGFCRow(oData.data[0]);
        props.setShowGFC(true)
        props.handleClose()
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
      })
  }
  getColumns(props.valueHelpData.metadata);
  let checkboxSelection = false;
  // if (props.source === "MassAddConditions" && !props.tableData.isExpression) {
  //     checkboxSelection = true
  // }
  // else
  if ((props.tableData.isExpression && (props.operatorValue === "EXISTSIN" || props.operatorValue === "NOTEXISTSIN")) || (!props.tableData.isExpression && props.source === "RuleList" && props.column.propertyDto.businessType === "C")) {
    checkboxSelection = true;
  }
  return (<>
    <BusyLoader show={State.showBusyLoader}></BusyLoader>

    <Modal
      className="styleModal"
      open={props.open}
      //onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          background: "#ffffff",
          margin: "5% 23% 5% 23%",
          borderRadius: "7px",
          width: "50%",
          height: "63%",
        }}
      >
        <div
          style={{
            display: "flex",
            paddingLeft: "1rem",
            justifyContent: "space-between",
            alignItems: "center",
            height: "2.5rem",
          }}
        >
          <p style={{ fontSize: "1rem", fontWeight: "600" }}>
            {props.ValueHelpTitle} Lookup
          </p>
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",

          }}>
            {props.valueHelpData.lookupType === "VL" && <Tooltip title="Manage Field Catalogue"><Button onClick={fnGoToGFC} disableRipple className='styledPrimaryButton styleButton' >Manage</Button>
            </Tooltip>}
            <IconButton
              size="small"
              style={{ marginRight: "1rem", color: "#424242" }}
              onClick={props.handleClose}
            >
              {/* dispatch({ type: "UPDATE_MODAL_VISIBILITY", value: false }); */}

              <CustomIcon
                style={{ width: "1.5rem", height: "1.5rem" }}
                icon={"MaterialIcon.MdOutlineClear"}
              />
            </IconButton>
          </div>
        </div>

        <div style={{ background: "#F1F5FE", padding: "1rem", height: "100%" }}>
          <TextField autoComplete='off'
            variant='outlined'
            style={{ height: '2rem', marginBottom: '0.5rem', textAlign: 'center', width: '40%', background: '#ffffff' }}
            placeholder="Search"
            className="styleSearch"
            onChange={event => fnSearchLookupdata(event)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility">
                    <CustomIcon fill="none" width="21" height="14" icon={"MaterialIcon.MdOutlineSearch"} />
                  </IconButton>

                </InputAdornment>
              )
            }} />
          <DataGrid
            className="styleGridTable"
            id="lookup"
            columns={aColumns}
            // page={page}
            // onPageChange={(newPage) => setPage(newPage)}
            rows={aSearchResult}
            // onRowClick={onValueSlection}
            hideFooterPagination={true}
            hideFooter={true}
            // pageSize={pageSize}
            // rowsPerPageOptions={[5,10]}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // rowsPerPageOptions={[5, 10]}
            // pagination

            checkboxSelection={checkboxSelection}
            onSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            selectionModel={selectedRows}
          />
        </div>

        {(props.operatorValue === "EXISTSIN" ||
          props.operatorValue === "NOTEXISTSIN" ||
          props.source === "MassAdd") && (
            <div
              style={{
                borderRadius: "0 0 7px 7px",
                boxShadow:
                  "0rem 0.438rem 0.5rem -0.25rem rgba(0, 0, 0, 0.2), 0rem 0.75rem 1.063rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 0.313rem 1.375rem 0.25rem rgba(0, 0, 0, 0.12)",
                height: "2rem",
                background: "#ffffff",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0.5rem",
              }}
            >
              <Button
                disableRipple
                className="styleButton styleManageButton"
                onClick={() => addSelctedRows(selectedRows)}
                color="primary"
              >
                ADD
              </Button>
            </div>
          )}
      </div>
    </Modal>
  </>
  );
};
export default LookUpTable;
