import fetchWrapper from "../utility/fetchWrapper";
const LookupService = (props) => {
    // const State = useSelector((state) => state.rootReducer);
    // const dispatch = useDispatch();
    /* Sample Props
     {
        setLoading:setLoading,
        column:column,
        fields:fields,
        row:row
     }
    */
   
    let valueHelpTitle = ""
    const handleValueHelp = () => {
        let lookupId = props.column.propertyDto.lookupId
        let lookupType = props.column.propertyDto.lookupType
        let isDependent = props.column.propertyDto.isDependent;
        valueHelpTitle = props.column.description
        if (!props.column.propertyDto.isHeader && isDependent && (lookupType === "DB" || lookupType === "API")) {
            fnGetValueHelpsMetaData(lookupType, lookupId, isDependent);
        }
        else {
            fnCreateValueHelpUrl(lookupType, lookupId);
        }
    }
    const fnGetValueHelpsMetaData = (lookupType, lookupId, isDependent) => {
        let sUrl = "/WorkUtilsServices/v1/lapi/constraints?lookupId=" + lookupId;
        Promise.all([
            fetchWrapper(sUrl, props.requestOptions).then((res) => res.json())
        ])
            .then(([oData]) => {
                props.setLoading(false);
                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    let message = "Session Timed Out.Kindly Refresh";
                    props.setMessageDialog({
                        "open": true,
                        "data": message,
                        onConfirm: () => { window.location.reload() },
                        type: "Error"

                    })
                }
                fnCreateValueHelpUrl(lookupType, lookupId, isDependent, oData.data);
                // ;props.setLoading(false)
            })


            .catch((error) => {
                console.log(error);
                props.setLoading(false);
            });
    }
    const fnCreateValueHelpUrl = (lookupType, lookupId, isDependent, aMetaData) => {

        let sUrl = "/WorkUtilsServices/v1/lapi/data?lookupId=" + lookupId;
        let aValues = [];
        let columns = props.tableData.fields;
        let dependeValueHelp, sQuery;
        let dependentValueHelpValue;
        let selecteColumnObject = props.row;
        let bValid = true;
        if (aMetaData) {
            if (aMetaData.length !== 0) {
                for (let i = 0; i < aMetaData.length; i++) {
                    dependeValueHelp = aMetaData[i].constraintColumn;
                    let columnName;
                    if (!props.tableData.isExpression) {
                        if (!props.column.propertyDto.isHeader) {
                            columns.find(function (oColumn) {
                                if (oColumn.fieldName === aMetaData[i].constraintColumn) {
                                    columnName = oColumn.columnName;
                                    dependentValueHelpValue = selecteColumnObject[columnName];
                                }
                            });
                            if (!columnName) {
                                dependentValueHelpValue = selecteColumnObject[aMetaData[i].constraintColumn];
                            }
                        } else {
                            if (props.column.propertyDto.isHeader) {
                                for (let j = 0; j < columns.length; j++) {
                                    if (columns[j].columnName === dependeValueHelp) {
                                        if (columns[j].propertyDto.defaultValue) {
                                            dependentValueHelpValue = columns[j].propertyDto.defaultValue;

                                        }
                                        else {
                                            dependentValueHelpValue = selecteColumnObject[columnName];
                                        }
                                        break;
                                    }
                                }
                            }

                        }
                        let operator = convertToRelationalOperators(aMetaData[i].constraintOperator);
                        if (lookupType === "API") {
                            sQuery = "&" + aMetaData[i].mappedName + operator + dependentValueHelpValue;
                        } else if (lookupType === "DB") {
                            sQuery = "&" + aMetaData[i].mappedName + operator + dependentValueHelpValue;
                        }

                        for (let k = 0; k < columns.length; k++) {
                            if (columns[k].columnName === dependeValueHelp) {
                                if (columns[k].label) {
                                    dependeValueHelp = columns[k].label;
                                } else {
                                    dependeValueHelp = columns[k].columnName;
                                }
                                break;
                            }
                        }
                        if (aMetaData && !dependentValueHelpValue) {
                            bValid = false;
                            //   sap.m.MessageToast.show("Please Select Dependent Valuehelp " + dependeValueHelp);
                            break;
                        }
                        else if (bValid && (props.source === "MassAdd" || props.source === "MassAddConditions")) {
                            dependentValueHelpValue = dependentValueHelpValue.map(each => {
                                return each.key
                            })
                            for (let p = 0; p < dependentValueHelpValue.length; p++) {

                                let operator = convertToRelationalOperators(aMetaData[i].constraintOperator);

                                sQuery = "&" + aMetaData[i].mappedName + operator + dependentValueHelpValue[p];

                                sUrl = sUrl + sQuery;
                                fnGetLapiData(lookupType, sUrl, isDependent, aValues)

                            }
                        }
                        else if (bValid) {
                            sUrl = sUrl + sQuery;
                        }

                    } else {
                        let dataType = props.column.dataType;

                        // if (this.operatorForm !== "Static" && this.operatorForm !== "MASS_ADD") {
                        //   selecteColumnObject = ruleAuthoringModel.getProperty("/selectedColumnSource").getBindingContext("ruleAuthoringModel").getObject();
                        // }

                        columns.find(function (oColumn) {
                            if (oColumn.fieldName === aMetaData[i].constraintColumn) {
                                columnName = oColumn.columnName;
                                dependentValueHelpValue = selecteColumnObject[columnName];
                            }
                        });

                        if (!columnName) {
                            dependentValueHelpValue = selecteColumnObject[aMetaData[i].constraintColumn];
                        }
                        if (dependentValueHelpValue) {
                            let keyvalues = splitKeys(dependentValueHelpValue, dataType);
                            let atemp = [];
                            keyvalues.forEach((each) => {
                                atemp.push(each.key)
                            })
                            dependentValueHelpValue = atemp
                        }

                        for (var k = 0; k < columns.length; k++) {
                            if (columns[k].columnName === dependeValueHelp) {
                                if (columns[k].label) {
                                    dependeValueHelp = columns[k].label;
                                } else {
                                    dependeValueHelp = columns[k].columnName;
                                }
                                break;
                            }
                        }
                        if (aMetaData && !dependentValueHelpValue) {
                            bValid = false;
                            props.setLoading(false)
                            //  sap.m.MessageToast.show("Please Select Dependent Valuehelp " + dependeValueHelp);
                            break;

                        } else {

                            for (let p = 0; p < dependentValueHelpValue.length; p++) {

                                let operator = convertToRelationalOperators(aMetaData[i].constraintOperator);

                                sQuery = "&" + aMetaData[i].mappedName + operator + dependentValueHelpValue[p];

                                sUrl = sUrl + sQuery;

                            }
                            // this.fnGetLapiData(lookupType, sUrl, aValues, fieldId, isDependent);
                        }

                    }
                }
            }
        }
        else {
            fnGetLapiData(lookupType, sUrl, isDependent, aValues)
        }
        if (bValid && aMetaData && props.source !== "MassAdd") {
            fnGetLapiData(lookupType, sUrl, isDependent, aValues,);
        }
    }
    const convertToRelationalOperators = (operator) => {
        if (operator) {
            if (operator === "EQ") {
                operator = "=";
            } else if (operator === "GTE") {
                operator = ">=";
            } else if (operator === "GT") {
                operator = ">";
            } else if (operator === "LTE") {
                operator = "<=";
            } else if (operator === "LT") {
                operator = "<";
            }
            return operator;
        }
    }

    const fnGetLapiData = (lookupType, sUrl, isDependent, aValues) => {
        let metadata;
        Promise.all([
            fetchWrapper(sUrl, props.requestOptions).then((res) => res.json())
        ])
            .then(([oData]) => {
                props.setLoading(false);
                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    let message = "Session Timed Out.Kindly Refresh";
                    props.setMessageDialog({
                        "open": true,
                        "data": message,
                        onConfirm: () => { window.location.reload() }

                    })
                }
                if (oData.data) {
                    let values = oData.data.values;
                    // let metadata = [];
                    if (lookupType === "VL") {
                        metadata = [{
                            "id": null,
                            "columnName": "key",
                            "displayName": "Key",
                            "searchable": true,
                            "isDisplayName": false,
                            "mappedName": "key"
                        }, {
                            "id": "1",
                            "columnName": "value",
                            "displayName": "Text",
                            "searchable": false,
                            "isDisplayName": true,
                            "mappedName": "value"
                        }, {
                            "id": "2",
                            "columnName": "additionalValue",
                            "displayName": "Additional Text",
                            "searchable": false,
                            "isDisplayName": false,
                            "mappedName": "additionalValue"
                        }];
                    } else {
                        metadata = oData.data.metadata;

                    }
                    values.find(function (value) {
                        value["id"] = Math.random().toString();
                        aValues.push(value);
                    });
                }
                metadata.unshift({

                    "id": null,

                    "columnName": "Radio",

                    "displayName": "",

                    "searchable": true,

                    "isDisplayName": false,

                    "mappedName": "Radio"

                })

                props.setValueHelpData({
                    "metadata": metadata,
                    "values": aValues,
                    "lookupType": lookupType
                })
                // handleClickOpen();
                let temp = [];
                aValues.forEach((e1) => props.options?.forEach((e2) => {
                    if (e1.key === e2.key) {
                        temp.push(e1.id)
                    }
                }
                ))
                props.setSelectedrow(temp)
                // props.setLoading(false);
                props.setShowLookup(true)
            })


            .catch((error) => {
                console.log(error);
                props.setLoading(false);
            });
    }
    const splitKeys = (expression, dataType) => {
        if (dataType === "NVARCHAR") {
            if (!expression.includes("[")) {
                var resArray = [];
                var startIndex = expression.indexOf("'")
                var value = expression.substring(startIndex + 1, expression.length - 1)
                if (value.includes("{") && value.includes("}")) {
                    var res = value.substring(value.indexOf("{") + 1, value.indexOf("}"))
                }
                resArray.push(res)
                return resArray;
            } else {
                var startIndex = expression.indexOf("[")
                var valueArr = [];
                valueArr = expression.substring(startIndex + 1, expression.length - 1).split(",")
                var resArray = [];
                for (var i = 0; i < valueArr.length; i++) {
                    var temp = valueArr[i].substring(1, valueArr[i].length - 1);
                    var value;
                    if (temp.includes("{") && temp.includes("}")) {
                        value = temp.substring(0, temp.indexOf("{"));
                        temp = temp.substring(temp.indexOf("{") + 1, temp.indexOf("}"))
                    }
                    resArray.push({
                        "key": temp,
                        "value": value
                    });
                }
                return resArray;
            }
        }
    }
    handleValueHelp();
}
export default LookupService;
