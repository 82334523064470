import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Divider,Typography } from "@mui/material";
import CherryworkLogoOriginal from "../images/CherryworkLogosvg.svg";
import IPMLogo from "../images/IPMLogo.svg";
import sapiens from "../images/sapiens2.svg";

export default function NoPageFound() {
  return (
    <>
      
      <Stack display="flex" alignItems="center" justifyContent="center" p={4}>
          <img src={sapiens} alt="IPMLogo"></img>
          <Typography variant="h6" color="#000" fontWeight="bold">No Page Found</Typography>
          {/* <Typography variant="h6" color="#000">Looks like you don't have permission. Please contact Admin</Typography> */}
      </Stack></>
  );
}
