import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { useState } from "react";

const useStyles = makeStyles({
  root: {
    height: '100%',
    flexGrow: 1,
    maxWidth: 400,
  },
});


const OperatorsPopup = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);



  };
  const handleSelect = (event, nodeId) => {

    setSelected(nodeId);
    console.log(props.params);
    let operatorValue = "", updatedValue;
    if (nodeId !== "Functional Operators" && nodeId !== "Array Operators" && nodeId !== "Comparison Operator" && nodeId !== "Range Operators") {
      if (props.source !== "MassAdd" && props.source !== "RuleList") {
        var arrayOfObj = Object.entries(props.operatorsList).map((e) => ({ [e[0]]: e[1] }));
        Object.keys(props.operatorsList)
        let aOperators = [];

        arrayOfObj.forEach((each, index) => {
          each[Object.keys(props.operatorsList)[index]].map((op) => {
            aOperators.push(op);
          })

        })

        if (props.source === "StaticForm") {
          aOperators.forEach((operator) => {
            if (props.params.row[props.column.columnName].includes(operator.key)) {
              operatorValue = operator.key;
            }
          })
        }
       
        else {
          aOperators.forEach((operator) => {
            if (props.params.value && props?.params?.value?.startsWith(operator.key)) {
              operatorValue = operator.key;
            }
          })
        }

  
        if (operatorValue) {
          updatedValue = props.params.row[props.column.columnName].replace(operatorValue, nodeId);
        }
        else {
          updatedValue = props.params.row[props.column.columnName] + nodeId;
        }



        //close operators popoover
        if (props.params.row[props.column.columnName + "isNew"] && (nodeId === "IN" || nodeId === "NOTIN")) {
          // openrangeoperators poppover
          props.params.row[props.column.columnName + "isNew"] = false;
          // props.setShowRangeOperators(true);
        }
        else if (nodeId === "IN" || nodeId === "NOTIN") {
          props.setShowRangeOperators(false);
        }
        else if (nodeId !== "IN" || nodeId !== "NOTIN") {
          props.params.row[props.column.columnName + "isNew"] = false;

          props.setShowRangeOperators(false);

        }
        if (props.source === "StaticForm") {
          props.handleInputCell(props.column, updatedValue)
        }
        else if (props.source === "HeaderFilter") {
          props.setValue(updatedValue, props.column, false, null, operatorValue)
        }

        else {
          props.fnChangeDTinputCell(updatedValue, null, nodeId, props.params.row, props.column);
        }
        props.setclosepopover(false);
      }
      else  if (props.source === "RuleList") {
        operatorValue = nodeId;
        props.handleOperatorSelection(props.column,operatorValue,props.token)
      }
      else {
        let temptoken = { ...props.tokens }
        temptoken[props.column.columnName][props.index].operator = nodeId;

        props.setTokens(temptoken);
        // props.setOperator(nodeId);

      }
    }

  }


  const arrayOfObj = Object.entries(props.operatorsList).map((e) => ({ [e[0]]: e[1] }));
  let oTree = {
    "id": Math.random().toString(),
    "childs": []
  }
  Object.keys(props.operatorsList).forEach((operator) => {
    oTree.childs.push({ "id": Math.random().toString(), "key": operator, "value": operator, "childs": [] })
  })
  for (var i = 0; i < arrayOfObj.length; i++) {
    arrayOfObj[i][oTree.childs[i].key]["id"] = Math.random().toString();
    oTree.childs[i]["childs"] = arrayOfObj[i][oTree.childs[i].key]
  }
  // console.log(oTree);
  const renderTree = (nodes) => (
    // <Tooltip title={nodes.value} placement="right">
    <TreeItem className="styleOperatorsTreeItem" tooltip={nodes.value} key={nodes.key} nodeId={nodes.key} label={nodes.value}>
      {nodes.childs != null && nodes.childs.length != 0 ? nodes.childs.map((node) => renderTree(node)) : null}
    </TreeItem>

    //  </Tooltip>

  );

  return <TreeView
    className={classes.root}
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
    expanded={expanded}
    selected={selected}
    onNodeToggle={handleToggle}
    onNodeSelect={handleSelect}

  >

    <div>
      {oTree.childs.map((data) => renderTree(data))}

    </div>

  </TreeView>
}
export default OperatorsPopup;