
import React from "react";
import DatePicker from "./cw_wr_DatePicker";
import InputField from "./cw_wr_Input";
import DateTimePicker from "./cw_wr_DateTimePicker";
import './cw_wr_RangeOperatorsPopup.css';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
const RangeOperatorPopover = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [fromValue, setFromValue] = React.useState('');
  const [toValue, setToValue] = React.useState('');
  const [fromSelection, setFromSelection] = React.useState('[');
  const [toSelection, setToSelection] = React.useState(']');
  let element;
  const   getInputElement = (source, value, setValue, radioSelection) => {
    let params = { ...props.params }
    params["value"] = value
    if (props.elementName === "InputField") {
      element = <InputField {...props} tableData={props.tableData} fnChangeDTinputCell={props.fnChangeDTinputCell} column={props.column} params={params}
        source={source} ParentSource={props.source} fromValue={fromValue} toValue={toValue} value={value} setValue={setValue} fromSelection={fromSelection} toSelection={toSelection} />
    }
    else if (props.elementName === "DatePicker") {
      element = <DatePicker token={props.token} fnChangeDTinputCell={props.fnChangeDTinputCell}
        source={source} fromValue={fromValue} toValue={toValue} value={value} setValue={setValue} fromSelection={fromSelection} toSelection={toSelection} />
    }
    else if (props.elementName === "DateTimePicker") {
      element = <DateTimePicker fnChangeDTinputCell={props.fnChangeDTinputCell}
        source={source} fromValue={fromValue} toValue={toValue} value={value} setValue={setValue} fromSelection={fromSelection} toSelection={toSelection} />
    }
    return element;
  }
  const handleChange = (event, source) => {
    if (source === "From") {
      setFromSelection(event.target.value);
    }
    if (source === "To") {
      setToSelection(event.target.value);
    }

  };

  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  const saveRangeOperatorsData = () => {
    let x = fromValue;
    let y = toValue;
    if (props.column.dataType === "NVARCHAR" || props.column.dataType === "DATE" || props.column.dataType === "TIMESTAMP") {
      x = x = "'" + x + "'";
      y = "'" + y + "'"
    }
    if (x !== "" && y !== "" ) {
      let updatedValue = props.operator + fromSelection + x + ".." + y + toSelection;
      if (props.source === "MassAddConditions" && props.tokens[props.column.columnName+"FROM_VALIDATION_STATUS"] !== "Error" &&  props.tokens[props.column.columnName+"TO_VALIDATION_STATUS"] !== "Error") {
        let temptoken={...props.tokens}
            temptoken[props.column.columnName][props.index].value=updatedValue;
            props.setTokens(temptoken);
          //  props.createConditionMassAddData(temptoken[props.column.columnName],props.column);
          props.setShowRangeOperators(false);
           
      }
      else if (props.source === "StaticForm") {
        props.handleInputCell(props.column, updatedValue)
        props.setRangeOperatorPopover(false);
      }
      
      else {
        props.fnChangeDTinputCell(updatedValue,null,null, props.params.row,props.column);
        props.setShowRangeOperators(false);
      }

    }
    else{
      if(props.source !== "StaticForm"){
        let tableDataTemp = { ...props.tableData }
        tableDataTemp.values.map((each) => {
          if (each.id === props.params.row.id) {
            if (x == "") {
              each[props.column.columnName + "FROM_VALIDATION_STATUS"] = "Error";
              each[props.column.columnName + "FROM_VALIDATION_ERROR"] = "Enter Valid Data";
            }
            if (y == "") {
              each[props.column.columnName + "TO_VALIDATION_STATUS"] = "Error";
              each[props.column.columnName + "TO_VALIDATION_ERROR"] = "Enter Valid Data";
            }
    
          }
        })
        props.setVariantInstanceDetails(tableDataTemp);
      }
      
    }
    
   
   
   
  }
  const discardRangeOperatorsData = () => {

    if (props.source === "MassAddConditions") {

    props.setShowRangeOperators(false)
    }
    else if (props.source === "StaticForm") {
      props.setRangeOperatorPopover(false);
    }
    else {
      props.setShowRangeOperators(false);
    }

  }

  return (
    <div style={{borderRadius:'0.5rem',boxShadow:'0rem 0.188rem 0.63rem 0.125rem rgba(0, 0, 0, 0.2), 0rem 0.125rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 0.63rem 0.313rem rgba(0, 0, 0, 0.12)'}}>
      <div className="styleHorizontal" style={{padding:'1rem 1rem 0rem 1rem'}}>
        <div  style={{height:'3rem',width:'2.75rem',fontSize:"1rem"}}>From  </div>
        {/* <div style={{marginRight:'1 rem'}}>|</div> */}
        <FormControl style={{ width: '8.75rem' ,marginTop:'-1rem'}} variant="standard" size="small">
        <InputLabel id="demo-simple-select-outlined-label" required style={{height:'1.5rem',fontSize:'1rem'}}>
               From
              </InputLabel>
              
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                //value={row.applicationId}
                // size="small"
                style={{height:'1.5rem'}}
                name="from selection"
               onChange={(event) => handleChange(event, "From")}
              
              >
            <MenuItem value="[" key="["  >Exclude</MenuItem>
            <MenuItem value="(" key="("  >Include</MenuItem>
            
                  
                
               
              </Select>
            
           
           
          </FormControl>
        {/* <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={fromSelection} onChange={(event) => handleChange(event, "From")} row>
            <FormControlLabel value="[" control={<Radio />} label="Include" />
            <FormControlLabel value="(" control={<Radio />} label="Exclude" />
          </RadioGroup>
        </FormControl> */}
<div  style={{marginLeft:'0.5rem',marginTop:'-0.75rem'}}>
        {getInputElement("From", fromValue, setFromValue, fromSelection)}
        </div>
      </div>
      <div className="styleHorizontal" style={{padding:'0rem 1rem 1rem 1rem'}}>
        <div  style={{height:'3rem',width:'2.75rem',fontSize:"1rem"}}>To </div>
        {/* <div style={{marginLeft:'0.5rem',marginRight:'0.6 rem'}}>|</div> */}
        <FormControl style={{ width: '8.5rem'  ,marginTop:'-1rem'}} variant="standard" size="small">
        <InputLabel id="demo-simple-select-outlined-label" required style={{height:'1.5rem',fontSize:'1rem'}}>
              To
              </InputLabel>
              
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                //value={row.applicationId}
                // size="small"
                style={{height:'2.1rem'}}
                name="from selection"
               onChange={(event) => handleChange(event, "To")}
              
              >
            <MenuItem value="]" key="]"  >Exclude</MenuItem>
            <MenuItem value=")" key=")"  >Include</MenuItem>
            
                  
                
               
              </Select>
            
           
           
          </FormControl>
        {/* <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={fromSelection} onChange={(event) => handleChange(event, "From")} row>
            <FormControlLabel value="[" control={<Radio />} label="Include" />
            <FormControlLabel value="(" control={<Radio />} label="Exclude" />
          </RadioGroup>
        </FormControl> */}
  <div style={{width:'11.5rem'}}>
{  getInputElement("To", toValue, setToValue, toSelection)}</div>

      </div>
      
      <div className=" styleFooter" style={{backgroundColor:'white',boxShadow:'0rem 0.438rem 0.5rem -0.25rem rgba(0, 0, 0, 0.2), 0rem 0.75rem 1.063rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 0.313rem 1.375rem 0.25rem rgba(0, 0, 0, 0.12)', borderRadius:'0rem 0rem 0.5rem 0.5rem'}}>
        
        <Button disableRipple  variant="text"  color="primary" style={{textTransform:'none'}} onClick={discardRangeOperatorsData}>Cancel</Button>
        <Button  disableRipple variant="contained" style={{marginLeft:'0.5rem',textTransform:'none',marginRight:'0.5rem',marginTop:'0.5rem',marginBottom:'0.5rem',backgroundColor:'#007AD4'}} color="primary" onClick={saveRangeOperatorsData}>save</Button>
      </div>
    </div>

  )

}
export default RangeOperatorPopover;