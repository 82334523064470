import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import FormatDate from './../functions/cw_wr_DateFormatter';
import './cw_wr_DatePicker.css'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

let defaultValue;

const DatePicker = (props) => {
  const onChangeDatePicker = (event) => {

    console.log(props);
    let updatedValue = "";
    if (!props.source) {
      if (props.tableData.isExpression) {
        updatedValue = props.operatorValue + event.target.value
      }
      else {
        updatedValue = event.target.value
      }
      props.fnChangeDTinputCell(updatedValue, null, props.operatorValue, props.params.row, props.column);
    }
    if (props.source === "To" || props.source === "From" || props.source === "MassAddvalidity" || props.source === "MassAddConditions" || props.source === "ColumnFilter") {

      props.setValue(event.target.value)

    }
    if (props.source === "HeaderFilter") {
      props.setValue(event.target.value, props.column, true)
    }
    if (props.source === "StaticForm") {
      props.handleInputCell(props.column, event.target.value)
    }
    if (props.source === "RuleList") {
      props.handleDateSelection(event)
    }
   
  }
  const classes = useStyles();
  const FormatDate = (format, value) => {
   
    if (value === 0) {
      return "1970-01-01"
    }
    if (!value) {
      return value;
    }

    let yyyy, MM, dd;
    value = value.toString();
    if (value.includes("-")) {
      var arr = value.split("-");
      yyyy = arr[0];
      MM = arr[1];
      dd = arr[2];
    }
   
     else {
      let val = parseInt(value);
      value = isNaN(val) ? value : val;
      let date = new Date(value);
      yyyy = date.getFullYear();
      MM = date.getMonth() + 1;
      dd = date.getDate();
    }
    if (MM < 10 && !MM.toString().startsWith("0")) {
      MM = "0" + MM
    }
    if (dd < 10 && !dd.toString().startsWith("0")) {
      dd = "0" + dd;
    }
    let formattedDate = "";
    if (format === "dd/MM/yyyy") {
      formattedDate = dd + "/" + MM + "/" + yyyy;
    } else if (format === "MM/dd/yyyy") {
      formattedDate = MM + "/" + dd + "/" + yyyy;
    } else if (format === "dd-MM-yyyy") {
      formattedDate = dd + "-" + MM + "-" + yyyy;
    } else if (format === "MM-dd-yyyy") {
      formattedDate = MM + "-" + dd + "-" + yyyy;
    } else if (format === "yyyy-MM-dd") {
      formattedDate = yyyy + "-" + MM + "-" + dd;
    }
    return formattedDate;
  };
  defaultValue = FormatDate("yyyy-MM-dd", props.value);
  // display format should be mm/dd/yyyy
  return (<TextField
    id="date"
    variant='outlined'
    value={defaultValue}
    style={{ width: '100%' }}
    onChange={onChangeDatePicker}
    type="date"
    className="styleInput"
    //disabled={(props.source === "MassAddvalidity" || props.column.propertyDto.isEditable) && !(props.params.row.STATUS==="Deactivated" )? false : true}
    defaultValue={defaultValue}
    // className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
    format="MM/DD/YYYY"
  />
  )
}
export default DatePicker;
