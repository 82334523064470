const FormatOperatorsText = (operator) => {
	if (operator === "ISEQUAL") {
        return "is equal to";
    } else if (operator === "ISNOTEQUAL") {
        return "is not equal to";
    } else if (operator === "ISGREATER") {
        return "is greater than";
    } else if (operator === "ISLESS") {
        return "is less than";
    } else if (operator === "ISGREATEREQUAL") {
        return "is greater than or equal to";
    } else if (operator === "ISLESSEQUAL") {
        return "is less than or equal to";
    } else if (operator === "CONTAINS") {
        return "contains";
    } else if (operator === "NOTCONTAINS") {
        return "does not contain";
    } else if (operator === "STARTSWITH") {
        return "starts with";
    } else if (operator === "NOTSTARTSWITH") {
        return "does not start with";
    } else if (operator === "ENDSWITH") {
        return "ends with";
    } else if (operator === "NOTENDSWITH") {
        return "does not end with";
    } else if (operator === "MATCHES") {
        return "matches";
    } else if (operator === "NOTMATCHES") {
        return "does not match";
    } else if (operator === "EXISTSIN") {
        return "exist in";
    } else if (operator === "NOTEXISTSIN") {
        return "does not exist in";
    } else if (operator === "IN") {
        return "is in";
    } else if (operator === "NOTIN") {
        return "is not in";
    } else return null;
}
export default FormatOperatorsText