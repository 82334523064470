import FormatOperatorsText from "./cw_tr_formatOperatorsText";
const ExpBuilder = (block, i) => {
	;
	var bFlag = true;
	var expression, a;
	var b = " ";
	var exp1 = " ";
	var exp2 = " ";
	var root = {
		"childrens": block.aConditionBlock,
		"conditionType": "group",
		// "dataSetId": null,
		"expression": null,
		"logicalRelationNext": null,
		"parent": null,
		"root": true,
		"sequence": 0
	}
	var postorder = function (root) {
		let res = [];
		const traverse = node => {
			if (!node) return;
			if (node.childrens) {
				let len = node.childrens.length;
				for (let i = 0; i < len; i++) {
					traverse(node.childrens[i])
				}
			}
			res.push(node);
		}
		traverse(root)
		return res;
	};
	if (block.blockType !== "default") {
		postorder(root).forEach(function (each) {
			var exp = "";
			if (each.conditionType === "group") {
				exp = exp + "(";
				each.childrens.forEach(function (C) {
					// if (C.conditionKey.name == "" || C.condOperation == "" || C.conditionValue.trim() == "") {
					// 	return "DataNotFilled";
					// }
				
					console.log(C.condOperation)
					let condOperation = FormatOperatorsText(C.condOperation);
					console.log(C.condOperation)
					if (C.conditionType !== "group") {

						if (C.condOperation === "EXISTSIN" || C.condOperation === "NOTEXISTSIN") {
							var condValue = "";
							for (var k = 0; k < C.aCondValueForArrayOperator.length; k++) {
								for (var l = 0; l < C.aCondValueForArrayOperator[k].data.length; l++) {
									condValue = condValue + C.aCondValueForArrayOperator[k].data[l].value + ",";
									// if (C.aCondValueForArrayOperator[k].type === "Value" && C.conditionKey.dataType === "NVARCHAR") {
									// 	condValue = condValue + "'" + C.aCondValueForArrayOperator[k].data[l].value + "',";

									// }
									// else if (C.aCondValueForArrayOperator[k].type === "Value" && C.conditionKey.dataType !== "NVARCHAR") {
									// 	condValue = condValue + C.aCondValueForArrayOperator[k].data[l].value + ",";
									
									// }
									// else if (C.aCondValueForArrayOperator[k].type === "Variable") {
									// 	condValue = condValue + C.aCondValueForArrayOperator[k].data[l].key + ",";
										
									// }
									// else {
									// 	condValue = condValue + C.aCondValueForArrayOperator[k].data[l].value + ",";
										
									// }
								}

							}
							condValue = "[" + condValue.substring(0, condValue.length - 1) + "]";

						}
						else {
							condValue = C.conditionValue;
						}
						C.expression = C.conditionKey.label + " " + condOperation + " " + condValue;
					}
					if (!C.logicalRelationNext) {
						C.logicalRelationNext = "";
					}
					exp = exp + C.expression + " " + C.logicalRelationNext + " ";
				});
				exp = exp + ")";
				each.expression = exp;
			} else {
				if (each.conditionKey.name === "" || each.condOperation === "") {
					bFlag = false;
				}
				if (each.logicalRelationNextVis && each.logicalRelationNext == null) {
					bFlag = false;
				}

				// check for value 
				if (bFlag && each.operatorGroupType !== "Array Operators" && each.operatorGroupType !== "Range Operators") {
					if (each.conditionValueType !== "null") {
						if (each.conditionValue.trim() == "") {
							bFlag = false;
						}
					}
				}
				else if (bFlag && each.operatorGroupType === "Range Operators") {
					
					if(each.fromValue ==="" || each.toValue === "" || each.FromconditionValueType == "" || each.ToconditionValueType === ""){
						bFlag = false
					} 
				}
				else if (bFlag && each.operatorGroupType === "Array Operators") {
					for (var l = 0; l < each.aCondValueForArrayOperator.length; l++) {
						if (each.aCondValueForArrayOperator[l].data.length == 0) {
							bFlag = false;
							break;
						}
					}
				}
			}
		})
	}
	for (var j = 0; j < block.aActionBlock.length; j++) {
		if (j === block.aActionBlock.length - 1) {
			if (block.aActionBlock[j].actionType === "Object") {
				for (var k = 0; k < block.aActionBlock[j].object.length; k++) {
					if (block.aActionBlock[j].object[k].field == "" || block.aActionBlock[j].object[k].value == "") {
						bFlag = false;
					}
					b = block.aActionBlock[j].object[k].field + " is " + block.aActionBlock[j].object[k].value;
					if (k == 0) {

						a = block.aActionBlock[j].actionKey.label + " is {" + b;
					} else if (k === block.aActionBlock[j].object.length - 1) {
						a = a + " , " + b + "}";
					} else {
						a = a + " , " + b;
					}
					if (k == 0 && k === block.aActionBlock[j].object.length - 1) {
						a = block.aActionBlock[j].actionKey.label + " is {" + b + "} ";
					}
				}
			} else {
				if (block.aActionBlock[j].actionKey.name == "" || block.aActionBlock[j].actionValue == "") {
					bFlag = false;
				}
				if (block.aActionBlock[j].actionKey.name != "" && block.aActionBlock[j].actionValue != "") {
					a = block.aActionBlock[j].actionKey.label + " is " + " " + block.aActionBlock[j].actionValue;
				} else if (block.aActionBlock[j].actionKey.name != " " || block.aActionBlock[j].actionKey.name != undefined) {
					a = block.aActionBlock[j].actionKey.label;
				}

			}

			if (exp2 === " " && a) {
				exp2 = exp2 + a;
			} else if (a) {
				exp2 = exp2 + " " + a;
			}

		} else {
			if (block.aActionBlock[j].actionType === "Object") {
				for (var p = 0; p < block.aActionBlock[j].object.length; p++) {
					if (block.aActionBlock[j].object[p].field == "" || block.aActionBlock[j].object[p].value == "") {
						bFlag = false;
					}
					b = block.aActionBlock[j].object[p].field + " is " + block.aActionBlock[j].object[p].value;
					if (p == 0) {
						a = block.aActionBlock[j].actionKey.label + " is {" + b;
					} else if (p === block.aActionBlock[j].object.length - 1) {
						a = a + " , " + b + "} AND";
					} else {
						a = a + " , " + b;
					}
					if (p == 0 && p === block.aActionBlock[j].object.length - 1) {
						a = block.aActionBlock[j].actionKey.label + " is {" + b + "} ";
					}
				}
				if (exp2 === " " && a) {
					exp2 = exp2 + a;
				} else if (a) {
					exp2 = exp2 + " " + a;
				}
			} else {
				if (block.aActionBlock[j].actionKey.name == "" || block.aActionBlock[j].actionValue == "") {
					bFlag = false;
				}
				if (block.aActionBlock[j].actionKey.name != "" && block.aActionBlock[j].actionValue != "") {
					exp2 = exp2 + " " + block.aActionBlock[j].actionKey.label + " is " + block.aActionBlock[j].actionValue + " AND";
				} else if (block.aActionBlock[j].actionKey.name != " ") {
					exp2 = exp2 + " " + block.aActionBlock[j].actionKey.label;
				}
			}

		}
	}
	if (bFlag) {
		block["expression"] = "If " + root.expression + " then " + exp2;
		return block["expression"];
	} else {
		return "DataNotFilled"
	}
}
export default ExpBuilder;