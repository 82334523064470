import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import Keycloak from "keycloak-js";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers/index'
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from './utility/theme.js'
import fetchWrapper from './utility/fetchWrapper';
import { Stack } from '@mui/material';
import { applicationProperties } from './utility/applicationConfig';
import NotAuthorizedPage from './utility/UnAuthorized'
// import { PublicClientApplication } from '@azure/msal-browser';
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from './utility/authConfig';
import Login from './Login'
const store = createStore(reducers)
if (applicationProperties.general.useWorkAccess) {
  const keycloak = Keycloak(
    window.location?.hostname === "localhost"
      ? "/localhostKeycloak.json"
      : "/azureKeycloak.json"
  );

  keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
    // debugger;
    // console.log(authenticated)
    const bearer = 'Bearer ' + keycloak.token;
    const headers = {
      "Content-Type": 'application/json',
      "Authorization": bearer,
    };
    // fetching roles and permission from workaccess
    fetchWrapper('/WorkAccessServices/api/v1/applications/entitiesAndActivities?applicationId=42', { headers: headers })
      .then(res => res.json())
      .then(response => {
        // debugger;
        let userAccess = {};
        // console.log(response);
        if (response.data) {
          let entitiesAndActivities = response.data.entitiesAndActivities[0];
          const entities = Object.keys(entitiesAndActivities).map(ele => { return ele });
          let activities = {};
          for (let entity of entities) {
            entitiesAndActivities[entity].forEach(ele => { activities[ele] = true })
          }
          userAccess.entities = entities;
          userAccess.activities = activities;
        }
        // else {
        //   userAccess = {
        //     "activities": {
        //       WR_Modelling: true,
        //       WR_Authoring: true,
        //       TextRules: true,
        //       WR_Approval: true
        //     },
        //     "entities": [1, 2]
        //   }
        // }
        (userAccess && userAccess.entities && userAccess.entities.length)
          ?
          ReactDOM.createRoot(document.getElementById('root')).render(<Provider store={store}>
            <ThemeProvider theme={customTheme}>
              <HashRouter hashType="noslash">
                {/* <TextInput/> */}
                <App requestHeaders={headers} keycloak={keycloak} userAccess={userAccess} userDetails={response.data} />
              </HashRouter>
            </ThemeProvider>
          </Provider>)
          // ReactDOM.render(<Provider store={store}>
          //   <ThemeProvider theme={customTheme}>
          //     <HashRouter hashType="noslash">
          //       <App requestHeaders={headers} keycloak={keycloak} userAccess={userAccess} userDetails={response.data} />
          //     </HashRouter>
          //   </ThemeProvider>
          // </Provider>, document.getElementById('root'))
          :
          // ReactDOM.render(<NotAuthorizedPage />, document.getElementById('root'))
        ReactDOM.createRoot(document.getElementById('root')).render(<NotAuthorizedPage />)
      });



  });
}
// else if (applicationProperties.general.useAzureLogin) {
//   /**
//  * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
//  * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
//  */
//   const msalInstance = new PublicClientApplication(msalConfig);
//   /**
//    * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
//    */
//   ReactDOM.render(
//     // <React.StrictMode>

//       <Provider store={store}>
//         <ThemeProvider theme={customTheme}>
//           <HashRouter hashType="noslash">
//           <MsalProvider instance={msalInstance}>
//              <Login />
//              </MsalProvider>
//           </HashRouter>
//         </ThemeProvider>
//       </Provider>

//     // </React.StrictMode>,
//     ,
//     document.getElementById("root")
//   );
// }






// If you want to start measuring performance in your app, pass a function

// to log results (for example: reportWebVitals(console.log))

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();