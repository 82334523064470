const FormatDateobjToString = (props) => {
    let miliSec = props.milliSec;
    if (typeof (miliSec) === "string") {
        return miliSec;
    } else if (miliSec === null || miliSec == "") {
        return "";
    }
    var oDate = new Date(miliSec);
    var dd = oDate.getDate();
    var MM = oDate.getMonth() + 1;
    var yy = oDate.getFullYear();
    var hr = oDate.getHours();
    var min = oDate.getMinutes();
    var sec = oDate.getSeconds();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (MM < 10) {
        MM = "0" + MM;
    }
    if (hr < 10) hr = "0" + hr
    if (min < 10) min = "0" + min
    if (sec < 10) sec = "0" + sec
    var newDate = dd + "/" + MM + "/" + yy;
    newDate = newDate + " " + hr + ":" + min + ":" + sec;
    console.log("date" + miliSec + newDate)
    return newDate;
}
export default FormatDateobjToString;