import { useState, useEffect } from 'react';
import { BrowserRouter, BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import RuleEditor from './cw_tr_RuleEditor';
import ViewRules from './cw_tr_ViewRules';
// import ManageRuleChain from './cw_tr_ManageRuleChain';
import { Breadcrumbs, Link, ClickAwayListener } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ExpBuilder from '../util/cw_tr_ExpressionBuilder';
import getOperatorGroup from '../util/cw_tr_getOperatorsGroup';
// import ActivityLog from './cw_tr_activityLog';
// import AuditLog from './cw_tr_AuditLog'
import { Button } from '@mui/material';
import './cw_tr_DetailsLandingPage.css';
import ExpressionPopover from './cw_tr_ExpressionPopover';
import BusyLoader from './busyLoader';
import fetchWrapper from '../../utility/fetchWrapper';
import MessageDialog from '../../components/cw_wr_MessageDialog';
import MessageStrip from '../../components/cw_wr_MessageStrip';
import { applicationProperties } from '../../utility/applicationConfig'
const DetailsLandingPage = (props) => {
    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer)
    const [activityLog, setActivityLog] = useState({
        "showActivityLog": false,
        "ActivityLogData": []
    });
    const [auditLog, setAuditLog] = useState({
        "showAuditLog": false,
        "AuditLogData": []
    });
    const [showHeader, setShowHeader] = useState(true)
    const [FilteredRuleChains, setFilteredRuleChains] = useState([]);
    const [RuleChainHierarchy, setRuleChainHierarchy] = useState([]);
    // const [showExpression, setShowExpression] = useState(false)
    const [oBlockIdsNamesMapping, setBlockIdsNamesMapping] = useState({})
    const [tempDetails, setTempDetails] = useState({})
    const aConditionFields = State.aConditionFields;
    const aActionFields = State.aActionFields;
    const [loading, setloading] = useState(false);
    const [showExpLoader, setShowExpLoader] = useState(false);
    const [MessageDialogContent, setMessageDialog] = useState({
        "open": false,
        "data": "",
        "onConfirm": {}
    });
    const [MessageStripData, setMessageStrip] = useState({
        "show": false,
        "message": "",
        "type": ""

    })
    const fnGetAllTextRules = (oSelectedDetails) => {
        if (oSelectedDetails.oSelectedRS.textrules) {
            oSelectedDetails.oSelectedRS.textrules.forEach(each => each["showicon"] = false)
        }
        dispatch({
            type: "UPDATE_RULES_LIST", "value": oSelectedDetails.oSelectedRS.textrules, "aFilteredTextRules": oSelectedDetails.oSelectedRS.textrules
        })
        // let sUrl1 = "/WorkRulesServices/v1/textrule/getTextRuleByHierarchy?applicationId=" + oSelectedDetails.oAppDetails.applicationId + "&ruleMaintenanceSetId=" + oSelectedDetails.oSelectedRMS.id +
        //     "&ruleSetId=" + oSelectedDetails.oSelectedRS.id + "&isAuthoring=false";
        var sUrl = "/WorkRulesServices/v1/textrule/attributes?applicationId=" + oSelectedDetails.oAppDetails.applicationId + "&rulesetId=" + oSelectedDetails.oSelectedRS.id;
        Promise.all([
            fetchWrapper(sUrl, State.requestOptions).then((res) => res.json())
            // fetchWrapper(sUrl2, State.requestOptions).then((res) => res.json())
        ])
            .then(([aAttributesData]) => {
                setloading(false)
                if (!aAttributesData.data) aAttributesData.data = []
                let message = aAttributesData.message
                if (aAttributesData.statusCode === "401") {
                    message = "Session Timed Out.Kindly Refresh";
                    setMessageDialog({
                        "open": true,
                        "data": message,
                        type: "Error",
                        onConfirm: () => { window.location.reload() }

                    })
                }


                dispatch({
                    type: "UPDATE_ATTRIBUTES_LIST", "value": aAttributesData.data
                })



            })
            .catch((error) => {
                setloading(false)
                console.log(error);
            });

    }
    const fnGetAllRuleChains = (oSelectedDetails) => {
        // setloading(true)
        let sUrl = "/WorkRulesServices/v1/rule-chain/hierarchy?applicationId=" + State.oSelectedDetails.oAppDetails.applicationId + "&ruleMaintenanceSetId=" + State.oSelectedDetails.oSelectedRMS.id +
            "&ruleSetId=" +
            State.oSelectedDetails.oSelectedRS.id + "&ruleChainType=TR";
        Promise.all([
            fetchWrapper(sUrl, State.requestOptions).then((res) => res.json()),
        ])
            .then(([oData]) => {
                setloading(false)
                /// if (!oData.data) oData.data = []
                let message = oData.message
                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    message = "Session Timed Out.Kindly Refresh";
                    setMessageDialog({
                        "open": true,
                        "data": message,
                        type: "Error",
                        onConfirm: () => { window.location.reload() }

                    })
                }
                setRuleChainHierarchy(oData.data.rules)
                setFilteredRuleChains(oData.data.rules)
                // setloading(true)

            })
            .catch((error) => {
                setloading(false)
                console.log(error);
            });
    }
    useEffect(() => {
        // setloading(true)
        fnGetAllTextRules(State.oSelectedDetails);
        // fnGetAllRuleChains(State.oSelectedDetails);

    }, [State.oSelectedDetails.oSelectedRS])

    const fnGetTextRuleDetails = (ruleId, closeExpression, trVersion) => {
        !closeExpression && setloading(true);
        let sUrl = "/WorkRulesServices/v1/textrule?ruleId=" + ruleId + "&version=" + trVersion;
        // if (trVersion)
        //     sUrl = "/WorkRulesServices/v1/textrule?ruleId=" + ruleId + "&version=" + trVersion;
        Promise.all([
            fetchWrapper(sUrl, State.requestOptions).then((res) => res.json()),
        ])
            .then(([oData]) => {
                setloading(false)
                // if (!oData.data) oData.data = []
                let message = oData.message
                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    message = "Session Timed Out.Kindly Refresh";
                    setMessageDialog({
                        "open": true,
                        "data": message,
                        type: "Error",
                        onConfirm: () => { window.location.reload() }

                    })
                }
                // setTextRuleDetails(oData.data);

                let aTextRuleBlocks = [], oBlock, blockType;
                let oBlockIdNames = {}
                // set condition fields and action fields based on orchestration
                let aConditionFields = [];
                let aActionFields = [];

                if (oData.data.isOrchestrated) {
                    aConditionFields = State.aAttributes.attributes
                    aActionFields = State.aAttributes.attributes;
                }
                else {
                    aConditionFields = State.aAttributes.conditionAttributes
                    aActionFields = State.aAttributes.actionAttributes
                }
                dispatch({ type: "UPDATE_CONDITION_FIELDS", value: aConditionFields })
                dispatch({ type: "UPDATE_ACTION_FIELDS", value: aActionFields })
                oData.data.textRuleBlocks.filter(function (block, index) {
                    var defaultBtnVis = false;
                    var aConditionBlockVis = true;
                    var defaultBtnValue = false;
                    var aAction = [];

                    var root = {

                        "childrens": block.conditions,
                        "conditionType": "group",
                        // "dataSetId": null,
                        "expression": null,
                        "logicalRelationNext": null,
                        "parent": null,
                        "root": true,
                        "sequence": 0,
                        "conditionId": "root"
                    };
                    var preorder = function (root, ans = []) {
                        if (!root) return ans
                        ans.push(root)
                        for (let child of root.childrens)
                            preorder(child, ans)
                        return ans
                    };
                    var conditions = preorder(root);
                    conditions.splice(0, 1);
                    preorder(root).forEach(function (condition, cIndex) {
                        var oAttributeDetails, logicalRelationNextVis;
                        var conidtionValue = "";

                        if (condition.logicalRelationNext) {
                            logicalRelationNextVis = true;
                        } else {
                            logicalRelationNextVis = false;
                        }
                        if (condition.conditionType === "single") {
                            aConditionFields.forEach(function (each) {
                                if (condition.attributeId === each.attributeId) {
                                    oAttributeDetails = {
                                        "DataSetname": each.DataSetname,
                                        "attributeId": each.attributeId,
                                        "dataType": each.dataType,
                                        "description": each.description,
                                        "isLookup": each.isLookup,
                                        "length": each.length,
                                        "lookUpId": each.lookUpId,
                                        "name": each.name,
                                        "label": each.label,
                                        "lookUpType": each.lookUpType
                                    }
                                }

                            })
                            var x = condition.expression.split(" ");
                            var conditionKeyPayload = x[0];
                            var conditionValuePayload = "";
                            x.splice("0", 2);
                            for (var i = 0; i < x.length; i++) {
                                conditionValuePayload = conditionValuePayload + x[i] + " ";
                            }
                            var y = condition.displayExpression.split(" ");
                            y.splice("0", 2);
                            let fromValue, fromValuePayload, toValue, toValuePayload, ToconditionValueType = "Value", FromconditionValueType = "Value"
                            for (var p = 0; p < y.length; p++) {
                                conidtionValue = conidtionValue + y[p] + " ";
                            }
                            if (condition.expression.split(" ")[1] === "EXISTSIN" || condition.expression.split(" ")[1] === "NOTEXISTSIN") {
                                var ConditionValueInputVis = false;
                                var arrayOperatorValueHelpVis = true;
                                var aCondValueForArrayOperator = [];
                                let isValuePresent, isVariablePresent, isExpressionPresent, isNullPresent
                                let arrayPayload = condition.expression.split("[")[1].substring(0, condition.expression.split("[")[1].length - 1).split(",")
                                let array = condition.displayExpression.split("[")[1].substring(0, condition.displayExpression.split("[")[1].length - 1).split(",")
                                for (var i = 0; i < arrayPayload.length; i++) {
                                    if (arrayPayload[i] === 'null') {
                                        if (isNullPresent === 0 || isNullPresent) {
                                            aCondValueForArrayOperator[isNullPresent].data.push({
                                                "value": null,
                                                "key": null,
                                                "id": Math.random().toString()

                                            })
                                        }
                                        else {
                                            isNullPresent = i;
                                            aCondValueForArrayOperator.push({
                                                "id": Math.random().toString(),
                                                "type": 'null',
                                                data: [
                                                    {
                                                        "id": Math.random().toString(),
                                                        key: null,
                                                        value: null
                                                    }
                                                ]
                                            })
                                        }
                                    }
                                    else if (arrayPayload[i].trim().startsWith("&")) {
                                        if (isVariablePresent === 0 || isVariablePresent) {
                                            aCondValueForArrayOperator[isVariablePresent].data.push({
                                                "value": array[i].replaceAll("&", ""),
                                                "key": arrayPayload[i].replaceAll("&", ""),
                                                "id": Math.random().toString()

                                            })
                                        }
                                        else {
                                            isVariablePresent = aCondValueForArrayOperator.length;
                                            aCondValueForArrayOperator.push({
                                                "id": Math.random().toString(),
                                                "type": "Variable",
                                                data: [
                                                    {
                                                        "id": Math.random().toString(),
                                                        key: arrayPayload[i].replaceAll("&", ""),
                                                        value: array[i].replaceAll("&", "")
                                                    }
                                                ]
                                            })
                                        }
                                    }
                                    else if (arrayPayload[i].trim().startsWith("{")) {
                                        if (isExpressionPresent === 0 || isExpressionPresent) {
                                            aCondValueForArrayOperator[isExpressionPresent].data.push({
                                                "value": array[i],
                                                "key": arrayPayload[i],
                                                "id": Math.random().toString()

                                            })
                                        }
                                        else {
                                            isExpressionPresent = aCondValueForArrayOperator.length;
                                            aCondValueForArrayOperator.push({
                                                "id": Math.random().toString(),
                                                "type": "Expression",
                                                data: [
                                                    {
                                                        "id": Math.random().toString(),
                                                        key: arrayPayload[i],
                                                        value: array[i]
                                                    }
                                                ]
                                            })
                                        }
                                    }
                                    else {
                                        if (isValuePresent === 0 || isValuePresent) {
                                            aCondValueForArrayOperator[isValuePresent].data.push({
                                                "value": array[i].replaceAll("'", ""),
                                                "key": arrayPayload[i].replaceAll("'", ""),
                                                "id": Math.random().toString()

                                            })
                                        }
                                        else {
                                            isValuePresent = aCondValueForArrayOperator.length;
                                            aCondValueForArrayOperator.push({
                                                "id": Math.random().toString(),
                                                "type": "Value",
                                                data: [
                                                    {
                                                        "id": Math.random().toString(),
                                                        key: arrayPayload[i].replaceAll("'", ""),
                                                        value: array[i].replaceAll("'", "")
                                                    }
                                                ]
                                            })
                                        }
                                    }
                                }

                            }
                            else if (condition.expression.split(" ")[1] === "IN" || condition.expression.split(" ")[1] === "NOTIN") {
                                // let tempPayloadValue = condition.expression.split(" ")[2].split("..");
                                // let tempValue = condition.displayExpression.split(" ")[2].split("..");
                                let tempPayloadValue = "", tempValue = "";
                                for (var k = 2; k < condition.expression.split(" ").length; k++) {
                                    tempPayloadValue = tempPayloadValue + " " + condition.expression.split(" ")[k];

                                }
                                for (var k = 2; k < condition.displayExpression.split(" ").length; k++) {

                                    tempValue = tempValue + " " + condition.displayExpression.split(" ")[k];
                                }
                                tempValue = tempValue.trim().split("..");
                                tempPayloadValue = tempPayloadValue.trim().split("..")
                                if (tempValue[0].includes("[")) {
                                    fromValue = tempValue[0].replace("[", "(In) ");
                                    fromValuePayload = tempPayloadValue[0].replace("[", "(In) ");
                                }
                                else if (tempValue[0].includes("(")) {
                                    fromValue = tempValue[0].replace("(", "(Ex) ");
                                    fromValuePayload = tempPayloadValue[0].replace("(", "(Ex) ");
                                }
                                if (tempValue[1].includes("]")) {
                                    toValue = "(In) " + tempValue[1].substring(0, tempValue[1].length - 1)
                                    toValuePayload = "(In) " + tempPayloadValue[1].substring(0, tempPayloadValue[1].length - 1)
                                }
                                else if (tempValue[1].includes(")")) {
                                    toValue = "(Ex) " + tempValue[1].substring(0, tempValue[1].length - 1)
                                    toValuePayload = "(Ex) " + tempPayloadValue[1].substring(0, tempPayloadValue[1].length - 1)
                                }


                                if (tempPayloadValue[0][1] === "&") {
                                    FromconditionValueType = "Variable"
                                }
                                else if (tempPayloadValue[0][1] === "{") {
                                    FromconditionValueType = "Expression"
                                }
                                if (tempPayloadValue[1][0] === "&") {
                                    ToconditionValueType = "Variable"
                                }
                                else if (tempPayloadValue[1][0] === "{") {
                                    ToconditionValueType = "Expression"
                                }
                                fromValue = fromValue?.trim().replaceAll("'", "");
                                fromValuePayload = fromValuePayload.trim().replaceAll("'", "");
                                toValue = toValue?.trim().replaceAll("'", "");
                                toValuePayload = toValuePayload.trim().replaceAll("'", "");
                            } else {
                                var ConditionValueInputVis = true;
                                var arrayOperatorValueHelpVis = false;
                            }
                            var conditionValueType = "Value";
                            if (conditionValuePayload.startsWith('&')) {
                                conditionValueType = "Variable";

                            }
                            else if (conditionValuePayload.startsWith('{')) {
                                conditionValueType = "Expression"
                            }

                            conidtionValue = conidtionValue.replaceAll("'", "");
                            conditionValuePayload = conditionValuePayload.replaceAll("'", "");
                            // if (conditionValueType === "Variable"){
                            //     aConditionFields.filter(function (each) {
                            //         if (conidtionValue?.trim() === each.name) {
                            //             conidtionValue = each.label
                            //         }

                            //     })
                            // }
                            var oCondition = {
                                "conditionValueType": conditionValueType,
                                "conditionType": "single",
                                "conditionKey": oAttributeDetails,
                                "operatorGroupType": getOperatorGroup(condition.expression.split(" ")[1]),
                                "condOperation": condition.expression.split(" ")[1],
                                "conditionValue": conidtionValue,
                                "logicalRelationNext": condition.logicalRelationNext,
                                "logicalRelationNextVis": logicalRelationNextVis,
                                "sequence": condition.sequence,
                                "conditionKeyPayload": conditionKeyPayload,
                                "conditionValuePayload": conditionValuePayload,
                                "fromValue": fromValue,
                                "toValue": toValue,
                                "fromValuePayload": fromValuePayload,
                                "toValuePayload": toValuePayload,
                                "FromconditionValueType": FromconditionValueType,
                                "ToconditionValueType": ToconditionValueType,
                                "validation": {
                                    "report": {
                                        "leftOperandStatus": "None",
                                        "leftOperandStatusText": "",
                                        "rightOperandStatus": "None",
                                        "rightOperandStatusText": "",
                                        "operatorStatus": "None",
                                        "operatorStatusText": ""
                                    }
                                },
                                "arrayOperatorValueHelpVis": arrayOperatorValueHelpVis,
                                "ConditionValueInputVis": ConditionValueInputVis,
                                "aCondValueForArrayOperator": aCondValueForArrayOperator

                            };

                        } else {
                            var oCondition = {
                                "expand": true,
                                "conditionType": "group",
                                "conditionKey": [],
                                "operatorGroupType": "",
                                "condOperation": "",
                                "conditionValue": "",
                                "logicalRelationNext": condition.logicalRelationNext,
                                "logicalRelationNextVis": logicalRelationNextVis,
                                "sequence": condition.sequence

                            };
                            var color;
                            if (!condition.parent) {
                                oCondition["color"] = "#FFFFFF"
                            }
                            else {
                                for (var s = 0; s < preorder(root).length; s++) {
                                    if (condition.parent === preorder(root)[s].conditionId) {
                                        if (preorder(root)[s].color === "#FFFFFF") {
                                            oCondition["color"] = "#F1F5FE"
                                        }
                                        else {
                                            oCondition["color"] = "#FFFFFF"
                                        }
                                    }
                                }
                            }
                        }

                        preorder(root)[cIndex]["color"] = oCondition["color"]
                        preorder(root)[cIndex]["expand"] = true
                        preorder(root)[cIndex]["conditionValueType"] = conditionValueType;
                        preorder(root)[cIndex]["conditionKey"] = oCondition.conditionKey;
                        preorder(root)[cIndex]["conditionType"] = oCondition.conditionType;
                        preorder(root)[cIndex]["operatorGroupType"] = oCondition.operatorGroupType;
                        preorder(root)[cIndex]["condOperation"] = oCondition.condOperation;
                        preorder(root)[cIndex]["conditionValue"] = oCondition.conditionValue;
                        preorder(root)[cIndex]["logicalRelationNext"] = oCondition.logicalRelationNext;
                        preorder(root)[cIndex]["logicalRelationNextVis"] = oCondition.logicalRelationNextVis;
                        preorder(root)[cIndex]["sequence"] = oCondition.sequence;
                        preorder(root)[cIndex]["conditionKeyPayload"] = oCondition.conditionKeyPayload;
                        preorder(root)[cIndex]["conditionValuePayload"] = oCondition.conditionValuePayload;
                        preorder(root)[cIndex]["validation"] = oCondition.validation;
                        preorder(root)[cIndex]["arrayOperatorValueHelpVis"] = oCondition.arrayOperatorValueHelpVis;
                        preorder(root)[cIndex]["ConditionValueInputVis"] = oCondition.ConditionValueInputVis;
                        preorder(root)[cIndex]["aCondValueForArrayOperator"] = oCondition.aCondValueForArrayOperator;
                        preorder(root)[cIndex]["FromconditionValueType"] = oCondition.FromconditionValueType;
                        preorder(root)[cIndex]["ToconditionValueType"] = oCondition.ToconditionValueType;
                        preorder(root)[cIndex]["fromValue"] = oCondition.fromValue;
                        preorder(root)[cIndex]["fromValuePayload"] = oCondition.fromValuePayload;
                        preorder(root)[cIndex]["toValue"] = oCondition.toValue;
                        preorder(root)[cIndex]["toValuePayload"] = oCondition.toValuePayload;
                        // delete preorder(root)[cIndex].expression;
                        delete preorder(root)[cIndex].dataSetId;
                        if (preorder(root)[cIndex].attributeId) {
                            delete preorder(root)[cIndex].attributeId;
                        }
                    });
                    oData.data.textRuleBlocks[index].result.forEach(function (action, aIndex) {
                        var oAction = {};
                        var actionAtts = {};

                        var actionId = "ACT_" + Math.random().toString();
                        // that.aIDS.push(actionId);
                        aActionFields.forEach(function (act) {
                            if (act.attributeId === action.attributeId) {
                                actionAtts = {

                                    "ActionType": act.ActionType,
                                    "name": act.name,
                                    "description": act.description,
                                    "dataType": act.dataType,
                                    "isLookup": act.isLookup,
                                    "attributeId": act.attributeId,
                                    "length": act.length,
                                    "lookUpId": act.lookUpId,
                                    "lookUpType": act.lookUpType,
                                    "datasetsId": act.datasetsId,
                                    "label": act.label
                                }
                            }
                        })
                        if (action.type == "single") {
                            if (actionAtts.isLookup) {
                                var ValueHelpVis = true;
                                var ActionInputVis = false;
                                var ActionDatePickerVis = false;
                                var ActionDateTimePickerVis = false;
                                var valueData = action.valueData
                            } else if (actionAtts.dataType === "DATE") {
                                var ValueHelpVis = false;
                                var ActionInputVis = false;
                                var ActionDatePickerVis = true;
                                var ActionDateTimePickerVis = false;
                            } else if (actionAtts.dataType === "TIMESTAMP") {
                                var ValueHelpVis = false;
                                var ActionInputVis = false;
                                var ActionDatePickerVis = false;
                                var ActionDateTimePickerVis = true;
                            } else {
                                var ValueHelpVis = false;
                                var ActionInputVis = true;
                                var ActionDatePickerVis = false;
                                var ActionDateTimePickerVis = false;
                                var valueData = action.value
                            }
                            oAction = {
                                "id": actionId,
                                "actionKey": actionAtts,
                                "actionType": "Value",
                                "actionValue": action.value,
                                "deleteBtnVis": false,
                                "ValueHelpVis": ValueHelpVis,
                                "ActionInputVis": ActionInputVis,
                                "ActionDatePickerVis": ActionDatePickerVis,
                                "ActionDateTimePickerVis": ActionDateTimePickerVis,
                                "isValueHelp": ValueHelpVis,
                                "actionValuePayload": action.valueData,
                                "valueData": valueData

                            };
                            aAction.push(oAction);
                        }
                        else if (action.type == "object") {
                            var array = []
                            var keys = Object.keys(action.datas);
                            for (var i = 0; i < keys.length; i++) {
                                let obj = {};
                                obj["field"] = keys[i];
                                obj["value"] = action.datas[keys[i]]
                                array.push(obj)
                            }

                            oAction = {
                                "actionKey": actionAtts,
                                "deleteBtnVis": false,
                                "actionType": "Object",
                                "object": array,
                                "isValueHelp": false
                            };
                            aAction.push(oAction);
                        }
                        else if (action.type == "expression") {
                            oAction = {
                                "id": actionId,
                                "actionKey": actionAtts,
                                "actionType": "Expression",
                                "actionValue": action.value,
                                "deleteBtnVis": false,
                                "actionValuePayload": action.valueData,
                                "valueData": valueData

                            };
                            aAction.push(oAction);
                        }
                    });
                    if (block.blockType === "Default") {
                        defaultBtnVis = true;
                        blockType = "default";
                        aConditionBlockVis = false;
                        defaultBtnValue = true;
                    }
                    oBlock = {
                        "blockId": block.blockId,
                        "defaultBtnVis": defaultBtnVis,
                        "Name": block.name,
                        "blockNameEdit": false,
                        "blockType": blockType,
                        "defaultBtnValue": defaultBtnValue,
                        "aConditionBlockVis": aConditionBlockVis,
                        "aActionBlock": aAction,
                        "aConditionBlock": root.childrens
                    };

                    aTextRuleBlocks.push(oBlock);
                    oBlockIdNames[block.blockId] = block.name;


                })

                oData.data["textRuleBlocks"] = aTextRuleBlocks

                dispatch({ type: "UPDATE_TEXT_RULE_DETAILS", value: oData.data })
                var aBlocksbackUp = [...aTextRuleBlocks]
                var aExpressions = [];
                aBlocksbackUp.forEach(function (block, i) {
                    var blockExp = ExpBuilder(block, i);
                    if (block.blockType == "default") {
                        blockExp = blockExp.split("then ")[1];
                    }
                    aExpressions.push({
                        "Name": block.Name,
                        "expression": blockExp,
                        "blockId": block.blockId,
                        "blockType": block.blockType
                    })
                });

                dispatch({ type: "UPDATE_aBLOCKS_BACKUP", value: aExpressions })
                setBlockIdsNamesMapping(oBlockIdNames)
                let temp = { ...oData.data }
                let newObj = JSON.parse(JSON.stringify(oData.data));
                setTempDetails(newObj)
                // setloading(false)
                closeExpression && setShowExpLoader(false);


            })
            .catch((error) => {
                console.log(error);
                setloading(false)
            });

    }
    const onClickTextrules = () => {
        dispatch({
            type: "UPDATE_RULES_LIST", "value": State.aTextRulesList, "aFilteredTextRules": State.aTextRulesList
        })
        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": false,
                "showRuleEditor": false,
                "showViewRules": true,
            }
        })
    }
    const onClickRuleChain = () => {
        props.setShowExpression(false)
        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": true,
                "showRuleEditor": false,
                "showViewRules": false,
            }
        })
    }
    return <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ width: '100%', paddingLeft: '0.5rem', height: '100%' }}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '0.3rem' }}>
                <Link underline='hover' color="inherit" href="/textrules" className="styleLink">
                    {State.oSelectedDetails.oAppDetails.name}
                </Link>
                <Link underline='hover' color="#757575" className="styleLink">
                    {State.oSelectedDetails.oSelectedRMS.label}
                </Link>
                <Link underline='hover' color="#007AD4" className="styleLink">
                    {State.oSelectedDetails.oSelectedRS.label}
                </Link>

            </Breadcrumbs>
            {showHeader && <div style={{
                paddingTop: '8px', display: 'flex', justifyContent: 'flex-start', borderBottom: '1px solid #E0E0E0', width: '97%',
            }}>
                <Button disableRipple className={State.showDetails.showViewRules ? 'styleActiveTab StyleTab' : 'styleDefaultTab StyleTab'} onClick={onClickTextrules} style={{ marginRight: '1rem' }}>
                {State?.translationsDataObject["TEXTRULES_WR"]?.shortDescription ?? State?.oTranslationsJSON["TEXTRULES_WR"]?.shortDescription ?? "Text Rules"} ({State.aFilteredTextRules.length})
                </Button>
                {/* {applicationProperties.general.showTextRuleChain && <Button disableRipple className={State.showDetails.showRuleChain ? 'styleActiveTab StyleTab' : 'styleDefaultTab StyleTab'} onClick={onClickRuleChain}>
                    Rule Chains ({FilteredRuleChains.length})
                </Button>} */}
            </div>
            }
            {State.showDetails.showViewRules && !loading && <ViewRules setShowExpLoader={setShowExpLoader} showExpLoader={showExpLoader} SubmitFooterWidth={props.SubmitFooterWidth} setloading={setloading} showExpression={props.showExpression} oBlockIdsNamesMapping={oBlockIdsNamesMapping} setBlockIdsNamesMapping={setBlockIdsNamesMapping} setShowExpression={(value) => props.setShowExpression(value)} setShowHeader={setShowHeader} fnGetAllTextRules={(oSelectedDetails) => fnGetAllTextRules(oSelectedDetails)} fnGetTextRuleDetails={(ruleId, closeExpression) => fnGetTextRuleDetails(ruleId, closeExpression)} />}
            {State.showDetails.showRuleEditor && <RuleEditor data={props.data} getDecisionTable={props.getDecisionTable} SubmitFooterWidth={props.SubmitFooterWidth} setloading={setloading} tempDetails={tempDetails} oBlockIdsNamesMapping={oBlockIdsNamesMapping} setBlockIdsNamesMapping={setBlockIdsNamesMapping} showExpression={props.showExpression} setShowExpression={(value) => props.setShowExpression(value)} setShowHeader={setShowHeader} setAuditLog={(logs) => setAuditLog(logs)} auditLog={auditLog} setActivityLog={setActivityLog} activityLog={activityLog} fnGetAllTextRules={(oSelectedDetails) => fnGetAllTextRules(oSelectedDetails)} fnGetTextRuleDetails={(ruleId, closeExp, version) => fnGetTextRuleDetails(ruleId, closeExp, version)} />}
            {/* {State.showDetails.showRuleChain && <ManageRuleChain SubmitFooterWidth={props.SubmitFooterWidth} setloading={setloading} showExpression={props.showExpression} setShowExpression={(value) => props.setShowExpression(value)} FilteredRuleChains={FilteredRuleChains} setFilteredRuleChains={setFilteredRuleChains} setShowHeader={setShowHeader} fnGetAllTextRules={(oSelectedDetails) => fnGetAllTextRules(oSelectedDetails)} fnGetTextRuleDetails={(ruleId) => fnGetTextRuleDetails(ruleId)} RuleChainHierarchy={RuleChainHierarchy} setRuleChainHierarchy={(val) => setRuleChainHierarchy(val)} fnGetAllRuleChains={fnGetAllRuleChains} />} */}
            <BusyLoader show={loading}></BusyLoader>
        </div>

        {props.showExpression && <ExpressionPopover setShowExpLoader={setShowExpLoader} showExpLoader={showExpLoader} setShowExpression={(value) => props.setShowExpression(value)} />}
        {/* {activityLog.showActivityLog && <ActivityLog setActivityLog={(logs) => setActivityLog(logs)} activityLog={activityLog} />} */}
        {/* {auditLog.showAuditLog && <AuditLog setAuditLog={(logs) => setAuditLog(logs)} auditLog={auditLog} />} */}
        <MessageStrip MessageStripData={MessageStripData} setMessageStrip={setMessageStrip} />
        <MessageDialog setMessageDialog={setMessageDialog} MessageDialogContent={MessageDialogContent} />

    </div >
}
export default DetailsLandingPage