import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import CustomIcon from '../icons/CustomIcons';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '2px solid #007AD4',
    borderRadius: '4px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography
      //  className={classes.root}
      {...other}>
      <CustomIcon style={{ width: '1rem', height: '1rem', margin: '0 0.5rem', color: props.color }} icon={"MaterialIcon.MdInfoOutline"} />
      <Typography style={{
        padding: 0, fontSize: '1rem',
        fontWeight: '600',
        color: props.color
      }}>{children}</Typography>
      {/* {onClose ? (
        <IconButton aria-label="close"
         
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </MuiDialogTitle >
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const MessageDialog = (props) => {
  var color = '#007AD4', className = "styleMessageBox";
  if (props.MessageDialogContent.type === "Warning") {
    color = '#FF8F00';
    className = "styleWarningMessageBox";
  }
  else if (props.MessageDialogContent.type === "Error") {
    color = '#B71C1C';
    className = "styleErrorMessageBox";
  }

  const handleClose = () => {
    props.setMessageDialog({
      "data": '',
      'open': false
    });
  };
  const handleOK = () => {
    props.MessageDialogContent.onConfirm();
    props.setMessageDialog({
      "data": '',
      'open': false
    });
  }
  return (
    <div>

      <Dialog className={"styleMessageBox " + className} onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.MessageDialogContent.open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} color={color}>
          {props.MessageDialogContent.type}
        </DialogTitle>
        <MuiDialogContent dividers>

          <Typography gutterBottom style={{ fontSize: '0.8rem' }}>
            {props.MessageDialogContent.data}
          </Typography>
          {props.MessageDialogContent.errorMsgs && <div style={{display: 'flex', alignItems: 'center' }}>
            {props.MessageDialogContent.errorMsgs?.map(each => {
              return <p style={{ margin: '0.5rem 0', fontSize: '0.8rem' }}>{each.text}</p>
            })
            }
          </div>
          }

        </MuiDialogContent>
        <MuiDialogActions style={{ height: '2.5rem', padding: '0 0.2rem' }}>
          <Button style={{ height: '1.8rem' }} disableRipple autoFocus onClick={handleClose} color="primary" className='styledOutlinedButton styleButton'>
            Cancel
          </Button>
          <Button style={{ height: '1.8rem' }} disableRipple autoFocus onClick={handleOK} color="primary" className='styleManageButton styleButton'>
            OK
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  )
}
export default MessageDialog