const getOperatorGroup = (operator) => {
    if (operator === "EXISTSIN" || operator === "NOTEXISTSIN") {
        return "Array Operators";
    } else if (operator === "IN" || operator === "NOTIN") {
        return "Range Operators";
    } else if (operator === "ISEQUAL" || operator === "ISNOTEQUAL" || operator === "ISGREATER" || operator === "ISLESS" || operator ===
        "ISGREATEREQUAL" || operator === "ISLESSEQUAL") {
        return "Comparison Operator";
    } else return "Functional Operators";
}
export default getOperatorGroup