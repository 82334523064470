import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const showToastMessage = (
    sMessage,
    sType,
    position,
    autoClose,
    hideProgressBar
  ) => {
    toast(sMessage, {
      type: sType, // default, error, info, success, warning
      position: position || "bottom-right", // top-left, top-right, top-center, bottom-left, bottom-right, bottom-center
      autoClose: autoClose || 5000, // in ms
      hideProgressBar: hideProgressBar || true, // boolean 
      closeOnClick: true, // boolean 
      pauseOnHover: true, // boolean 
      theme: "light", // light, dark, colored
    });
  
  };
  export default showToastMessage;