import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/lab/Alert';


function Alert(alertprops) {
  return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
}
const MessageStrip = (props) => {
  const handleClose = () => {
    props.setMessageStrip({
      ...props.MessageStripData.type,
      "show": false,
      "message": ""
    }
    )
  }
  return (<Snackbar
    open={props.MessageStripData.show}
    TransitionComponent='Fade'
    autoHideDuration={5000}
    onClose={handleClose}
    anchorOrigin={{ vertical:'bottom', horizontal:'left' }}
  >
    <Alert onClose={handleClose} severity={props.MessageStripData.type}>
      {props.MessageStripData.message}
    </Alert>
  </Snackbar>
  )
}
export default MessageStrip