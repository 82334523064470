
const addZero = (value) => {
  if (value < 10) {
    value = "0" + value
  }
  return value
}
const FormatDate = (props) => {
  let format = props.format;
  let value = props.value;
  if (value === 0) {
    return "1970-01-01"
  }
  if (!value) {
    return value;
  }
  let yyyy, MM, dd;
  value = value.toString();
  if (value.includes("-")) {
    var arr = value.split("-");
    yyyy = arr[0];
    MM = arr[1] < 0 ? addZero(arr[1]) : arr[1];
    dd = arr[2] < 0 ? addZero(arr[2]) : arr[2];
  }
  else if (value.includes("/")) {
    var arr = value.split("/");
    yyyy = arr[2];
    MM = arr[0]
    dd = arr[1]
  } else {
    let val = parseInt(value);
    value = isNaN(val) ? value : val;
    let date = new Date(value);
    yyyy = date.getFullYear();
    MM = date.getMonth() + 1 < 10 ? addZero(date.getMonth() + 1) : date.getMonth() + 1;
    dd = date.getDate() < 10 ? addZero(date.getDate()) : date.getDate();
  }

  let formattedDate = "";
  if (format === "dd/MM/yyyy") {
    formattedDate = dd + "/" + MM + "/" + yyyy;
  } else if (format === "MM/dd/yyyy") {
    formattedDate = MM + "/" + dd + "/" + yyyy;
  } else if (format === "dd-MM-yyyy") {
    formattedDate = dd + "-" + MM + "-" + yyyy;
  } else if (format === "MM-dd-yyyy") {
    formattedDate = MM + "-" + dd + "-" + yyyy;
  } else if (format === "yyyy-MM-dd") {
    formattedDate = yyyy + "-" + MM + "-" + dd;
  }
  return formattedDate;
};
export default FormatDate
