const initialState = {
    "counter": 0,
    "oSelectedDetails": {
        "oAppDetails": {},
        "oSelctedRMS": {},
        "oSelectedRS": {},
        "oSelectedDT": {}
    },
    "oFromSelectionDetails": {
        "oAppDetails": {},
        "oSelctedRMS": {},
        "oSelectedRS": {},
        "oSelectedDT": {}
    },
    "aApplicationsListFrom": [],
    "aRMSlistFrom": [],
    "aRSlistFrom": [],
    "aDTlistFrom": [],
    "aApplicationsList": [],
    "aRMSlist": [],
    "aRSlist": [],
    "aDTlist": [],
    "aActionTypeData": [],
    "aConditionFields": [],
    "applicationCatalogDatasets": [],
    "validStartDateAndStatusGenerated": {
        validStartDateAndEndDate: '',
        result: {},
        validStartDateGenerated: '',
        validToDateGenerated: '',
        status: {}
    },
    'detailsPageItemsVisibility': {
        adminConsole: false,
        landing: true,
        DTcreation: false,
        DTlist: false
    },
    'hierarchyCount': {},
    "operatorsList": [],
    openPopover: false,
    anchorElementOperator: null,
    aDecisionTablelist: [],
    attributeMaster: [],
    SelectedActionTypeTable: [],
    SelectedConditionTypeTable: [],
    usageIdSelected: '',
    isExpression: 'false',
    hitPolicyExclusiveOrInclusive: 'Exclusive',
    ConditionDepeOrIndependent: 'Independent',
    actionDepeOrIndependent: "Independent",
    isCompositeSelected: true,
    aRuleList: [],
    showBusyLoader: false,
    DTcreationSource: 'sidenav',
    isOrchestrated: false,
    reactFlowInstance: [],
    Nodes: [],
    Edges: [],
    decisionTableDetails: [],
    setNodes: "",
    setEdges: "",
    oActionType: {},
    selectedNodesTV: [],
    checkSwitchTV: {}
}
function modelling_rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'INCREMENT':
            return { ...state, counter: action.value }
        case 'UPDATE_SELECTED_DETAILS':
            return { ...state, oSelectedDetails: action.value }
        case 'UPDATE_SELECTED_DETAILS_FROM':
            return { ...state, oFromSelectionDetails: action.value }
        case 'UPDATE_APPLICATIONS_LIST':
            return { ...state, aApplicationsList: action.value }
        case 'UPDATE_RMS_LIST':
            return { ...state, aRMSlist: action.value }
        case 'UPDATE_RS_LIST':
            return { ...state, aRSlist: action.value }
        case 'UPDATE_DT_LIST':
            return { ...state, aDTlist: action.value }
        case 'UPDATE_APPLICATIONS_LIST_FROM':
            return { ...state, aApplicationsListFrom: action.value }
        case 'UPDATE_RMS_LIST_FROM':
            return { ...state, aRMSlistFrom: action.value }
        case 'UPDATE_RS_LIST_FROM':
            return { ...state, aRSlistFrom: action.value }
        case 'UPDATE_DT_LIST_FROM':
            return { ...state, aDTlistFrom: action.value }
        case 'UPDATE_ACTION_TYPE':
            return { ...state, aActionTypeData: action.value }
        case 'UPDATE_CONDITION_FIELDS':
            return { ...state, aConditionFields: action.value }
        case 'UPDATE_APPLICATION_CATALOG_DATASETS':
            return { ...state, applicationCatalogDatasets: action.value }
        case 'UPDATE_VALIDTY_PERIOD_STATUS':
            return { ...state, validStartDateAndStatusGenerated: action.value }
        case 'SET_DEATILS_PAGE_ITEMS_VISIBILITY':
            return { ...state, detailsPageItemsVisibility: action.value }
        case 'UPDATE_HIERARCHY_COUNT':
            return { ...state, hierarchyCount: action.value }
        case 'UPDATE_OPERATORS_LIST':
            return { ...state, operatorsList: action.value }
        case 'setOpenPopover':
            return { ...state, openPopover: action.value }
        case 'handleAnchorElement':
            return { ...state, anchorElementOperator: action.value }
        case 'UPDATE_DECISION_TABLE_LIST':
            return { ...state, aDecisionTablelist: action.value }
        case 'UPDATE_ATTRIBUTE_MASTER':
            return { ...state, attributeMaster: action.value }
        case 'setIsExpression':
            return { ...state, isExpression: action.value }
        case 'setHitPolicyExclusiveOrInclusive':
            return { ...state, hitPolicyExclusiveOrInclusive: action.value }
        case 'setUsageIdSelected':
            return { ...state, usageIdSelected: action.value }
        case 'setConditionDepeOrIndependent':
            return { ...state, ConditionDepeOrIndependent: action.value }
        case 'setActionDepeOrIndependent':
            return { ...state, actionDepeOrIndependent: action.value }
        case 'setCompositeSelected':
            return { ...state, isCompositeSelected: action.value }
        case 'setIsOrchestrated':
            return { ...state, isOrchestrated: action.value }
        case 'setSelectedActionTypeTable':
            return { ...state, SelectedActionTypeTable: action.value }
        case 'setSelectedConditionTypeTable':
            return { ...state, SelectedConditionTypeTable: action.value }
        case 'setFilteredConditionFields':
            return { ...state, aFilteredConditionFields: action.value }
        case 'UPDATE_RULE_LIST':
            return { ...state, aRuleList: action.value }
        case 'UPDATE_BUSY_LOADER':
            return { ...state, showBusyLoader: action.value }
        case 'UPDATE_DTCREATION_SOURCE':
            return { ...state, DTcreationSource: action.value }
        case 'SET_REACTFLOW':
            return { ...state, reactFlowInstance: action.value }
        case 'SET_NODES':
            return { ...state, Nodes: action.value }
        case 'SET_NODES_FUNC':
            return { ...state, setNodes: action.value }
        case 'SET_EDGES_FUNC':
            return { ...state, setEdges: action.value }
        case 'SET_EDGES':
            return { ...state, Edges: action.value }
        case 'SET_DECISION_TABLES':
            return { ...state, decisionTableDetails: action.value }
        case "SET_ACTION_TYPE":
            return {...state, oActionType: action.value }
        case "SET_SELECTED_NODES_TV":
            return {...state, selectedNodesTV: action.value}
        case "SET_CHECK_SWITCH":
            return {...state, checkSwitchTV: action.value}
        default:
            return state;
    }
}
export default modelling_rootReducer;