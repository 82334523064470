import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./utility/authConfig";
import { callMsGraph } from "./utility/graph";
import App from './App'
const Login = () => {

    const navigate = useNavigate();
    useEffect(() => {
        if (sessionStorage.getItem("loggedInEmail") != null) {
            navigate('/home');
        }
        else {
            RequestProfileData();
        }

    }, [])

    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const name = accounts[0] && accounts[0].name;
    const isAuthenticated = useIsAuthenticated();
    let headers = {},userAccess={},userDetails={}
    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request)
            .then((response) => {
                callMsGraph(response.accessToken)
                    .then((response) => {
                        const bearer = 'Bearer ' + response.accessToken;
                        headers = {
                            "Content-Type": 'application/json',
                            "Authorization": bearer,
                        };
                        const url = "https://reuseportalbackendservice.cherrywork.com/addUserDetails";
                        const formData = new FormData();
                        formData.append("name", response.displayName);
                        formData.append("email", response.mail);
                        formData.append("position", response.jobTitle);
                        formData.append("uid", response.id);

                        fetch(url, {

                            method: 'POST',
                            body: formData

                        })
                            .then((res) => {
                                return res.json();
                            })
                            .then((data) => {
                                if (data.status === "1") {
                                    sessionStorage.setItem("loggedInEmail", response.mail);
                                    sessionStorage.setItem("loggedInPosition", response.jobTitle);
                                    sessionStorage.setItem("loggedInName", response.displayName);
                                    sessionStorage.setItem("loggedInId", response.id);
                                    sessionStorage.setItem("loggedInImage", data.imageurl);
                                    sessionStorage.setItem("loggedInRole", data.userRole);
                                    navigate('/home');
                                }
                            });
                    });
            }).catch((e) => {
                instance.acquireTokenPopup(request)
                    .then((response) => {
                        callMsGraph(response.accessToken)
                            .then((response) => {

                                const url = "https://reuseportalbackendservice.cherrywork.com/addUserDetails";
                                const formData = new FormData();
                                formData.append("name", response.displayName);
                                formData.append("email", response.mail);
                                formData.append("position", response.jobTitle);
                                formData.append("uid", response.id);

                                fetch(url, {

                                    method: 'POST',
                                    body: formData

                                }).then((res) => {
                                    return res.json();
                                }).then((data) => {
                                    if (data.status === "1") {
                                        sessionStorage.setItem("loggedInEmail", response.mail);
                                        sessionStorage.setItem("loggedInPosition", response.jobTitle);
                                        sessionStorage.setItem("loggedInName", response.displayName);
                                        sessionStorage.setItem("loggedInId", response.id);
                                        sessionStorage.setItem("loggedInImage", data.imageurl);
                                        sessionStorage.setItem("loggedInRole", data.userRole);
                                        navigate('/home');
                                    }
                                });

                            });
                    });
            });
    }
    return (
        <div className="login-page">
            <div id="header">
                <div id="header">
                    <nav className="navbar fixed-top navbar-light bg-transperant">
                        <Link className="navbar-brand" to="/">
                            <img src="./logo_reuse.png" alt="" />
                        </Link>
                    </nav>
                </div>
            </div>


            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6">
                        <div className="login d-flex align-items-center py-5">

                            <div className="container">
                                <div className="row">
                                    <div className="side-1 col-lg-10 col-xl-7 mx-auto">

                                        {
                                            !isAuthenticated && <div> <p className="h1 login-head">Welcome!</p>
                                                <p className="h6 login-para">Please login to your account to continue</p>
                                                <br /> <button className="btn btn-primary" id="loginBtn" onClick={RequestProfileData}>
                                                    Login
                                                </button>
                                            </div>
                                        }
                                        {
                                            isAuthenticated && <App requestHeaders={headers} userAccess={userAccess} userDetails={userDetails} />
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 d-none d-md-flex bg-image">
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Login