import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import { Popover, Accordion, AccordionDetails, AccordionSummary, Link, Tooltip, IconButton, Typography, TextField, InputAdornment } from "@mui/material";
import './cw_wr_sideNavHierachy.css';
import PostOrder from '../util/cw_tr_postOrder';
import { useSelector, useDispatch } from 'react-redux';
import CustomIcon from "../../icons/CustomIcons";
const useTreeItemStyles = makeStyles((theme) => ({

    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `#007AD4`,
            color: '#ffffff',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            background: 'transparant'
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        width: '100%'
    },
}));



export default function SideNavHierarchy(props) {
    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer)
    let navigate = useNavigate();
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [aAttributes, setaAttributes] = React.useState([]);
    const [aConditionFields, setaConditionFields] = React.useState([]);
    const [aActionFields, setaActionFields] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverData, setPopoverData] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState([]);
    const open = Boolean(anchorEl);
    const useStyles = makeStyles({
        root: {
            height: 216,
            flexGrow: 1,
            maxWidth: 400,
        },
    });
    const classes = useStyles();
    const root = {
        "id": 'root',
        "status": true,
        "statusCode": 200,
        "message": null,
        "totalCount": 0,
        "childs": [...props.data]
    }


    let aLinearRMSlist = PostOrder(root)
    const fnGetHierarchyIds = (rs) => {
        let oSelectedDetails = { ...State.oSelectedDetails }
        let RulesetDetails = { ...rs }
        oSelectedDetails["oSelectedRMS"] = RulesetDetails.RMS
        delete RulesetDetails.RMS
        oSelectedDetails["oSelectedRS"] = RulesetDetails
        // aLinearRMSlist.forEach(each => {
        //     if (each.id === id) {
        //         oSelectedDetails["oSelectedRMS"] = each.RMS
        //         oSelectedDetails["oSelectedRS"] = each
        //     }
        // })
        dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })
        return oSelectedDetails
    }


    const onClickRS = (rs) => {

        let oSelectedDetails = fnGetHierarchyIds(rs);
        let data = [...popoverData];
        data.forEach(each => {
            if (each.id === rs.id) {
                each["Active"] = true;
            }
            else {
                each["Active"] = false
            }
        })
        setPopoverData(data);
        setAnchorEl(null);
        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": false,
                "showRuleEditor": false,
                "showViewRules": true,
            }
        })

        props.setShowDetailsPage(true);
    }



    const onNavBackToApplication = () => {
        let applications = [...props.aApplicationsList]
        applications.forEach(each => each['isSelected'] = false)
        props.setnewapplication(applications);
        props.setApplicationNav(true);
        props.setShowDetailsPage(false)

    }
    const onSelectRMS = (event, selectedRMS) => {
        let data = [...props.data];
        data.forEach(each => {
            if (each.id === selectedRMS.id) {
                each["Active"] = true;
            }
            else {
                each["Active"] = false;
            }
        })
        props.setDecisionTableHierarchy(data)
        setPopoverData(selectedRMS.childs)
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const fnOnChangeSearchValue = (event) => {
        setSearchValue(event.target.value)
        if (event.key === 'Enter') {
            fnFilterData(event.target.value)
        }
    }
    const fnFilterData = (value) => {
       
        value = value.toLowerCase();
        let rmslist = [...State.RMSlist];
        let aFilteredRMSlist = []
        rmslist.forEach(function (rms) {
            if (rms.label.toLowerCase().includes(value) || rms.name.toLowerCase().includes(value) || rms.description.toLowerCase().includes(value)) {
                aFilteredRMSlist.push(rms)
            }
            else {
                rms.childs?.forEach(function (rs) {
                    if (rs.label.toLowerCase().includes(value) || rs.name.toLowerCase().includes(value) || rs.description.toLowerCase().includes(value)) {
                        aFilteredRMSlist.push(rms)
                    }
                })
            }

        })
        props.setDecisionTableHierarchy(aFilteredRMSlist)

    }
    return (


        <div>
            <div style={{ display: 'flex', marginTop: '1rem', alignItems: 'center' }}>
                <IconButton aria-label="delete" size="small" onClick={onNavBackToApplication}>
                    <CustomIcon style={{ width: '1rem' }} icon={"AntIcon.AiOutlineArrowLeft"} />
                    {/*<ArrowBackIcon fontSize = "inherit"/> */}
                </IconButton>
                <Link onClick={onNavBackToApplication} style={{ marginLeft: '0.5rem' }}>{State.oSelectedDetails.oAppDetails.name}</Link>

            </div>
            <TextField autoComplete='off'
                variant='outlined'
                style={{ height: '0.8rem', marginbotton: '0.5rem', textAlign: 'center', margin: '0.3rem 0 1rem 0.5rem', width: '12rem', background: '#ffffff' }}
                placeholder="Search"
                className="styleSearch"
                value={searchValue}
                onKeyUp={fnOnChangeSearchValue}
                onChange={fnOnChangeSearchValue}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility">
                                <CustomIcon fill="none" width="21" height="14" icon={"MaterialIcon.MdOutlineSearch"} />
                                {/* <SearchIcon /> */}
                            </IconButton>

                        </InputAdornment>
                    )
                }} />


            <div>

                <div>
                    <div style={{
                        display: 'flex', height: '1.5rem', margin: '1rem 0.5rem 0.5rem 0.5rem', fontSize: '0.875rem', borderBottom: '1px solid #E0E0E0'
                    }}>
                        <IconButton fontSize='small' size='small' className='styleButton'>
                            {/* <img

                                src={RMSicon}
                                alt="Logo"
                                style={{ height: "1rem", color: "black", width: "1rem" ,marginRight:'3px'}}
                            /> */}
                            <CustomIcon style={{ width: '1.2rem', height: '1.2rem', marginRight: '3px' }} icon={"SVGIcons.RMSicon"} />
                        </IconButton> {State?.translationsDataObject["RULE MAINTAINANCE SET_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE MAINTAINANCE SET_WR"]?.shortDescription ?? "Rule Maintainance Set"}</div>
                    <div style={{ margin: '0.5rem', cursor: "pointer"  }}>  {root.childs.map((child) => {
                        console.log(child)
                        if (child.entity === 'Rule Maintenance Set') {
                            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', minHeight: '2rem', marginBottom: '0.5rem', paddingLeft: '0.5rem', backgroundColor: child.Active ? '#C3E6FF' : '#FFFFFF', wordBreak: 'break-all', fontWeight: child.Active ?'600':'500' ,fontSize:'0.875rem'}}
                                onClick={(event) => onSelectRMS(event, child)}>{child.label}
                                <IconButton className='styleButton'>
                                    <CustomIcon style={{ width: '1.3rem' }} icon={"MaterialIcon.MdOutlineNavigateNext"} />
                                    {/* <NavigateNextIcon fontSize='small' /> */}
                                </IconButton></div>
                        }

                    })}
                    </div>
                </div>
                <div>
                    <div style={{
                        display: 'flex', alignItems: 'center', height: '1.5rem', margin: '1rem 0.5rem 0.5rem 0.5rem', fontSize: '0.875rem', borderBottom: '1px solid #E0E0E0'
                    }}>
                        <IconButton className='styleButton styleListIcon'>
                            <CustomIcon style={{ width: '1rem', marginRight: '3px' }} icon={"FontAwesome.FaRegListAlt"} />
                            {/* <ListAltIcon /> */}
                        </IconButton>{State?.translationsDataObject["RULE LIST_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE LIST_WR"]?.shortDescription ?? "Rule List"}</div>
                    <div>  {root.childs.map((child) => {
                        if (child.entity === 'Rule List') {
                            return <div>{child.label}</div>
                        }

                    })}
                    </div>
                </div>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ width: '13rem' }}>

                    <div>
                        <div style={{
                            display: 'flex', alignItems: 'center', height: '1.5rem', margin: '0.5rem 0.5rem 0.5rem 0.5rem', fontSize: '0.875rem', borderBottom: '1px solid #E0E0E0'
                        }}>
                            <IconButton fontSize='small' size='small' className='styleButton'>
                                {/* <img

                                    src={RMSicon}
                                    alt="Logo"
                                    style={{ height: "1rem", color: "black", width: "1rem" }}
                                /> */}
                                <CustomIcon fill="none" width="21" height="14" icon={"AntIcon.AiFillAppstore"} /></IconButton>{State?.translationsDataObject["RULE SET_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE SET_WR"]?.shortDescription ?? "Rule Set"}</div>
                        <div style={{ margin: '0.5rem' }}>  {popoverData.map((child) => {
                            if (child.entity === 'Rule Set') {
                                return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', minHeight: '2rem', marginBottom: '0.5rem', paddingLeft: '0.5rem', backgroundColor: child.Active ? '#C3E6FF' : '#FFFFFF', wordBreak: 'break-word', cursor: "pointer" }}
                                    onClick={(event) => onClickRS(child)}>{child.label}<IconButton className='styleButton'>
                                    <CustomIcon style={{ width: '1.3rem' }} icon={"MaterialIcon.MdOutlineNavigateNext"} />
                                </IconButton>
                                </div>
                            }

                        })}
                        </div>
                    </div>
                    <div>
                        <div style={{
                            display: 'flex', alignItems: 'center', height: '1.5rem', margin: '1rem 0.5rem 0.5rem 0.5rem', fontSize: '0.875rem', borderBottom: '1px solid #E0E0E0'
                        }}>
                            <IconButton className='styleButton styleListIcon'>
                                <CustomIcon style={{ width: '1rem', marginRight: '3px' }} icon={"FontAwesome.FaRegListAlt"} />
                                {/* <ListAltIcon /> */}
                            </IconButton>{State?.translationsDataObject["RULE LIST_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE LIST_WR"]?.shortDescription ?? "Rule List"}</div>
                        <div>  {root.childs.map((child) => {
                            if (child.entity === 'Rule List') {
                                return <div>{child.label}</div>
                            }

                        })}
                        </div>
                    </div>
                </div>
            </Popover>
        </div>


    )
}
