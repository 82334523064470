import { Select, MenuItem, Popover, ClickAwayListener, IconButton, Button, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from "@mui/material";
import CustomIcon from "../icons/CustomIcons";
import { useSelector, useDispatch } from "react-redux";
import './cw_RuleListTableView.css';
import { useState } from "react";
import fetchWrapper from '../utility/fetchWrapper';
import MessageDialog from './cw_wr_MessageDialog';
import MessageStrip from './cw_wr_MessageStrip';
import FormatStatusColor from '../textrules/util/cw_tr_formatStatusColor';
import RuleListCreation from "./cw_RuleListCreation";
import FormatDate from "../functions/cw_DateFormatter";

const RuleListTableView = (props) => {
    const State = useSelector((state) => state.rootReducer);
    const dispatch = useDispatch();
    const [aDecisionTableRuleList, setdecisionTableRuleList] = useState([]);
    const [MessageDialogContent, setMessageDialog] = useState({
        "open": false,
        "data": "",
        "onConfirm": {}
    });
    const [MessageStripData, setMessageStrip] = useState({
        "show": false,
        "message": "",
        "type": ""

    })
    const [showCreationPopover, setShowCreationPopover] = useState(false);
    const [creationPopoveranchor, handleCreationPopoveranchor] = useState(null)
    const [selectedDecisionTable, setSelectedDecisionTable] = useState(null)
    const [showRuleListCreationView, setShowRuleListCreationView] = useState(false)
    const [RuleListDetails, setRuleListDetails] = useState({})
    const [dtAccessPermissionForms, setdtAccessPermissionForms] = useState(null);
    const [aSaveModelData, setaSaveModelData] = useState([])
    const [authorizationReadWriteaccess, setauthorizationReadWriteaccess] = useState({
        show: false,
        text: ""
    })
    const [staticOrFlexibleVisible, setStaticOrFlexibleVisible] = useState(false)
    const [flexibleFormVisible, setFlexibleFormVisible] = useState(false)
    const [staticFormVisible, setStaticFormVisible] = useState(false)
    const [actionDropDownVisible, setActionDropDownVisible] = useState(false)
    const [conditionDropDownVisible, setConditionDropDownVisible] = useState(false)
    const [aActionListFlexibleForm, setaActionListFlexibleForm] = useState([])
    const [aConditionsListFlexibleForm, setaConditionListFlexibleForm] = useState([])
    const [decisionTableFormType, setDecisionTableFormType] = useState(null)
    const [aConditionsListForFlexibleSelection, setaConditionsListForFlexibleSelection] = useState([])
    const [SubmitRuleListBtnEnable, setSubmitRuleListBtnEnable] = useState(false)
    const [SelectedVarientIdFlexibleForm, setSelectedVarientIdFlexibleForm] = useState(null)
    const [VariantInstancesList, setVariantInstancesList] = useState([]);
    const [variantInstanceDetails, setVariantInstanceDetails] = useState({});
    const [authorisationExpressionMsg, setauthorisationExpressionMsg] = useState("")
    const [CommentsDetails, setCommentsDetails] = useState({
        "show": false,
        "data": []
    })
    const getDecisionTableList = (applicationId, ruleMaintenanceId, ruleSetId) => {
        var sUrl;
        var sQuery;
        sUrl = "/WorkRulesServices/v1/decision-table?";
        if (!applicationId && !ruleMaintenanceId && !ruleSetId) {
            sUrl = "/WorkRulesServices/v1/decision-table?";
        } else {
            if (applicationId) {
                if (!sQuery) {
                    sQuery = "applicationId=" + applicationId;
                } else {
                    sQuery = sQuery + "&applicationId=" + applicationId;
                }
            }
            if (ruleMaintenanceId) {
                if (!sQuery) {
                    sQuery = "ruleMaintenanceSetId=" + ruleMaintenanceId;
                } else {
                    sQuery = sQuery + "&ruleMaintenanceSetId=" + ruleMaintenanceId;
                }
            }
            if (ruleSetId) {
                if (!sQuery) {
                    sQuery = "ruleSetId=" + ruleSetId;
                } else {
                    sQuery = sQuery + "&ruleSetId=" + ruleSetId;
                }
            }
            if (sQuery) {
                sUrl = sUrl + sQuery;
            }

        }
        dispatch({ type: 'UPDATE_BUSY_LOADER', value: true })
        props.setLoading(true)
        let promise = new Promise(function (resolve, reject) {
            Promise.all([
                fetchWrapper(sUrl, State.requestOptions).then((res) => res.json()),

            ])
                .then(([oData]) => {
                    props.setLoading(false)
                    dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
                    if (!oData.data) oData.data = []
                    let message = oData.message

                    if (oData.statusCode === 401 || oData.statusCode === '401') {
                        message = "Session Timed Out.Kindly Refresh"
                        setMessageDialog({
                            "open": true,
                            "data": message,
                            type: "Error",
                            onConfirm: () => { window.location.reload() }

                        })
                    }
                    dispatch({ type: 'UPDATE_DECISION_TABLE_LIST', value: oData.data })
                    var decisiontableList = oData.data;
                    var decisionTableRuleList = [];
                    var ruleListTables = [...State.aRuleList]
                    ruleListTables[0].ruleListDecisionTableMapping.filter(function (dt) {
                        decisiontableList.filter(function (element) {
                            if (dt.decisionTableId === element.decisionTableId) {
                                if (element.labels) {
                                    element.labels.filter(function (label) {
                                        element["text"] = label.text;
                                    });
                                } else {
                                    element["text"] = element.name;
                                }
                                decisionTableRuleList.push(element);
                            }
                        });
                    });
                    var unique = new Set(decisionTableRuleList.map(e => JSON.stringify(e)));
                    decisionTableRuleList = Array.from(unique).map(e => JSON.parse(e));
                    setdecisionTableRuleList(decisionTableRuleList);
                    // props.setLoading(false)

                    resolve();

                })
                .catch((error) => {
                    dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
                    setMessageStrip({
                        show: true,
                        type: 'error',
                        message: error.message
                    })
                    console.log(error);
                });
        })
        return promise;

    }
    const onAddNewRule = (event) => {
        var target = event.currentTarget
        getDecisionTableList(State.oSelectedDetails.oAppDetails.applicationId, State.oSelectedDetails.oSelectedRMS.id).then(function () {
            setShowCreationPopover(true)
            handleCreationPopoveranchor(target);
        }.bind(this))
    }
    const onSelectDT = (event) => {
        setSelectedDecisionTable(event.target.value)

    }
    const fnCreateNewRule = (rule) => {
        setShowCreationPopover(false)
        handleCreationPopoveranchor(null);
        setShowRuleListCreationView(true);
        var formType = "flexible";
        var decTableId = rule.decisionTableId ? rule.decisionTableId : selectedDecisionTable.decisionTableId;
        var dtVersion = rule.decisionTableId ? rule.dtVersion : selectedDecisionTable.dtVersion
        var aRuleList = [...State.aRuleList]
        setRuleListDetails({
            name: rule.ruleName ? rule.ruleName : null,
            validityFromDate: '1970-01-01',
            validityToDate: '9999-12-31'
        })
        aRuleList[0].ruleListDecisionTableMapping.filter(function (decTableMapping) {
            if (decTableMapping.decisionTableId === decTableId) {
                formType = decTableMapping.formType;
            }
        });
        var iIndex, iIndex1;
        setaSaveModelData([]);
        fngetAllColumnVarientInstance(decTableId, rule, dtVersion).then(function (variantInstanceDetails) {
            setauthorizationReadWriteaccess({
                show: false,
                text: ""
            })

            if (formType === "Static") {
                setStaticOrFlexibleVisible(true)
                setFlexibleFormVisible(false)
                setStaticFormVisible(true)
            } else {
                setStaticOrFlexibleVisible(true)
                setFlexibleFormVisible(true)
                setStaticFormVisible(false)
                setActionDropDownVisible(true)
                setConditionDropDownVisible(true)
            }
            // if (variantInstanceDetails.isExpression) {
            //     // oVisibilityPropModel.setProperty("/massAddValuehelpAddButton", false);
            // } else {
            //     // oVisibilityPropModel.setProperty("/massAddValuehelpAddButton", true);
            // }
            setDecisionTableFormType(formType);
            // this.notSelected = false;
            var aConditionsListCopy = structuredClone(variantInstanceDetails.aConditionsList)
            // var aConditionsListCopy = $.extend(true, [], ruleAuthoringModel.getProperty("/variantInstanceDetails/aConditionsList"));
            setaConditionsListForFlexibleSelection(aConditionsListCopy);
            setSubmitRuleListBtnEnable(false)
            // oVisibilityPropModel.setProperty("/submitRuleListBtnEnable", false);

            // this.rowIndex = "-1";
        }.bind(this));
    }
    const fnGetComments = (variantInstanceDetails) => {
        if (variantInstanceDetails.values?.length !== 0) {
            let sUrl = "/WorkRulesServices/v1/logs/communication?entityId=" + variantInstanceDetails.values[0].RULE_RECORD_ID +
                "&entityType=" + "RuleRecord";
            Promise.all([
                fetchWrapper(sUrl, State.requestOptions).then((res) => res.json())
            ])
                .then(([oData]) => {
                    if (oData.status && oData.statusCode === 200) {
                        setCommentsDetails({
                            "show": false,
                            "data": oData.data,
                            "row": {}
                        })
                    }
                })


                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            setCommentsDetails({
                "show": false,
                "data": [],
                "row": {}
            })
        }
    }
    const fngetAllColumnVarientInstance = (decTableId, rule, dtVersion) => {

        var selectedRuleSetId = State.oSelectedDetails.oSelectedRS.id;
        var selectedAppDetails = State.oSelectedDetails.oAppDetails;
        var requiredFor;
        if (selectedAppDetails.source === "ECC") {
            requiredFor = "pricing";
        } else {
            requiredFor = "workrules";
        }
        var sUrl = "/WorkRulesServices/v1/commons/variant-instances?definitionId=" + decTableId + "_" + dtVersion + "&id=" + selectedRuleSetId +
            "&idType=RuleSet" + "&rquiredFor=" + requiredFor;
        var sUrl2 = "/WorkRulesServices/v1/decision-table/operators"
        props.setLoading(true);
        let promise = new Promise(function (resolve, reject) {
            Promise.all([
                fetchWrapper(sUrl, State.requestOptions).then((res) => res.json())
            ])
                .then(([oData]) => {
                    props.setLoading(false)
                    if (!oData.data) oData.data = []
                    let message = oData.message

                    if (oData.statusCode === 401 || oData.statusCode === '401') {
                        message = "Session Timed Out.Kindly Refresh"
                        setMessageDialog({
                            "open": true,
                            "data": message,
                            type: "Error",
                            onConfirm: () => { window.location.reload() }

                        })
                    }
                    if (oData.status && oData.statusCode === 200 && oData.data !== null) {
                        var VariantList = oData.data;
                        var selectedVarientId;


                        VariantList.forEach(function (element) {
                            if (element.variantName === "ALL_COLUMNS") {
                                selectedVarientId = element.variantId;
                                setSelectedVarientIdFlexibleForm(element.variantId);
                                if (element.isExpression) {
                                    fnGetOperatorsList().then(function (operatorsList) {
                                        fnGetDecisionTableDetails(rule, selectedVarientId, oData.data, operatorsList).then(function (variantInstanceDetails) {
                                            resolve(variantInstanceDetails)
                                            let fromDate = variantInstanceDetails.values.length !== 0 && FormatDate({ format: "yyyy-MM-dd", value: variantInstanceDetails.values[0].VALID_FROM })
                                            let toDate = variantInstanceDetails.values.length !== 0 && FormatDate({
                                                format: "yyyy-MM-dd", value: new Date(variantInstanceDetails.values[0].VALID_TO).toLocaleDateString()
                                            })
                                            setRuleListDetails({
                                                name: rule.ruleName ? rule.ruleName : null,
                                                validityFromDate: rule.ruleName ? fromDate : '1970-01-01',
                                                validityToDate: rule.ruleName ? toDate : '9999-12-31'
                                            })
                                            fnGetComments(variantInstanceDetails)
                                        });
                                    }.bind(this));
                                }
                                else {
                                    fnGetDecisionTableDetails(rule, selectedVarientId, oData.data).then(function (variantInstanceDetails) {
                                        resolve(variantInstanceDetails)
                                    });
                                }

                                return;
                            }
                        });



                        setVariantInstancesList(VariantList);
                    }


                })
                .catch((error) => {
                    props.setLoading(false)
                    setMessageStrip({
                        show: true,
                        type: 'error',
                        message: error.message
                    })

                });
        })
        return promise;

    }

    const fnGetOperatorsList = (VariantInstanceDetails) => {

        let sUrl =
            "/WorkRulesServices/v1/decision-tables/operators"
        let p = new Promise(function (resolve, reject) {
            Promise.all([
                fetchWrapper(sUrl, State.requestOptions).then((res) => res.json()),
            ])
                .then(([oData]) => {
                    if (oData.statusCode === 401 || oData.statusCode === '401') {
                        let message = "Session Timed Out.Kindly Refresh";
                        setMessageDialog({
                            "open": true,
                            "data": message,
                            onConfirm: () => { window.location.reload() },
                            type: "Error"

                        })

                    }
                    else {
                        resolve(oData.data);


                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        })
        return p;
    };
    const getFormattedValue = (value, dataType, isLookup, isExpression) => {
        let key, text;
        if (isLookup) {
            key = value.slice(1, value.indexOf("{"))
            text = value.slice(value.indexOf("{") + 1, value.indexOf("}"))
        }
        else if (dataType === "NVARCHAR") {
            key = value.slice(1, value.length - 1);
            text = value.slice(1, value.length - 1);
        }
        else if (dataType === "NVARCHAR" && !isExpression) {
            key = value.slice(1, value.length - 1);
            text = value.slice(1, value.length - 1);
        }
        else if (dataType === "DATE" && isExpression) {
            value = value.slice(1, value.length - 1);
            key = value.split("/")[2] + "-" + value.split("/")[0] + "-" + value.split("/")[1];
            text = key;
        }
        else if (dataType === "TIMESTAMP") {
            value = value.replace("'" + value.slice(1, value.length - 1).split(" ")[0] + "'", FormatDate({
                format: "MM/dd/yyyy",
                value: value.slice(1, value.length - 1).split(" ")[0]
            }))
            value = value.replaceAll("'", "")
            key = value
            text = value;
        }
        else {
            key = value
            text = value
        }
        return {
            key: key, text: text
        }
    }
    const fnGetDecisionTableDetails = (rule, SelectedVarientId, VariantList, operatorsList) => {
        props.setLoading(true);
        let promise = new Promise(function (resolve, reject) {
            var isExpression = VariantList[0].isExpression;
            var selectedRuleSetId = State.oSelectedDetails.oSelectedRS.id;
            var selectedApplicationId = State.oSelectedDetails.oAppDetails.applicationId
            var skipCount = 0;
            if (!rule) {
                if (!isExpression) {
                    var sUrl = "/WorkRulesServices/v1/commons/variant-instances/" + SelectedVarientId + "/details?applicationId=" +
                        selectedApplicationId + "&locale=en" + "&skip=" + skipCount + "&top=100" + "&source=false" + "&id=" + selectedRuleSetId +
                        "&idType=RuleSet";
                } else {
                    var sUrl = "/WorkRulesServices/v1/commons/variant-instances/" + SelectedVarientId + "/details?applicationId=" +
                        selectedApplicationId + "&locale=en" + "&source=false" + "&id=" + selectedRuleSetId +
                        "&idType=RuleSet";
                }
            }
            else {
                if (!isExpression) {
                    var sUrl = "/WorkRulesServices/v1/commons/variant-instances/" + SelectedVarientId + "/details?applicationId=" +
                        selectedApplicationId + "&locale=en" + "&skip=" + skipCount + "&top=100" + "&source=false" + "&id=" + selectedRuleSetId +
                        "&idType=RuleSet&RULE_NAME=" + rule.ruleName;
                } else {
                    var sUrl = "/WorkRulesServices/v1/commons/variant-instances/" + SelectedVarientId + "/details?applicationId=" +
                        selectedApplicationId + "&locale=en" + "&source=false" + "&id=" + selectedRuleSetId +
                        "&idType=RuleSet&RULE_NAME=" + rule.ruleName;
                }
            }
            Promise.all([
                fetchWrapper(sUrl, State.requestOptions).then((res) => res.json()),

            ])
                .then(([oData]) => {
                    props.setLoading(false);
                    if (oData.statusCode === 401 || oData.statusCode === '401') {
                        let message = "Session Timed Out.Kindly Refresh";
                        props.setLoading(false);
                        setMessageDialog({
                            "open": true,
                            "data": message,
                            onConfirm: () => { window.location.reload() },
                            type: 'Error'

                        })
                    }
                    var aVariantInstanceDetails = oData.data;
                    var aFlexibleFormMandatoryConditions = []; // mandatory conditions that need to be shown by default for flexible form
                    var aFlexibleConditions = []; // optional conditions that can be selected while creating a flexible form
                    var aFlexibleFormMandatoryActions = []; // mandatory actions that need to be shown by default for flexible form
                    var aFlexibleActions = []; // optional actions that can be selected while creating a flexible form
                    oData.data.fields.forEach(field => {
                        field.tokens = [];
                        oData.data.values.forEach(value => {
                            if (field.propertyDto.businessType === "C" && !oData.data.isExpression) {

                                if (field.dataType === "DATE") {
                                    value[field.columnName] = FormatDate({ format: "yyyy-MM-dd", value: value[field.columnName] })
                                    field[field.columnName + "_TEXT"] = value[field.columnName]
                                }

                                field.tokens.push({
                                    id: Math.random().toString(),
                                    key: value[field.columnName],
                                    text: field.propertyDto.isLookup && !oData.data.isExpression ? value[field.columnName + "_TEXT"] : value[field.columnName]
                                })
                            }
                            if (field.propertyDto.businessType === "C" && oData.data.isExpression) {
                                field.operatorsList = operatorsList[field.dataType];
                                let tokenOperator, data, key, text;
                                let aOperators = [], dataTokens = [], arrayTokens = [];
                                Object.values(operatorsList[field.dataType]).forEach(each => {
                                    aOperators = [...each];
                                    aOperators.forEach(operator => {
                                        if (value[field.columnName]?.startsWith(operator.key)) {
                                            tokenOperator = operator.key;
                                            data = value[field.columnName].replace(tokenOperator, "");
                                            if (operator.key === "EXISTSIN" || operator.key === "NOTEXISTSIN") {
                                                arrayTokens = data.slice(1, data.length - 1).split(",")
                                                arrayTokens = arrayTokens.map(arrayToken => {
                                                    let obj = getFormattedValue(arrayToken, field.dataType, field.propertyDto.isLookup, true);
                                                    return {
                                                        key: obj.key,
                                                        text: obj.text,
                                                        id: Math.random().toString()
                                                    }
                                                })
                                            }
                                            else if (operator.key === "IN" || operator.key === "NOTIN") {
                                                if (field.dataType === "DATE") {
                                                    let fromValue = data.slice(0, data.length).slice(2, data.slice(0, data.length).indexOf("..") - 1)
                                                    fromValue = FormatDate({
                                                        format: "yyyy-MM-dd",
                                                        value: fromValue
                                                    })

                                                    let toValue = data.slice(0, data.length).slice(data.slice(0, data.length).lastIndexOf(".") + 2, data.slice(0, data.length).length - 2)
                                                    toValue = FormatDate({
                                                        format: "yyyy-MM-dd",
                                                        value: toValue
                                                    })

                                                    key = data[0] + "'" + fromValue + "'..'" + toValue + "'" + data[data.length - 1]
                                                    text = data[0] + "'" + fromValue + "'..'" + toValue + "'" + data[data.length - 1]

                                                }
                                                else if (field.dataType === "TIMESTAMP") {
                                                    data = data.replace(data.slice(1, data.length - 1).split(" ")[0], FormatDate({
                                                        format: "MM/dd/yyyy",
                                                        value: data.slice(1, data.length - 1).split(" ")[0]
                                                    }) + " ")
                                                    key = data
                                                    text = data;
                                                }
                                                else {
                                                    key = data.slice(0, data.length);
                                                    text = data.slice(0, data.length);
                                                }

                                            }
                                            else {
                                                let obj = getFormattedValue(data, field.dataType, field.propertyDto.isLookup, true);
                                                key = obj.key;
                                                text = obj.text;

                                            }
                                        }
                                    })
                                })
                                field.tokens.push({
                                    id: Math.random().toString(),
                                    key: key,
                                    text: text,
                                    operator: tokenOperator,
                                    data: (tokenOperator === "EXISTSIN" || tokenOperator == "NOTEXISTSIN") ? arrayTokens : null
                                })

                            }
                            if (field.propertyDto.businessType === "A" && oData.data.isExpression) {
                                field[field.columnName] = value[field.columnName]
                                field[field.columnName + "_TEXT"] = value[field.columnName]

                                if (field.dataType === "DATE" || field.dataType === "TIMESTAMP" || (field.dataType === "NVARCHAR" && !field.propertyDto.isLookup)) {
                                    field[field.columnName] = value[field.columnName].slice(1, value[field.columnName].length - 1)
                                    field[field.columnName + "_TEXT"] = field[field.columnName]

                                }
                                else if (field.dataType === "NVARCHAR" && field.propertyDto.isLookup) {
                                    field[field.columnName] = value[field.columnName].slice("1", value[field.columnName].indexOf("{"))
                                    field[field.columnName + "_TEXT"] = value[field.columnName].slice(value[field.columnName].indexOf("{") + 1, value[field.columnName].indexOf("}"))

                                }
                            }
                            if (field.propertyDto.businessType === "A" && !oData.data.isExpression) {
                                field[field.columnName] = value[field.columnName]
                                field[field.columnName + "_TEXT"] = field.propertyDto.isLookup && !oData.data.isExpression ? value[field.columnName + "_TEXT"] : value[field.columnName]
                            }
                        })
                        if (!rule.decisionTableId && field.propertyDto.defaultValue) {
                            if (field.propertyDto.defaultValueText) {
                                field["tokens"] = [{
                                    id: Math.random().toString(),
                                    key: field.propertyDto.defaultValue,
                                    text: field.propertyDto.defaultValueText + "[" + field.propertyDto.defaultValue + "]"
                                }];
                            } else {
                                field["tokens"] = [{
                                    id: Math.random().toString(),
                                    key: field.propertyDto.defaultValue,
                                    text: field.propertyDto.defaultValue
                                }];
                            }
                        }
                        if (!rule.decisionTableId && oData.data.isExpression && field.propertyDto.businessType === "C") {
                            field.operatorsList = operatorsList[field.dataType];
                            field.tokens = [{
                                id: Math.random().toString(),
                                key: null,
                                text: null,
                                operator: field.propertyDto.operator ? field.propertyDto.operator : null
                            }]

                        }
                        if (field.propertyDto.businessType === "C" && field.propertyDto.isMandatory) {
                            aFlexibleFormMandatoryConditions.push(field)
                        }
                        if (field.propertyDto.businessType === "C" && !field.propertyDto.isMandatory) {
                            aFlexibleConditions.push(field)
                        }
                        if (field.propertyDto.businessType === "A" && field.propertyDto.isMandatory) {
                            aFlexibleFormMandatoryActions.push(field)
                        }
                        if (field.propertyDto.businessType === "A" && !field.propertyDto.isMandatory) {
                            aFlexibleActions.push(field)
                        }

                    })
                    aFlexibleFormMandatoryConditions.forEach(condition => {
                        const arrayUniqueByKey = [...new Map(condition.tokens.map(item => [item["key"], item])).values()];
                        condition.tokens = arrayUniqueByKey;
                    })

                    oData.data["aConditions"] = aFlexibleFormMandatoryConditions;
                    oData.data["aFlexibleFormConditions"] = aFlexibleConditions;
                    oData.data["aActions"] = aFlexibleFormMandatoryActions;
                    oData.data["aFlexibleFormActions"] = aFlexibleActions;
                    setVariantInstanceDetails(oData.data)
                    if (aVariantInstanceDetails.isExpression) {
                        setauthorisationExpressionMsg("Decision table with expression language");
                    } else {
                        setauthorisationExpressionMsg("Decision table without expression language")
                    }
                    resolve(oData.data)
                })
                .catch((error) => {
                    console.log(error);
                    props.setLoading(false);
                });
        })
        return promise;
    }

    const fnGetDTPermissionsForms = (decisionTableId, flag) => {
        var sUrl = "/WorkRulesServices/v1/decision-table/permissions?decisionTableId=" + decisionTableId
        let promise = new Promise(function (resolve, reject) {
            Promise.all([
                fetchWrapper(sUrl, State.requestOptions).then((res) => res.json()),

            ])
                .then(([oData]) => {
                    props.setLoading(false)
                    if (!oData.data) oData.data = []
                    let message = oData.message

                    if (oData.statusCode === 401 || oData.statusCode === '401') {
                        message = "Session Timed Out.Kindly Refresh"
                        setMessageDialog({
                            "open": true,
                            "data": message,
                            type: "Error",
                            onConfirm: () => { window.location.reload() }

                        })
                    }
                    dispatch({ type: 'UPDATE_DECISION_TABLE_LIST', value: oData.data })
                    if (oData.status && oData.statusCode === 200 && oData.data !== null) {

                        setdtAccessPermissionForms(oData.data.permission)
                    }

                    resolve(oData.data.permission);

                })
                .catch((error) => {
                    props.setLoading(false)
                    setMessageStrip({
                        show: true,
                        type: 'error',
                        message: error.message
                    })

                });
        })
        return promise;
    }
    const fnEditRuleList = (rule) => {
        fnCreateNewRule(rule)
    }
    const fnDeleteDTInRuleList = (rule, index) => {
        props.setLoading(true)
        const requestOptionsDELETE = {
            ...State.requestOptions,
            method: "DELETE"
        };
        var sUrl = "/WorkRulesServices/v1/rule-list/rule?dtId=" + rule.decisionTableId + "&ruleName=" + rule.ruleName
        Promise.all([
            fetchWrapper(sUrl, requestOptionsDELETE).then((res) => res.json()),

        ])
            .then(([oData]) => {
                props.setLoading(false)
                if (!oData.data) oData.data = []
                let message = oData.message

                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    message = "Session Timed Out.Kindly Refresh"
                    setMessageDialog({
                        "open": true,
                        "data": message,
                        type: "Error",
                        onConfirm: () => { window.location.reload() }

                    })
                }
                if (oData.status && oData.statusCode === 200 && oData.data !== null) {
                    let aDTS = [...State.aRuleListDTs];
                    aDTS.splice(index, 1)
                    dispatch({ type: 'UPDATE_RULE_LIST_DTS', value: aDTS })
                }



            })
            .catch((error) => {
                props.setLoading(false)
                setMessageStrip({
                    show: true,
                    type: 'error',
                    message: error.message
                })

            });


    }
    return (
        !showRuleListCreationView ?
            <div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
                    <CustomIcon style={{ marginRight: '0.25rem', height: '0.875rem', width: '0.875rem' }} icon={"SVGIcons.Vector"} />
                    <p style={{ margin: '0 0.5rem' }}>{State.oSelectedDetails.oSelectedRMS.label}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: "flex-end" }}><Button startIcon={<CustomIcon icon={"MaterialIcon.MdAdd"} />} className="styleButton styleStaticFormButtons" disableRipple onClick={onAddNewRule}>Add New Rule</Button>
                </div>
                <TableContainer className="styleRMStableContainerList" style={{ margin: '0.5rem', width: "98%" }} >
                    <Table stickyHeader aria-label="sticky table"  >
                        <TableHead>
                            <TableRow className='styleHeaderCellList'>
                                <TableCell>
                                    Rule List ( {State.aRuleListDTs ? State.aRuleListDTs.length : 0} )
                                </TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">Status</TableCell>

                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ height: '100px', overflow: 'scroll' }}>
                            {State.aRuleListDTs?.map((row, index) => (
                                <TableRow className="styleDataCellList" key={row.ruleId} style={row.showExpression ? { background: "#FAFAFA" } : { background: "#ffffff" }}>
                                    <TableCell component="th" scope="row" >
                                        {row.ruleName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" >
                                        {row.ruleText}
                                    </TableCell>
                                    <TableCell align="left" style={{ color: FormatStatusColor(row.status) }}>{row.status}</TableCell>

                                    <TableCell align="left"> <IconButton className="styleButton" onClick={() => fnEditRuleList(row)}>
                                        <CustomIcon fill="none" width="21" height="14" icon={"MaterialIcon.MdModeEditOutline"} />
                                    </IconButton></TableCell>
                                    <TableCell component="th" scope="row" >
                                        <IconButton className="styleButton">
                                            <CustomIcon fill="none" width="21" height="14" icon={"MaterialIcon.MdOutlineDelete"} onClick={() => fnDeleteDTInRuleList(row, index)} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer >
                {!State.aRuleListDTs && <div style={{ margin: "0.5rem", justifyContent: "center", display: 'flex' }}>No data</div>}

                <MessageDialog setMessageDialog={setMessageDialog} MessageDialogContent={MessageDialogContent} />
                <Popover
                    placement="bottom"
                    open={showCreationPopover}
                    anchorEl={creationPopoveranchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: "true",
                            boundariesElement: 'scrollParent'
                        },
                    }}
                >
                    {/* <ClickAwayListener onClickAway={() => { setShowCreationPopover(false); }}> */}
                    <div style={{
                        padding: '0.5rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        width: "13rem",
                        flexDirection: 'column'
                    }}>

                        <Select

                            variant="outlined"
                            className="styleReferenceFieldsSelect"
                            style={{ width: '100%' }}
                            value={selectedDecisionTable}
                            onChange={(event) => onSelectDT(event)}
                        >
                            {aDecisionTableRuleList.map(DT => {
                                return <MenuItem value={DT}>{DT.text}</MenuItem>
                            })}


                        </Select>
                        <Button disableRibble className="styleButton styleManageButton" style={{ margin: "0.5rem" }} disableRipple onClick={fnCreateNewRule}>Create New Rule</Button>
                    </div>
                    {/* </ClickAwayListener> */}
                </Popover >
            </div> : <RuleListCreation RuleListDetails={RuleListDetails}
                setRuleListDetails={setRuleListDetails}
                dtAccessPermissionForms={dtAccessPermissionForms}
                aSaveModelData={aSaveModelData}
                authorizationReadWriteaccess={authorizationReadWriteaccess}
                staticOrFlexibleVisible={staticOrFlexibleVisible}
                flexibleFormVisible={flexibleFormVisible}
                staticFormVisible={staticFormVisible}
                actionDropDownVisible={actionDropDownVisible}
                conditionDropDownVisible={conditionDropDownVisible}
                aActionListFlexibleForm={aActionListFlexibleForm}
                aConditionsListFlexibleForm={aConditionsListFlexibleForm}
                decisionTableFormType={decisionTableFormType}
                aConditionsListForFlexibleSelection={aConditionsListForFlexibleSelection}
                SubmitRuleListBtnEnable={SubmitRuleListBtnEnable}
                SelectedVarientIdFlexibleForm={SelectedVarientIdFlexibleForm}
                VariantInstancesList={VariantInstancesList}
                variantInstanceDetails={variantInstanceDetails}
                authorisationExpressionMsg={authorisationExpressionMsg}
                setVariantInstanceDetails={setVariantInstanceDetails}
                setLoading={props.setLoading}
                setShowRuleListCreationView={setShowRuleListCreationView}
                CommentsDetails={CommentsDetails}
                setCommentsDetails={setCommentsDetails}
            />
    )
}
export default RuleListTableView