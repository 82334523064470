
  export const applicationProperties = {
    general: {
      useWorkAccess: true,
      showTextRuleChain: true,
      showDTRuleChain:true,
      showSystemSettings:true,
      showAppHeader: true,
      showTextTranslation:true,
      showRuleList:true,
      showTextRules:true,
      showRuleSimulation:true,
      authorer:"workRules_Authorer",
      modeler:"workRules_Modeler",
      admin:"workRules_Admin"
    },
   
  };
  
  