import { Skeleton } from "@mui/lab";
import Backdrop from '@material-ui/core/Backdrop';
import { ThreeDots } from "react-loader-spinner";
import './cw_tr_DetailsLandingPage.css';
const BusyLoader = (props) => {
  return ((props.show) && <div style={{zIndex:'99999',display:'flex',justifyContent:'center',alignItems:'center'}}>
    <Backdrop  open={props.show}  className="styleBusyLoader" sx={{zIndex:'99999'}}>
      <ThreeDots height="80" width="80" radius="9" color="black" ariaLabel="three-dots-loading"  wrapperStyle={{zIndex:'99999'}} visible={props.show} />
    </Backdrop>
  </div>)

}
export default BusyLoader;