const initialState = {
    "counter": 0,
    "showDetails": {
        "showRuleChain": false,
        "showRuleEditor": false,
        "showViewRules": false,
    },
    "TextRuleDetails": [],
    "aTextRulesList": [],

    "aSaveModalDataTemp": [],
    "aBlocksBackUp": [],
    "aFilteredTextRules": [],
    "aConditionFields": [],
    "aActionFields": [],
    "aAttributes": [],
    "oSelectedDetails": {},
    "destinations": [],
    "oSelectedDT": [],
    "oSelectedRL": [],

    "headerDetails": {
        "fields": [],
        "values": {}
    },

    "requestOptions": {
        "headers": {
            "Content-Type": 'application/json',
            "Authorization": ""
        }
    },
    "userDetails": {},
    "userAccess": {
        activities: {
            WR_Modelling: true,
            WR_Authoring: true,
            TextRules: true,
            WR_Approval: true
        }
    },
    "FieldsLookUpData": {
        "headerText": "",
        "values": [],
        "metadata": []
    },
    "currentContextId": '',
    "currentBlock": {},
    "operatorsList": [],
    showBusyLoader: false,
    textruleEditMode: true,
    RMSlist: [],
    aVariantInstanceDetails: {},
    translationDataObjectsArray: [],
    textRuleModified: false,
    oSelectedVariant: {
        variantName: ""
    },
    translationsDataObject: {},
    oTranslationsJSON: {},
    aRuleList: [],
    aRuleListDTs: [],
    decisionTableList: [],
    operators: {},
    expOperators: [],
    featureFlag: {
        Modelling: true,
        AdminConsole: true,
        AC_Application: true,
        AC_ApplicationFieldCatalogue: true,
        AC_ActionType: true,
        AC_GFC: true,
        TextTranslation: false,
        AC_RuleMaintainanceSet: true,
        AC_RuleSet: true,
        AC_SystemSettings: true,
        AC_WorkText: true,
        CreateDecisionTable: true,
        Versioning: true,
        Authoring: true,
        DT_QuickAdd: false,
        DT_MassAdd: false,
        DT_StaticForm: false,
        DT_Export_Import: false,
        DT_FileUpload: false,
        DT_ActivityLog: false,
        DT_Delete: false,
        DT_Copy: false,
        DT_Paste: false,
        DT_ChangeLog: false,
        // DT_VariantManagement: false,
        DT_VariantCreation: false,
        DT_VariantView: false,
        DT_DefaultView: "TABLE_VIEW",
        DT_TableView: false,
        RuleList: false,
        TextRules: true,
        TR_FileUpload: false,
        TR_ActivityLog: false,
        TR_AuditLog: false,
        RuleSimulation: true,
        RuleChain: true,
    },
    destinationsList: [
        {
            "Description": "",
            "Name": "WorkAccessServices",
            "URL": process.env["REACT_APP_WorkAccessServices"]
        },
        {
            "Description": "",
            "Name": "WorkRulesServices",
            "URL": process.env["REACT_APP_WorkRulesServices"]
        },
        {
            "Description": "",
            "Name": "CW_Worktext",
            "URL": process.env["REACT_APP_WorkUtilsServices"]
        },
        {
            "Description": "",
            "Name": "WorkRuleEngineServices",
            "URL": process.env["REACT_APP_WorkRuleEngineSercvices"]
        },
        {
            "Description": "",
            "Name": "WorkUtilsServices",
            "URL": process.env["REACT_APP_WorkUtilsServices"]
        }

    ],
    worktextResponse: []

}
function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'INCREMENT':
            return { ...state, counter: action.value }
        case 'UPDATE_HEADER': return { ...state, headerDetails: action.value }
        case 'SETMODALDATATEMP':

            const temp = {
                ...state,
                aSaveModalDataTemp: action.value
            }
            return temp
        case 'UPDATE_RULES_LIST':
            return { ...state, aTextRulesList: action.value, aFilteredTextRules: action.aFilteredTextRules }
        case 'UPDATE_ATTRIBUTES_LIST':
            return { ...state, aAttributes: action.value }
        case 'UPDATE_CONDITION_FIELDS':
            return { ...state, aConditionFields: action.value }
        case 'UPDATE_DT':
            return { ...state, oSelectedDT: action.value }
        case 'UPDATE_RL':
            return { ...state, oSelectedRL: action.value }
        case 'UPDATE_ACTION_FIELDS':
            return { ...state, aActionFields: action.value }
        case 'UPDATE_SELECTED_DETAILS':
            return { ...state, oSelectedDetails: action.value }
        case 'UPDATE_DESTINATIONS':
            return { ...state, destinations: action.value }
        case 'SET_DETAILS_VIEW':
            return { ...state, showDetails: action.value }
        case 'UPDATE_TEXT_RULE_DETAILS':
            return { ...state, TextRuleDetails: action.value }
        case 'UPDATE_aBLOCKS_BACKUP':
            return { ...state, aBlocksBackUp: action.value }
        case 'UPDATE_MODAL_VISIBILITY':
            return { ...state, showModal: action.value }
        case 'UPDATE_USER_DETAILS':
            return { ...state, userDetails: action.value }
        case 'UPDATE_USER_ACCESS':
            return { ...state, userAccess: action.value }
        case 'UPDATE_REQUEST_HEADERS':
            return { ...state, requestOptions: action.value }
        case 'UPDATE_FIELDS_LOOKUP':
            return { ...state, FieldsLookUpData: action.value }
        case 'UPDATE_CONTEXT_ID':
            return { ...state, currentContextId: action.value }
        case 'UPDATE_CURRENT_BLOCK':
            return { ...state, currentBlock: action.value }
        case 'UPDATE_OPERATORS':
            return { ...state, operators: action.value }
        case 'UPDATE_BUSY_LOADER':
            return { ...state, showBusyLoader: action.value }
        case 'UPDATE_TEXTRULE_EDIT_MODE':
            return { ...state, textruleEditMode: action.value }
        case 'UPDATE_RMS_LIST':
            return { ...state, RMSlist: action.value }
        case 'UDPATE_VARIANT_INSTANCE_DETAILS':
            return { ...state, aVariantInstanceDetails: action.value }
        case 'UPDATE_TEXTRULE_MODIFIED':
            return { ...state, textRuleModified: action.value }
        case 'UPDATE_SELECTED_VARIANT':
            return { ...state, oSelectedVariant: action.value }
        case 'UPDATE_RULE_LIST':
            return { ...state, aRuleList: action.value }
        case 'UPDATE_RULE_LIST_DTS':
            return { ...state, aRuleListDTs: action.value }
        case 'UPDATE_DECISION_TABLE_LIST':
            return { ...state, decisionTableList: action.value }
        case 'UPDATE_OPERATORS_LIST':
            return { ...state, operatorsList: action.value }
        case 'UPDATE_EXP_OPERATORS_LIST':
            return { ...state, expOperators: action.value }
        case 'UPDATE_FEATURE_FLAG':
            return { ...state, featureFlag: action.value }
        case 'UPDATE_DESTINATION_LIST':
            return { ...state, destinationsList: action.value }
        case "UPDATE_TRANSLATIONS_DATA":
            return { ...state, translationsDataObject: action.value }
        case "UPDATE_WT_RESPONSE":
            return { ...state, worktextResponse: action.value }
        case "UPDATE_TRANSLATIONS_OBJECT_JSON":
            return { ...state, oTranslationsJSON: action.value}
        default:
            return state;
    }
}
export default rootReducer;