import { Simulation } from '@cw/rule-generator';
import { useSelector } from 'react-redux';
import features from "../utility/features.json";

const SimulationLandingPage = (props) => {
    const State = useSelector(state => state.rootReducer);
    let userDetails = {
        emailId: State.userDetails.emailId,
        user_id: State.userDetails.user_id
    }
    if(State.requestOptions.headers?.Authorization){
        let token = State.requestOptions.headers.Authorization.replaceAll("Bearer", "").trim();
        return (State.requestOptions.headers?.Authorization?.length > 0 && <Simulation translationDataObjects={State.worktextResponse} destinations={State.destinationsList} token={token} userDetails={userDetails} />)
    }
    else{
        return <Simulation translationDataObjects={State.worktextResponse} destinations={State.destinationsList} userDetails={userDetails} />
    }
}
export default SimulationLandingPage;