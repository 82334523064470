import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip, Button, ButtonGroup, IconButton, Checkbox, Table, TableRow, TableHead, TableContainer, TableBody, TableCell, Divider, TextField, InputAdornment, Popover, TableFooter, ClickAwayListener } from "@mui/material";
import './cw_tr_ViewRules.css';
import FormatDate from '../../functions/cw_wr_DateFormatter';
import getOperatorGroup from '../util/cw_tr_getOperatorsGroup';
import ExpBuilder from '../util/cw_tr_ExpressionBuilder';
import MessageDialog from '../../components/cw_wr_MessageDialog';
import MessageStrip from '../../components/cw_wr_MessageStrip';
import { useSelector, useDispatch } from 'react-redux';
import FormatStatusColor from '../util/cw_tr_formatStatusColor';
import CustomIcon from "../../icons/CustomIcons";
import fetchWrapper from '../../utility/fetchWrapper';
import showToastMessage from '../../utility/MessageToast';
import { borderRadius } from '@mui/system';
const useStylesTable = makeStyles({
    root: {
        width: '98%',
        border: 'none',
        margin: '0rem 0.5rem 0rem 0.5rem'

    },
    container: {
        maxHeight: 'calc(100vh - 14rem)',
        width: '99%',
        display: 'block'
        // margin: '0.5rem'
    },
    columnHeaders: {
        borderBottom: 'none',
        background: '#DFEAFB'
    },
    tableRow: {
        height: 30
    },
    tableCell: {
        padding: "0px 16px"
    }
});
let footerWidth = "75%";

const ViewRules = (props) => {

    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer)
    const [showList, setShowList] = useState(true)
    //const [showExpression, setShowExpression] = useState(false)
    // const [TextRuleDetails, setTextRuleDetails] = useState([])

    const [showPopover, setShowPopover] = useState(false)
    const [anchorElement, handleAnchorElement] = useState(null);
    const [Rulestext, setRulestext] = useState("All Rules");
    const [showIconsPopover, setShowIconsPopover] = useState(false)
    const [anchorElementIcons, handleAnchorElementIcons] = useState(null);
    const [PopoverData, setPopoverData] = useState({})
    let location = useLocation()
    const classesTable = useStylesTable();
    let aTextRulesList = State.aTextRulesList;
    let aFilteredTextRules = State.aFilteredTextRules
    const aConditionFields = State.aConditionFields;
    const aActionFields = State.aActionFields;
    const requestOptions = State.requestOptions;
    const [MessageDialogContent, setMessageDialog] = useState({
        "open": false,
        "data": "",
        "onConfirm": {}
    });
    const [MessageStripData, setMessageStrip] = useState({
        "show": false,
        "message": "",
        "type": ""

    })
    // useEffect(()=>{
    //     if (State.oSelectedDetails.oSelectedRS.textrules) {
    //         State.oSelectedDetails.oSelectedRS.textrules.forEach(each => each["showicon"] = false)
    //     }
    //     dispatch({
    //         type: "UPDATE_RULES_LIST", "value": State.oSelectedDetails.oSelectedRS.textrules, "aFilteredTextRules": State.oSelectedDetails.oSelectedRS.textrules
    //     })
    // },[])
    useEffect(() => {
        // props.fnGetAllTextRules(State.oSelectedDetails);
        dispatch({
            type: "UPDATE_RULES_LIST", "value": State.aTextRulesList, "aFilteredTextRules": State.aTextRulesList
        })
    }, []);
    useEffect(() => {
        if (props.showExpression) {
            footerWidth = '47%'
        }
        else {
            footerWidth = '76%'
        }
    }, [props.showExpression]);

    const onRowSelection = (row, index) => {
        // props.setloading(true);
        props.setShowExpLoader(true)
        let aTextRulesList = [...State.aTextRulesList];
        let aFilteredTextRules = [...State.aFilteredTextRules];
        aFilteredTextRules.forEach(each => {
            each["showExpression"] = false;

        })
        aFilteredTextRules[index]["showExpression"] = true
        dispatch({
            type: "UPDATE_RULES_LIST", "value": aTextRulesList, "aFilteredTextRules": aFilteredTextRules
        })
        props.fnGetTextRuleDetails(row.id, true)
        props.setShowExpression(true);
    }

    const fnSubmitRules = () => {
        let aFilteredTextRules = [...State.aFilteredTextRules];
        let aRuleIDs = [];
        let aTextRulesList = [...State.aTextRulesList]
        let bValidRulesForSubmission = true;
        aFilteredTextRules.forEach(each => {
            if (each.isSelected && each["status"] === "Active") {
                bValidRulesForSubmission = false;
                setMessageStrip({
                    "show": true,
                    "message": "Active Rules cannot be submitted",
                    "type": "error"
                })
                // showToastMessage("Active Rules cannot be submitted","error")
            }
            else if (each.isSelected && each["status"] !== "Active") {
                each["status"] = "Awaiting Approval"
                each["isSelected"] = false
                aRuleIDs.push(each.id)
            }
        })
        if (bValidRulesForSubmission) {
            aRuleIDs.forEach(id => {
                aTextRulesList.forEach(rule => {
                    if (rule.id === id) {
                        rule["status"] = "Awaiting Approval"
                        // rule["isSelected"] = "Awaiting Approval"
                    }
                })
            })
            let payload = {
                "ruleIds": aRuleIDs,
                // "updatedBy": that.oTextRulesModel.getProperty("/oUserData").firstName + " " + that.oTextRulesModel.getProperty("/oUserData").lastName,
                // "updatedBy": State.userDetails.emailId,
                // "updatedOn": new Date().getTime()
            }
            let sUrl = "/WorkRulesServices/v1/textrule/submit";
            let requestOptions = { ...State.requestOptions }
            requestOptions["method"] = "POST";
            requestOptions["body"] = JSON.stringify(payload)
            Promise.all([
                fetchWrapper(sUrl, requestOptions).then((res) => res.json())

            ])
                .then(([oData]) => {
                    let message = oData.message
                    if (oData.statusCode === 401 || oData.statusCode === '401') {
                        message = "Session Timed Out.Kindly Refresh";
                        setMessageDialog({
                            "open": true,
                            "data": message,
                            type: "Error",
                            onConfirm: () => { window.location.reload() }

                        })
                    }

                    dispatch({
                        type: "UPDATE_RULES_LIST", "value": aTextRulesList, "aFilteredTextRules": aFilteredTextRules
                    })
                    setMessageStrip({
                        "show": true,
                        "message": "Rules submitted successfully",
                        "type": "success"
                    })


                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }
    const fnDeleteRule = (event, ruleId) => {

        // event.preventDefault();
        let aTextRulesList = [...State.aTextRulesList];
        let aFilteredTextRules = [...State.aFilteredTextRules];
        props.setShowExpression(false)
        setMessageDialog({
            "data": 'Do you want to delete the rule',
            'open': true,
            type: "Confirmation",
            'onConfirm': () => {
                // let ruleId = aFilteredTextRules[index]["ruleId"];
                let sUrl = "/WorkRulesServices/v1/textrule/" + ruleId;
                let requestOptions = { ...State.requestOptions }
                requestOptions["method"] = "DELETE";
                Promise.all([
                    fetchWrapper(sUrl, requestOptions).then((res) => res.json())

                ])
                    .then(([oData]) => {
                        let message = oData.message
                        if (oData.statusCode === 401 || oData.statusCode === '401') {
                            message = "Session Timed Out.Kindly Refresh";
                            setMessageDialog({
                                "open": true,
                                type: "Error",
                                "data": message,
                                onConfirm: () => { window.location.reload() }

                            })
                        }
                        let msg = "Rule deleted successfully"
                        let type = "success"
                        if (oData.status) {
                            aFilteredTextRules.forEach((each, index) => {
                                if (each.id === ruleId) {
                                    aFilteredTextRules.splice(index, 1)
                                }
                            })
                            aTextRulesList.forEach((each, index) => {
                                if (each.id === ruleId) {
                                    aTextRulesList.splice(index, 1)
                                }
                            })
                            dispatch({
                                type: "UPDATE_RULES_LIST", "value": aTextRulesList, "aFilteredTextRules": aFilteredTextRules
                            })
                        }
                        else {
                            msg = oData.message;
                            type = "error"
                        }
                        setMessageStrip({
                            "show": true,
                            "message": msg,
                            "type": type
                        })


                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }
        })
    }
    const handleSelection = (event, index) => {
        event.stopPropagation();
        let aTextRulesList = [...State.aTextRulesList];
        let aFilteredTextRules = [...State.aFilteredTextRules];
        aFilteredTextRules[index]["isSelected"] = event.target.checked
        dispatch({
            type: "UPDATE_RULES_LIST", "value": aTextRulesList, "aFilteredTextRules": aFilteredTextRules
        })
    }
    const handleSelectionAll = (event) => {
        let aFilteredTextRules = [...State.aFilteredTextRules];
        aFilteredTextRules.forEach(each => {
            each["isSelected"] = event.target.checked
        })
        dispatch({
            type: "UPDATE_RULES_LIST", "value": [...State.aTextRulesList], "aFilteredTextRules": aFilteredTextRules
        })
    }
    const onChangeSearchQuery = (event) => {

        let aSearchResult = []
        aTextRulesList.forEach(each => {
            if ( each?.name?.toLowerCase()?.includes( event?.target?.value?.toLowerCase() ) || each?.description?.toLowerCase()?.includes( event?.target?.value?.toLowerCase() ) ) {
                aSearchResult.push(each)
            }
        })
        dispatch({
            type: "UPDATE_RULES_LIST", "value": [...State.aTextRulesList], "aFilteredTextRules": aSearchResult
        })
    }
    const fnFilterRules = (status) => {
        let aTextRulesList = [...State.aTextRulesList];
        let afiltedRules = [];
        if (status !== "All") {
            aTextRulesList.forEach(each => {
                if (each.status === status) {
                    afiltedRules.push(each)
                }
            })
            setRulestext(status + " Rules")

        }
        else {
            setRulestext("All Rules")
            afiltedRules = aTextRulesList
        }

        dispatch({
            type: "UPDATE_RULES_LIST", "value": aTextRulesList, "aFilteredTextRules": afiltedRules
        })
        setShowPopover(false);
    }
    const fnOpenpopover = (event) => {
        handleAnchorElement(event.currentTarget)
        setShowPopover(true);
    }
    const fnEditTextRule = (event, rule) => {
        event.stopPropagation();
        props.setShowExpression(false)
        // fnGetExpressionOperators();
        let aFilteredTextRules = [...State.aFilteredTextRules];
        aFilteredTextRules.forEach(each => {
            each["showExpression"] = false
        })
        dispatch({
            type: "UPDATE_RULES_LIST", "value": [...State.aTextRulesList], "aFilteredTextRules": aFilteredTextRules
        })

        dispatch({
            "type": "UPDATE_TEXT_RULE_DETAILS", value: {
                "ruleId": null,
                "ruleName": "",
                "status": "Draft",
                "textRuleBlocks": [
                    {
                        "blockId": Math.random().toString(),
                        "Name": "Block 1",
                        "aConditionBlock": [
                            {
                                "path": "/0/childrens/0",
                                "conditionValueType": 'Value',
                                "conditionId": Math.random().toString(),
                                "conditionType": "single",
                                "conditionKey": {
                                    "name": "",
                                    "label": "",
                                    "dataType": "NVARCHAR"
                                },
                                "condOperation": "",
                                "operatorGroupType": "",
                                "conditionValue": "",
                                "root": true,
                                "validation": {
                                    "report": {
                                        "leftOperandStatus": "None",
                                        "leftOperandStatusText": "",
                                        "rightOperandStatus": "None",
                                        "rightOperandStatusText": "",
                                        "operatorStatus": "None",
                                        "operatorStatusText": ""
                                    }
                                }

                            }],
                        "aActionBlock": [
                            {
                                "actionKey": {
                                    "label": "",
                                    "dataType": "NVARCHAR"
                                },
                                "actionType": "Value",
                                "actionValue": ""
                            }
                        ]
                    }
                ]
            }
        })
        dispatch({ type: "UPDATE_TEXTRULE_MODIFIED", value: false })
        // props.fnGetTextRuleDetails(rule.ruleId);
        let oSelectedDetails = { ...State.oSelectedDetails }
        oSelectedDetails['rule'] = rule
        dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })

        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": false,
                "showRuleEditor": true,
                "showViewRules": false,
            }
        })
        // fnGetOperatorsList();
        props.setShowHeader(false)
    }
    const fnCreateTextRule = () => {
        props.setShowHeader(false)
        fnGetExpressionOperators()
        let aConditionFields = State.aAttributes.conditionAttributes
        let aActionFields = State.aAttributes.actionAttributes
        dispatch({ type: "UPDATE_TEXTRULE_MODIFIED", value: true })
        dispatch({ type: "UPDATE_CONDITION_FIELDS", value: aConditionFields })
        dispatch({ type: "UPDATE_ACTION_FIELDS", value: aActionFields })
        let blockId = Math.random().toString()
        dispatch({
            "type": "UPDATE_TEXT_RULE_DETAILS", value: {
                "ruleId": null,
                "id": null,
                "ruleName": "",
                "status": "Draft",
                "textRuleBlocks": [
                    {
                        "blockId": blockId,
                        "Name": "Block 1",
                        "aConditionBlock": [
                            {
                                "path": "/0/childrens/0",
                                "conditionValueType": 'Value',
                                "conditionId": Math.random().toString(),
                                "conditionType": "single",
                                "conditionKey": {
                                    "name": "",
                                    "label": "",
                                    "dataType": "NVARCHAR"
                                },
                                "condOperation": "",
                                "operatorGroupType": "",
                                "conditionValue": "",
                                "root": true,
                                "validation": {
                                    "report": {
                                        "leftOperandStatus": "None",
                                        "leftOperandStatusText": "",
                                        "rightOperandStatus": "None",
                                        "rightOperandStatusText": "",
                                        "operatorStatus": "None",
                                        "operatorStatusText": ""
                                    }
                                }

                            }],
                        "aActionBlock": [
                            {
                                "actionKey": {
                                    "label": "",
                                    "dataType": "NVARCHAR"
                                },
                                "actionType": "Value",
                                "actionValue": ""
                            }
                        ]
                    }
                ]
            }
        })
        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": false,
                "showRuleEditor": true,
                "showViewRules": false,
            }
        })
        let BlockIdNames = { ...props.oBlockIdsNamesMapping }
        BlockIdNames[blockId] = "Block 1";
        props.setBlockIdsNamesMapping(BlockIdNames)
        let oSelectedDetails = { ...State.oSelectedDetails }
        oSelectedDetails['rule'] = {
            ruleId: null,
            id:null
        }
        dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })
        // fnGetOperatorsList();
    }
    const fnGetOperatorsList = () => {

        let sUrl = "/WorkRulesServices/v1/decision-table/operators"
        Promise.all([
            fetchWrapper(sUrl, State.requestOptions).then((res) => res.json())

        ])
            .then(([oData]) => {
                if (!oData.data) oData.data = [];
                let message = oData.message
                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    message = "Session Timed Out.Kindly Refresh";
                    setMessageDialog({
                        "open": true,
                        type: "Error",
                        "data": message,
                        onConfirm: () => { window.location.reload() }

                    })
                }

                dispatch({
                    type: "UPDATE_OPERATORS_LIST", "value": oData.data
                })



            })
            .catch((error) => {
                console.log(error);
            });
    }
    const fnShowIconsPopover = (event, rule, show) => {

        // if (!show && props.showExpression) {
        //     show = true;
        // }
        setShowIconsPopover(show);
        handleAnchorElementIcons(show ? event.currentTarget : null)
        let aTextRulesList = [...State.aTextRulesList];
        let aFilteredTextRules = [...State.aFilteredTextRules];
        setPopoverData(rule)
        aFilteredTextRules.forEach(each => {
            if (each.id === rule.id) {

                each["showEllipisis"] = show
            }
            else {
                each["showEllipisis"] = !show
            }
        })
        dispatch({
            type: "UPDATE_RULES_LIST", "value": State.aTextRulesList, "aFilteredTextRules": aFilteredTextRules
        })
    }
    const fnShowEllipis = (show, rule) => {

        let aTextRulesList = [...State.aTextRulesList];
        let aFilteredTextRules = [...State.aFilteredTextRules];

        if (!show && showIconsPopover) {
            show = true
        }

        aFilteredTextRules.forEach(each => {
            if (each.id === rule.id) {
                if (!show && each["showExpression"]) {
                    show = true;
                }
                each["showEllipisis"] = show
            }
        })
        dispatch({
            type: "UPDATE_RULES_LIST", "value": State.aTextRulesList, "aFilteredTextRules": aFilteredTextRules
        })

    }
    const fnGetExpressionOperators = () => {

        let sUrl = "/WorkRulesServices/v1/decision-table/operators?operatorType=EXP"
        Promise.all([
            fetchWrapper(sUrl, State.requestOptions).then((res) => res.json())

        ])
            .then(([oData]) => {
                if (!oData.data) oData.data = [];
                let message = oData.message
                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    message = "Session Timed Out.Kindly Refresh";
                    setMessageDialog({
                        "open": true,
                        type: "Error",
                        "data": message,
                        onConfirm: () => { window.location.reload() }

                    })
                }

                dispatch({
                    type: "UPDATE_EXP_OPERATORS_LIST", "value": oData.data
                })



            })
            .catch((error) => {
                console.log(error);
            });
    }
    return <div style={{ marginTop: '0.5rem' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', margin: '0 0.5rem 0.5rem 0', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', border: !showList && '1.5px solid #007AD4', height: '1.8rem', background: !showList && "#FFFFFF", borderRadius: '3px' }}>
                {showList && <p style={{
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    letterSpacing: '0.001em',
                    color: '#757575'
                }}></p>}
                <Button variant="text" style={{ width: '3.625rem', height: '1.875rem', borderRadius: '2.5rem', color: '#002D5D', marginRight: '0.5rem', textTransform: "none", fontSize: '0.75rem' }}>All ({State.aFilteredTextRules.length})</Button>
                <Button variant="text" style={{ width: '4.813rem', height: '1.875rem', borderRadius: '2.5rem', color: '#002D5D', marginRight: '0.5rem', textTransform: "none", fontSize: '0.75rem' }}>{State?.translationsDataObject["DRAFT_WR"]?.shortDescription ?? State?.oTranslationsJSON["DRAFT_WR"]?.shortDescription ?? "Draft"} ({State.aFilteredTextRules.filter(rule => rule.status === "Draft").length}) </Button>
                <Button variant="text" style={{ width: '4.875rem', height: '1.875rem', borderRadius: '2.5rem', color: '#002D5D', textTransform: "none", fontSize: '0.75rem' }}>{State?.translationsDataObject["ACTIVE_WR"]?.shortDescription ?? State?.oTranslationsJSON["ACTIVE_WR"]?.shortDescription ?? "Active"}({State.aFilteredTextRules.filter(rule => rule.status === "Active").length})</Button>
                {!showList && <div style={{ display: 'flex', marginLeft: '0.5rem' }}><p style={{ margin: 0 }}>{Rulestext}</p>
                    <IconButton className="styleButton styleSelection" onClick={(event) => fnOpenpopover(event)} size="small">
                        <CustomIcon fill="none" width="21" height="14" icon={"BoxIcon.BiChevronDown"} />
                        {/* <KeyboardArrowDownIcon fontSize='small' /> */}
                    </IconButton>
                </div>}


                <Popover
                    placement="bottom"
                    open={showPopover}
                    anchorEl={anchorElement}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: "true",
                            boundariesElement: 'scrollParent'
                        },
                    }}
                >
                    <ClickAwayListener onClickAway={() => setShowPopover(false)}>
                        <div>
                            <p onClick={() => fnFilterRules("All")} style={{ margin: '0.5rem 0.5rem 0.5rem 0.5rem' }}>All Rules</p>
                            <p onClick={() => fnFilterRules("Draft")} style={{ margin: '0.5rem 0.5rem 0.5rem 0.5rem' }}>Draft Rules</p>
                            <p onClick={() => fnFilterRules("Active")} style={{ margin: '0.5rem 0.5rem 0.5rem 0.5rem' }}>Active Rules</p>
                        </div>
                    </ClickAwayListener>
                </Popover>


                {/* <div style={{ background: '#ffffff', width: '2rem', height: '2rem', padding: '0.4rem 0 0 0.6rem', borderRadius: '1rem', color: '#007AD4', fontWeight: '600' }}>{aFilteredTextRules.length}</div> */}
            </div>
            <div style={{
                // width: '44%',
                display: 'flex',
                marginBottom: '16px',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}><TextField autoComplete='off'
                variant='outlined'
                style={{ marginbotton: '0.5rem', textAlign: 'center', width: '12rem', background: '#ffffff' }}
                placeholder="Search"
                className="styleSearch"
                onChange={(event) => onChangeSearchQuery(event)}

                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                            >
                                <CustomIcon fill="none" width="21" height="14" icon={"MaterialIcon.MdOutlineSearch"} />
                                {/* <SearchIcon /> */}
                            </IconButton>

                        </InputAdornment>
                    )
                }} />
                <Button data-testid="create-button" disableRipple className="styleButton styleManageButton" onClick={fnCreateTextRule} style={{ margin: '0 0.5rem 0 0.5rem', padding: '4px 8px', fontSize: '14px' }}>{State?.translationsDataObject["CREATE RULE_WR"]?.shortDescription ?? State?.oTranslationsJSON["CREATE RULE_WR"]?.shortDescription ?? "Create Rule"}</Button>
                <ButtonGroup disableRipple className='styleButtonGroup' disableElevation variant="contained" color="primary" >
                    <Tooltip title="List View">
                        <IconButton className={showList ? 'styleActiveButton' : 'styleDefaultButton'} onClick={() => { setShowList(true); props.setShowExpression(false) }}><CustomIcon style={{ width: '1rem', height: '1rem' }} icon={showList ? "SVGIcons.TableViewActive" : "SVGIcons.TableView"} /></IconButton></Tooltip>
                    <Tooltip title="Grid View">
                        <IconButton className={!showList ? 'styleActiveButton' : 'styleDefaultButton'} onClick={() => { setShowList(false); props.setShowExpression(false) }}><CustomIcon style={{ width: '1rem', height: '1rem' }} icon={"MaterialIcon.MdOutlineGridOn"} /></IconButton></Tooltip>
                </ButtonGroup>
            </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}> {showList ? <div style={{ width: '100%' }}>

            <TableContainer className={classesTable.container} >
                <Table className='styletable' sx={{ paddingRight: '0.875rem' }} stickyHeader aria-label="sticky table"  >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    onChange={(event) => handleSelectionAll(event)}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </TableCell>

                            <TableCell align="left" sx={{ fontWeight: 600 }}>{State?.translationsDataObject["RULE NAME_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE NAME_WRR"]?.shortDescription ?? "Rule Name"}</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 600 }}>Rule Description</TableCell>
                            {State?.featureFlag?.TR_Versioning && <TableCell align="left" sx={{ fontWeight: 600 }}>{State?.translationsDataObject["VERSION_WR"]?.shortDescription ?? State?.oTranslationsJSON["VERSION_WR"]?.shortDescription ?? "Version"}</TableCell>}
                            <TableCell align="left" sx={{ fontWeight: 600 }}>{State?.translationsDataObject["MODIFIED BY_WR"]?.shortDescription ?? State?.oTranslationsJSON["MODIFIED BY_WR"]?.shortDescription ?? "Modified By"}</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 600 }}>{State?.translationsDataObject["MODIFIED ON_WR"]?.shortDescription ?? State?.oTranslationsJSON["MODIFIED ON_WR"]?.shortDescription ?? "Modified On"}</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 600 }}>
                            {State?.translationsDataObject["STATUS_WR"]?.shortDescription ?? State?.oTranslationsJSON["STATUS_WR"]?.shortDescription ?? "Status"}
                                <IconButton className="styleButton" onClick={(event) => fnOpenpopover(event)}>
                                    {/* <KeyboardArrowDownIcon /> */}
                                    <CustomIcon fill="none" width="21" height="14" icon={"BoxIcon.BiChevronDown"} />
                                </IconButton></TableCell>
                            <TableCell align="left" style={{ marginLeft: '1rem' }} sx={{ fontWeight: 600 }}>{State?.translationsDataObject["ACTIONS_WR"]?.shortDescription ?? State?.oTranslationsJSON["ACTIONS_WR"]?.shortDescription ?? "Actions"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ height: '100px', overflow: 'scroll' }}>
                        {aFilteredTextRules.map((row, index) => (

                            <TableRow className={classesTable.tableRow} key={row.id} style={row.showExpression ? { background: "#FAFAFA" } : { background: "#ffffff" }}>
                                <TableCell align="left"><Checkbox
                                    defaultChecked={row.isSelected}
                                    color="primary"
                                    checked={row.isSelected}
                                    onChange={(event) => { handleSelection(event, index) }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                </TableCell>
                                <TableCell component="th" scope="row" className={classesTable.tableCell}>
                                    {row.label ? row.label : row.name}
                                </TableCell>
                                <TableCell align="left">{row.description}</TableCell>
                                {State?.featureFlag?.TR_Versioning && <TableCell align="left">{row.version}</TableCell>}
                                <TableCell align="left">{row.updatedBy}</TableCell>
                                <TableCell align="left">{FormatDate({ "value": row.updatedOn, "format": "dd/MM/yyyy" })}</TableCell>
                                <TableCell align="left" style={{ color: FormatStatusColor(row.status), fontWeight: 'bold' }}>
                                    {row.status}
                                </TableCell>
                                <TableCell align="left">
                                    <IconButton color="primary" className='styleDefaultEyeIcon' onClick={(event) => fnEditTextRule(event, row)}>
                                        {/* <EditIcon fontSize='small' /> */}
                                        <CustomIcon fill="none" width="15.34" height="15.34" icon={"MaterialIcon.MdModeEditOutline"} />
                                    </IconButton>
                                    <IconButton color="primary" className={row.showExpression ? 'styleEyeIcon' : 'styleDefaultEyeIcon'} onClick={() => onRowSelection(row, index)}>
                                        {/* <VisibilityIcon fontSize='small' /> */}
                                        <CustomIcon fill="none" width="21" height="14" icon={"AntIcon.AiFillEye"} />
                                    </IconButton>
                                    <IconButton data-testid="delete-rule" disabled={row.status === "Active" ? true : false} className='styleRedButton' onClick={(event) => { event.stopPropagation(); fnDeleteRule(event, row.id); props.setShowExpression(false) }}>
                                        {/* <DeleteOutlineIcon fontSize='small' /> */}
                                        <CustomIcon style={{ color: "#B71C1C", opacity: row.status === "Active" ? 0.2 : 1, width: "1.81rem", height: "1.047rem" }} fill="none" width="21" height="14" icon={"MaterialIcon.MdOutlineDelete"} />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                    {/* <TableFooter>
                        <div style={{ boxShadow: '0rem 0.438rem 0.5rem -0.25rem rgba(0, 0, 0, 0.2), 0rem 0.75rem 1.063rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 0.313rem 1.375rem 0.25rem rgba(0, 0, 0, 0.12)', display: 'flex', justifyContent: 'flex-end', position: "fixed", bottom: '2px', background: '#ffffff', width: '76%', borderRadius: '0 0 6px 6px' }}>
                            <Button style={{ margin: '0.5rem' }} className="styleButton styleManageButton" onClick={() => fnSubmitRules()}>Submit</Button>
                        </div>
                    </TableFooter> */}
                </Table>
            </TableContainer>

        </div> :
            <div style={{ display: 'flex', flexWrap: 'wrap', background: '#ffffff', justifyContent: 'flex-start', alignContent: 'flex-start', margin: '0.5rem', borderRadius: '10px', width: '100%', height: 'calc(100vh - 12rem)', overflowY: 'scroll' }}>

                {aFilteredTextRules.map((each, index) => {
                    return <div style={index % 2 ? { background: "#FFFFFF" } : { background: "#F5F5F5" }} className="styleCard" onMouseOver={() => fnShowEllipis(true, each)} onMouseLeave={() => fnShowEllipis(false, each)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>  <p style={{ fontSize: '14px', fontWeight: '600', display: 'flex', flexWrap: 'wrap' }}>{each.ruleName}</p>
                            {each["showEllipisis"] && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                <IconButton color="primary" className='styleDefaultEyeIcon styleButton' onClick={(event) => fnShowIconsPopover(event, each, true)}>
                                    {/* <MoreHorizIcon fontSize='small' /> */}
                                    <CustomIcon style={{ width: '1rem', height: '1rem' }} icon={"FontAwesome.FaEllipsisH"} />
                                </IconButton>

                            </div>}
                        </div>
                        <Popover
                            placement="bottom"
                            open={showIconsPopover}
                            anchorEl={anchorElementIcons}
                            className="stylePopover"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            modifiers={{
                                flip: {
                                    enabled: true,
                                },
                                preventOverflow: {
                                    enabled: "true",
                                    boundariesElement: 'scrollParent'
                                },
                            }}
                        >
                            <ClickAwayListener

                                onClickAway={(event) => fnShowIconsPopover(event, each, false)}
                            >
                                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.4rem' }}>
                                    <IconButton color="primary" className='styleDefaultEyeIcon styleButton' onClick={(event) => fnEditTextRule(event, PopoverData)} >
                                        {/* <EditIcon fontSize='small' /> */}
                                        <CustomIcon style={{ width: '1rem', height: '1rem' }} icon={"MaterialIcon.MdModeEditOutline"} />
                                    </IconButton>
                                    <IconButton color="primary" className='styleDefaultEyeIcon styleButton' onClick={() => onRowSelection(PopoverData, index)} >
                                        {/* <VisibilityIcon fontSize='small' /> */}
                                        <CustomIcon style={{ width: '1rem', height: '1rem' }} icon={"AntIcon.AiFillEye"} />
                                    </IconButton>
                                    <IconButton disabled={PopoverData.status === "Active" ? true : false} className="styleButton styleRedButton" onClick={(event) => { fnDeleteRule(event, PopoverData.id); }}>
                                        {/* <DeleteOutlineIcon fontSize='small' /> */}
                                        <CustomIcon style={{ width: '1rem', height: '1rem' }} icon={"MaterialIcon.MdOutlineDelete"} />
                                    </IconButton>
                                </div>
                            </ClickAwayListener>
                        </Popover>
                        <p style={{ fontSize: '12px', color: '#312d2d' }}>Modified on {FormatDate({ "value": each.updatedOn, "format": "dd/MM/yyyy" })}</p>
                        <p style={{ fontSize: '12px', color: '#312d2d' }}>Modified By {each.updatedBy}</p>
                    </div>
                })}

            </div>}
            <div style={{ boxShadow: '0rem 0.438rem 0.5rem -0.25rem rgba(0, 0, 0, 0.2), 0rem 0.75rem 1.063rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 0.313rem 1.375rem 0.25rem rgba(0, 0, 0, 0.12)', display: 'flex', justifyContent: 'flex-end', position: "fixed", bottom: '2px', background: '#ffffff', width: props.SubmitFooterWidth, borderRadius: '0 0 6px 6px' }}>
                <Button disableRipple style={{ margin: '0.5rem' }} className="styleButton styleManageButton" onClick={() => fnSubmitRules()}>{State?.translationsDataObject["SUBMIT_WR"]?.shortDescription ?? State?.oTranslationsJSON["SUBMIT_WR"]?.shortDescription ?? "Submit"}</Button>
            </div>
        </div>
        <MessageStrip MessageStripData={MessageStripData} setMessageStrip={setMessageStrip} />
        <MessageDialog setMessageDialog={setMessageDialog} MessageDialogContent={MessageDialogContent} />
    </div >
}
export default ViewRules