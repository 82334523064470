import { Avatar, TextField, IconButton, InputAdornment, Button, Divider } from "@mui/material";

import MessageIcon from '@mui/icons-material/Message';
import FormatDateobjToString from "../functions/cw_wr_DateTimeFormatter";
import "./cw_wr_comments.css";
import React from "react";
import CustomIcon from "../icons/CustomIcons";
const Comments = (props) => {
    const [value, setValue] = React.useState("");
    const [showdone, setShowdone] = React.useState(false);
    const fnChangeHandler = (event) => {
        setValue(event.target.value)
        setShowdone(true);
    }
    return (
        <div className='comment' style={{ backgroundColor: 'white', height: '70vh', margin: ' 0.5rem 0.5rem 0.5rem', overflowY: 'scroll' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ margin: 0, color: '#282828', fontSize: '1rem', fontWeight: 'bold' }}>Comments ({props.data ? props.data.length : 0})</p>
                <IconButton size="small" onClick={() => {
                    props.onClose()
                }
                } >
                    <CustomIcon style={{ width: '1.2rem', height: '1.2rem' }} icon={"MaterialIcon.MdOutlineClear"} />
                </IconButton>
            </div>
            <Divider style={{
                margin: '0.5rem', width: '100%'
            }} />
            <TextField placeholder="Write a Comment"
                value={value}
                size="small"
                onChange={(event) => fnChangeHandler(event)}
                className="styleCommentBox"
                InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            size="small"
                        >
                            <MessageIcon fontSize="small" />
                        </IconButton>
                    </InputAdornment>)
                }} />
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '0.5rem 0'
            }}>
                {showdone && <Button className="styleButton" disableRipple variant="text" style={{ width: '30%' }} onClick={() => { setShowdone(false); setValue('') }}>Cancel</Button>}

                {showdone && <Button className="styleButton" disableRipple variant="contained" onClick={() => { props.addComment(value, props.source, props.CommentsDetails.row); setShowdone(false); setValue('') }} style={{ width: '30%' }}
                >Done</Button>}
            </div>
            <Divider style={{
                margin: '0 0.5rem', width: '100%'
            }} />
            {props.data && props.data.map(comment => {
                let createdOn = <FormatDateobjToString milliSec={comment.createdOn} />
                let createdBy = comment.createdBy;
                if (comment.createdBy?.split(" ")[0]) {
                    createdBy = comment.createdBy?.split(" ")[0][0]
                }
                if (comment.createdBy?.split(" ")[1]) {
                    createdBy = createdBy + comment.createdBy?.split(" ")[1][0]
                }
                return (
                    <div>
                        <div style={{ display: 'flex', padding: '0.5rem 0' }}>
                            <Avatar style={{ width: '2rem', height: '2rem' }} >{createdBy}</Avatar>
                            <div style={{ marginLeft: '0.5rem' }}>
                                <p style={{ margin: 0, color: '#212121', fontSize: '0.875rem' }}>{comment.createdBy}</p>
                                <p style={{ margin: 0, color: '#BDBDBD', fontSize: '0.75rem' }}>{createdOn}</p>
                                {/* <p style={{ display: 'flex', justifyContent: 'space-between' }}>{comment.createdBy ? <b >{comment.createdBy}</b> : ""} {createdOn}</p> */}

                                <p style={{ margin: 0, color: '#212121', fontSize: '0.875rem' }}>{comment.description}</p>

                            </div>
                        </div>
                        <Divider style={{ width: '2rem', marginLeft: '0.5rem' }} />
                    </div>
                )

            })
            }
        </div>
    )
}
export default Comments;