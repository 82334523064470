import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import EditIcon from '@mui/icons-material/Edit';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MessageDialog from './cw_wr_MessageDialog';
import MessageStrip from './cw_wr_MessageStrip';
import './cw_wr_viewDT_table.css';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@mui/lab/TabList';
import AppBar from '@mui/material/AppBar';
import TabPanel from '@mui/lab/TabPanel';
import Vector from '../icons/Vector.svg';
import DTtab from '../icons/DTtab.svg';
import RLtab from '../icons/RLtab.svg';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';

import { Link, FormControl, Select } from "@mui/material";
import {
  Button,
  ButtonGroup,
  IconButton,
  Checkbox,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  TableCell,
  Divider,
  TextField,
  InputAdornment,
  Popover,
  TableFooter,
  ClickAwayListener,
} from "@mui/material";
import fetchWrapper from "../utility/fetchWrapper";
import FormatStatusColor from "../textrules/util/cw_tr_formatStatusColor";
import DisplayAvatar from '../utility/Avatar';
import { applicationProperties } from '../utility/applicationConfig';
import FormatDate from '../functions/cw_wr_DateFormatter'
// const useStylesTable = makeStyles({
//   root: {
//     width: "98%",
//     border: "0.25rem",
//     padding:'1rem 1rem 1rem 1rem ',
//     margin: "2rem 0.5rem 0rem 0.5rem",
//   },
//   container: {

//     width: "98%",
//     marginTop: "0.5rem",
//     borderRadius: "0.5rem 0.5rem 0.5rem 0.5rem",

//   },
//   columnHeaders: {
//     borderBottom: "none",

//   },
//   tableRow: {
//     height: 30,
//     "&:hover": {
//         backgroundColor: "#E5E5E5 !important"
//       }
//   },
//   tableCell: {
//     padding: "0px 6px !important",
//     height:'2rem'
//   },
// });
let footerWidth = "75%";

const ViewDT_Table = (props) => {
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const State = useSelector((state) => state.rootReducer);
  const [tab, settab] = useState("DTtable")
  const [oRuleChainDetails, setRuleChainDetails] = useState({});
  const [header, setShowHeader] = useState(false);
  const [aAvaialbleRules, setAvaialbleRules] = useState([])
 
  const fnEditDT = (evt, decisionTable) => {

    let oSelectedDetails = State.oSelectedDetails;
    oSelectedDetails["oSelectedDT"] = decisionTable;
    dispatch({ "type": "UPDATE_SELECTED_DETAILS", value: oSelectedDetails })
    props.setShowDetailsPage(true)
    dispatch({
      type: "SET_DETAILS_VIEW",
      value: {
        showRuleChain: false,
        showRuleEditor: false,
        showViewRules: false,
      },
    });



  };
  const [MessageDialogContent, setMessageDialog] = useState({
    "open": false,
    "data": "",
    "onConfirm": {}
  });
  const [showCreateChain, setShowCreateChain] = useState(false);
  const [MessageStripData, setMessageStrip] = useState({
    "show": false,
    "message": "",
    "type": ""

  })
  const [value, setValue] = useState("1");
  const [DT_Table, setDT_Table] = useState(State.oSelectedDT);
  const [RL_Table, setRL_Table] = useState(State.oSelectedRL);

  const searchQuery = (e) => {
    if (e?.target?.value === "" || e?.target?.value?.trim() === "") {
      setDT_Table(State.oSelectedDT);
      setRL_Table(State.oSelectedRL);
    }
    else {
      // DT
      if (value === "1") {
        let DT_Table = State?.oSelectedDT.filter(each => each?.label?.toLowerCase()?.includes( e?.target?.value?.toLowerCase() ) );

        setDT_Table(DT_Table)
      }
      else if (value === "2") {
        let RL_Table = State?.oSelectedRL.filter(each => each?.label?.toLowerCase()?.includes( e?.target?.value?.toLowerCase() ) );

        setRL_Table(RL_Table)
      }
    }
  }

  const DecisionTab = (State?.translationsDataObject["DECISION TABLE_WR"]?.shortDescription ?? State?.oTranslationsJSON["DECISION TABLE_WR"]?.shortDescription ?? "Decision Table") + "(" + State.oSelectedDT.length + ")"
  const RuleTab = "Rule List (" + State.oSelectedRL.length + ")"
  
  const onClickRuleList = (ruleList) => {
    props.fnGetAllRuleLists(ruleList).then(function () {
      props.setShowRuleListTableView(true)
      props.setShowDetailsPage(false)
    }.bind(this));
    props.fnGetAllRules(ruleList);

  }
  return (
    <div className="ViewDt" style={{ width: '100%', height: '100%' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginLeft: "1rem", marginTop: "0.3rem" }}
      >
        <Link color="inherit" href="/" style={{ fontSize: '0.875rem' }} className="styleLink">
          {State.oSelectedDetails.oAppDetails.label ? State.oSelectedDetails.oAppDetails.label : State.oSelectedDetails.oAppDetails.name}
        </Link>
        <Link color="inherit" style={{ fontSize: '0.875rem' }} className="styleLink">
          {State.oSelectedDetails.oSelectedRMS.label}
        </Link>
        <Link style={{ color: "#007AD4", fontSize: '0.875rem' }} className="styleLink">
          {State.oSelectedDetails.oSelectedRS.label}
        </Link>
      </Breadcrumbs>
      {State.showDetails.showViewRules && !showCreateChain && <p style={{ fontSize: '1rem', margin: '0.5rem 0rem 0rem 0.85rem', color: "#9E9E9E" }}><img src={Vector} alt="Logo" style={{ height: '0.95rem', marginRight: '0rem', color: "#9E9E9E", width: '1.25rem' }} />{State.oSelectedDetails.oSelectedRS.label}</p>}
      {State.showDetails.showViewRules && !showCreateChain && <Box sx={{ width: '100%', typography: 'body1', height: 'calc(100% - 4rem)' }} >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} textColor="primary"
              indicatorColor="primary">
              <Tab icon={<img src={DTtab} alt="Logo" style={{ width: '1em', height: '1em', marginRight: '0.5rem' }} />} iconPosition="start" label={DecisionTab} style={{ textTransform: "none", fontWeight: 500, fontSize: '0.875rem', marginRight: '1rem', padding: 0 }} value='1' />
              {State.featureFlag.RuleList && <Tab icon={<img src={RLtab} alt="Logo" style={{ marginRight: '0.5rem' }} />} iconPosition="start" label={RuleTab} style={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }} value='2' />}
            </TabList>
          </Box>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                variant='outlined'
                style={{ height: '0.8rem', textAlign: 'center', margin: '0.3rem 0.3rem 0.7rem 0.5rem', width: '12rem', background: '#ffffff' }}
                placeholder="Search"
                className="styleSearch"
                size="small"
                onChange={e => searchQuery(e)}


                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility">
                              <SearchIcon />
                            </IconButton>

                        </InputAdornment>
                    )
                }} 
              />
            </div>
          <TabPanel className='viewDtTab' value='1' >
            <div style={{ display: "flex", justifyContent: "space-between", height: '100%' }}>

              <div style={{ width: "100%" }}>
                <TableContainer component={Paper} className={'container'} sx={{ maxHeight: 'calc(100% - 4rem)' }} >
                  <Table

                    stickyHeader
                    aria-label="simple table"

                  >
                    <TableHead>
                      <TableRow style={{ height: '2.25rem', font: 'Roboto' }}>
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["DECISION TABLE NAME_WR"]?.shortDescription ?? State?.oTranslationsJSON["DECISION TABLE NAME_WR"]?.shortDescription ?? "Decision Table Name"}</TableCell>
                        {State?.featureFlag?.DT_Versioning && <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["VERSION_WR"]?.shortDescription ?? State?.oTranslationsJSON["VERSION_WR"]?.shortDescription ?? "Version"}</TableCell>}
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["MODIFIED BY_WR"]?.shortDescription ?? State?.oTranslationsJSON["MODIFIED BY_WR"]?.shortDescription ?? "Modified By"}</TableCell>
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["MODIFIED ON_WR"]?.shortDescription ?? State?.oTranslationsJSON["MODIFIED ON_WR"]?.shortDescription ?? "Modified On"}</TableCell>
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["STATUS_WR"]?.shortDescription ?? State?.oTranslationsJSON["STATUS_WR"]?.shortDescription ?? "Status"}</TableCell>
                        <TableCell
                          align="left"
                          style={{ marginLeft: "1rem", backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}
                        > {State?.translationsDataObject["ACTIONS_WR"]?.shortDescription ?? State?.oTranslationsJSON["ACTIONS_WR"]?.shortDescription ?? "Actions"}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {DT_Table.map((row, index) => (
                        <TableRow
                          className='tableRow'
                          key={row.ruleId}
                          hover
                          role="checkbox"
                          style={{ backgroundColor: '#F1F5FE', height: '2rem' }}

                          onClick={(evt) => fnEditDT(evt, row)}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className='tableCell'
                          >
                            {row.label}
                          </TableCell>
                          {State?.featureFlag?.DT_Versioning && <TableCell align="left" className='tableCell'>
                            {row.version}</TableCell>}
                          <TableCell align="left" className='tableCell'>
                            {/* <DisplayAvatar name={row.createdBy}/> */}
                            {row.createdBy}</TableCell>
                          <TableCell align="left" className='tableCell'> {FormatDate({
                            format: "dd/MM/yyyy",
                            value: row.createdOn
                          })}</TableCell>
                          <TableCell align="left" className='tableCell' style={{ fontWeight: 'bold', color: FormatStatusColor(row.status) }}>{row.status}</TableCell>
                          <TableCell align="left" className='tableCell'>
                            <IconButton

                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </TabPanel>
          <TabPanel className='viewDtTab' value='2' >
            <div style={{ display: "flex", justifyContent: "space-between", height: '100%' }}>

              <div style={{ width: "100%" }}>
                <TableContainer component={Paper} className={'container'} sx={{ maxHeight: 'calc(100% - 4rem)' }} >
                  <Table

                    stickyHeader
                    aria-label="simple table"

                  >
                    <TableHead>
                      <TableRow style={{ height: '2.25rem', font: 'Roboto' }}>
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>Rule List Name</TableCell>
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["MODIFIED BY_WR"]?.shortDescription ?? State?.oTranslationsJSON["MODIFIED BY_WR"]?.shortDescription}</TableCell>
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["MODIFIED ON_WR"]?.shortDescription ?? State?.oTranslationsJSON["MODIFIED ON_WR"]?.shortDescription}</TableCell>
                        <TableCell className='tableCell' align="left" style={{ backgroundColor: '#FAFAFA', fontWeight: '600', padding: '0px' }}>{State?.translationsDataObject["STATUS_WR"]?.shortDescription ?? State?.oTranslationsJSON["STATUS_WR"]?.shortDescription}</TableCell>
                        <TableCell
                          align="left"
                          style={{ marginLeft: "1rem", backgroundColor: '#FAFAFA', padding: '0px' }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {RL_Table.map((row, index) => (
                        <TableRow
                          className='tableRow'
                          key={row.ruleId}
                          hover
                          role="checkbox"
                          style={{ backgroundColor: '#F1F5FE', height: '2rem' }}

                          onClick={(evt) => onClickRuleList(row)}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className='tableCell'
                          >
                            {row.label}
                          </TableCell>
                          <TableCell align="left" className='tableCell'>
                            {/* <DisplayAvatar name={row.createdBy}/> */}
                            {row.createdBy}</TableCell>
                          <TableCell align="left" className='tableCell'>
                            {FormatDate({
                              format: "dd/MM/yyyy",
                              value: row.createdOn
                            })}</TableCell>
                          <TableCell align="left" className='tableCell' style={{ fontWeight: 'bold', color: FormatStatusColor(row.status) }}>{row.status}</TableCell>
                          <TableCell align="left" className='tableCell'>
                            <IconButton

                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </TabPanel>

        </TabContext>
      </Box>}

      <MessageStrip MessageStripData={MessageStripData} setMessageStrip={setMessageStrip} />
      <MessageDialog setMessageDialog={setMessageDialog} MessageDialogContent={MessageDialogContent} />
    </div>
  );
};

export default ViewDT_Table;
