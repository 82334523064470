import { createTheme } from "@mui/material";



export const customTheme = createTheme({

    // palette: {



    // },

    // typography: {

    // },

});