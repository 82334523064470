import { useNavigate } from "react-router-dom";
import { Tooltip, Drawer, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as FaIcons from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import * as BsIcons from 'react-icons/bs'
import React from 'react'
import './home2.css';
import { applicationProperties } from "./utility/applicationConfig";
import CustomIcon from './icons/CustomIcons';
import LanIcon from '@mui/icons-material/Lan';
const drawerWidth = 50;
const appbarHeight = 3;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        // height: `calc(100% - ${appbarHeight}rem)`
        height: '100%'
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        zIndex: 'inherit',
        position: 'relative',
        height: '3rem',
        boxShadow: 'none',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        // height: 'calc(100vh -  3rem)',
        height: '100%',
        width: '4.5rem',
        flexShrink: 0,
        position: 'relative',
        zIndex: '3',
    },
    drawerPaper: {
        width: '4.5rem',
        position: 'relative',
        zIndex: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundColor: getComputedStyle(document.documentElement)
        //     .getPropertyValue('--tabSideNavBg').trim(),

    },
    drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
},
    content: {
    background: "#FAFCFF",
    flexGrow: 1,
    height: '97vh',
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
},
    contentShift: {
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
},


}));

const Home2 = (props) => {
    let navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer)
    // const [Navmodelling, setNavmodelling] = React.useState(false);
    // const [Navauthoring, setNavauthoring] = React.useState(false);
    // const [Navtextrules, setNavtextrules] = React.useState(false);
    // const [NavApproval, setNavApproval] = React.useState(false);
    // const [NavRuleChain, setNavRuleChain] = React.useState(false);

    const [Navmodelling, setNavmodelling] = React.useState(window.location.href.includes("modelling"));
    const [Navauthoring, setNavauthoring] = React.useState(window.location.href.includes("authoring"));
    const [Navtextrules, setNavtextrules] = React.useState(window.location.href.includes("textrules"));
    const [NavApproval, setNavApproval] = React.useState(window.location.href.includes("simulation"));
    const [NavRuleChain, setNavRuleChain] = React.useState(window.location.href.includes("rule-chain"));

    React.useEffect(() => {
        if(!window.location.href.includes("modelling") && !window.location.href.includes("authoring") && !window.location.href.includes("textrules") && !window.location.href.includes("simulation") && !window.location.href.includes("rule-chain")){
            setNavauthoring(true);
        }
    }, []);

    React.useEffect(() => {
        try{
            if (window.location.href.includes("modelling")){
                setNavmodelling(true); 
                setNavauthoring(false); 
                setNavtextrules(false); 
                setNavApproval(false); 
                setNavRuleChain(false);
            }
            else if (window.location.href.includes("authoring")){
                setNavmodelling(false); 
                setNavauthoring(true);
                setNavtextrules(false); 
                setNavApproval(false); 
                setNavRuleChain(false);
            }
            else if (window.location.href.includes("textrules")){
                setNavmodelling(false); 
                setNavauthoring(false);
                setNavtextrules(true); 
                setNavApproval(false); 
                setNavRuleChain(false);
            }
            else if (window.location.href.includes("simulation")){
                setNavmodelling(false); 
                setNavauthoring(false);
                setNavtextrules(false); 
                setNavApproval(true); 
                setNavRuleChain(false);
            } 
            else if (window.location.href.includes("rule-chain")){
                setNavmodelling(false); 
                setNavauthoring(false);
                setNavtextrules(false); 
                setNavApproval(false); 
                setNavRuleChain(true);
            }
            else {
                setNavmodelling(false); 
                setNavauthoring(true);
                setNavtextrules(false); 
                setNavApproval(false); 
                setNavRuleChain(false);
            }
        }
        catch{
            console.log("wrong");
        }
    }, [window.location.href]);

    return (
        //<div style={{height:"92vh",display:'flex',width:'100%'}}>

        <Drawer id="nav1" className={classes.drawer} variant="persistent" anchor="left" open={true}
            classes={{
                paper: classes.drawerPaper,
            }}>
            <div style={{ padding: '0.3rem', marginTop:'1rem' }} className="styleVerticalFlex">
                {State.userAccess.activities.WR_Modelling && props.featureFlag.Modelling && <div className={Navmodelling ? "styleVerticalFlex styleIconBackground" : "styleVerticalFlex"} style={{ marginBottom:'1rem'}}>
                    <Tooltip title={State?.translationsDataObject["MODELING_WR"]?.longDescription ?? State?.oTranslationsJSON["MODELING_WR"]?.shortDescription ?? "Modeling"} placement="right">
                        <IconButton className="styleIcon"  onClick={() => {
                            setNavmodelling(true); navigate('modelling'); setNavauthoring(false); setNavApproval(false); setNavtextrules(false); setNavRuleChain(false); dispatch({
                                type: "SET_DEATILS_PAGE_ITEMS_VISIBILITY", "value": {
                                    adminConsole: false,
                                    landing: true,
                                    DTcreation: false,
                                    DTlist: false
                                }
                            });
                        }}>
                            <FaIcons.FaNetworkWired size={25} style={{ color: Navmodelling && "#007AD4" }} />
                        </IconButton>
                    </Tooltip>
                    <p style={{ textAlign: 'center', fontSize: '12px', marginBlockStart: '0rem', color: Navmodelling && "#007AD4" }}>{State?.translationsDataObject["MODELING_WR"]?.shortDescription ?? State?.oTranslationsJSON["MODELING_WR"]?.shortDescription ?? "Modeling"}</p>
                </div>
                }
                {State.userAccess.activities.WR_Authoring && props.featureFlag.Authoring && <div className={Navauthoring ? "styleVerticalFlex styleIconBackground" : "styleVerticalFlex"} style={{ marginBottom:'1rem'}}>
                    <Tooltip title={State?.translationsDataObject["AUTHORING_WR"]?.longDescription ?? State?.oTranslationsJSON["AUTHORING_WR"]?.shortDescription ?? "Authoring"} placement="right">
                        <IconButton   onClick={() => {
                            dispatch({
                                "type": "SET_DETAILS_VIEW", value: {
                                    "showRuleChain": false,
                                    "showRuleEditor": false,
                                    "showViewRules": false,
                                }
                            }); setNavmodelling(false); navigate('/authoring'); setNavauthoring(true); setNavApproval(false); setNavtextrules(false); setNavRuleChain(false);
                        }}>
                            <BsIcons.BsFillPersonFill size={25} style={{ color: Navauthoring && "#007AD4" }} />
                        </IconButton>
                    </Tooltip>
                    <p style={{ textAlign: 'center', fontSize: '12px', marginBlockStart: '0rem', color: Navauthoring && "#007AD4" }}>{State?.translationsDataObject["AUTHORING_WR"]?.shortDescription ?? State?.oTranslationsJSON["AUTHORING_WR"]?.shortDescription ?? "Authoring"}</p>
                </div>
                }
                {State.userAccess.activities.WR_Authoring && props.featureFlag.TextRules && <div className={Navtextrules ? "styleVerticalFlex styleIconBackground" : "styleVerticalFlex"} style={{ marginBottom:'1rem'}}>
                    <Tooltip title= {State?.translationsDataObject["TEXTRULES_WR"]?.longDescription ?? State?.oTranslationsJSON["TEXTRULES_WR"]?.shortDescription ?? "Text Rules"} placement="right">
                        <IconButton  onClick={() => { setNavmodelling(false); navigate('/textrules'); setNavauthoring(false); setNavApproval(false); setNavtextrules(true); setNavRuleChain(false); }}>
                            <BsIcons.BsLayoutTextSidebarReverse size={25} style={{ color: Navtextrules && "#007AD4" }} />
                        </IconButton>
                    </Tooltip>
                    <p style={{ textAlign: 'center', fontSize: '12px', marginBlockStart: '0rem', color: Navtextrules && "#007AD4" }}>{State?.translationsDataObject["TEXTRULES_WR"]?.shortDescription ?? State?.oTranslationsJSON["TEXTRULES_WR"]?.shortDescription ?? "TextRules"}</p>
                </div>
                }
                {State.userAccess.activities.WR_Authoring && props.featureFlag.RuleChain && <div className={NavRuleChain ? "styleVerticalFlex styleIconBackground" : "styleVerticalFlex"} style={{ marginBottom:'1rem'}}>
                    <Tooltip title={State?.translationsDataObject["RULE CHAIN_WR"]?.longDescription ?? State?.oTranslationsJSON["RULE CHAIN_WR"]?.shortDescription ?? "Rule Chain"} placement="right"><IconButton style={{ transform: 'rotate(-90deg)' }} onClick={() => { setNavmodelling(false); navigate('/rule-chain'); setNavauthoring(false); setNavApproval(false); setNavtextrules(false); setNavRuleChain(true); }}>
                        <LanIcon style={{ width: '1.2rem', height: '1.2rem', color: NavRuleChain && "#007AD4"  }} />
                    </IconButton>
                    </Tooltip>
                    <p style={{ textAlign: 'center', fontSize: '12px', marginBlockStart: '0rem', justifyContent: 'center', color: NavRuleChain && "#007AD4" }}>{State?.translationsDataObject["RULE CHAIN_WR"]?.shortDescription ?? State?.oTranslationsJSON["RULE CHAIN_WR"]?.shortDescription ?? "Rule Chain"}</p>
                </div>
                }
                {State.userAccess.activities.WR_Authoring && props.featureFlag.RuleSimulation && <div className={NavApproval ? "styleVerticalFlex styleIconBackground" : "styleVerticalFlex"} style={{ marginBottom:'1rem'}}>
                    <Tooltip title= {State?.translationsDataObject["RULE SIMULATION_WR"]?.longDescription ?? State?.oTranslationsJSON["RULE SIMULATION_WR"]?.shortDescription ?? "Rule Simulation"} placement="right"><IconButton onClick={() => { setNavmodelling(false); navigate('/simulation'); setNavauthoring(false); setNavApproval(true); setNavtextrules(false); setNavRuleChain(false); }}>
                        {/* <BsIcons.BsFillPersonCheckFill size={25} style={{ color: NavApproval && "#3026B9" }} /> */}
                        <CustomIcon icon={"SVGIcons.SimulationIcon"} style={{ width: '1.2rem', height: '1.2rem', color: NavApproval && "#007AD4" }}  />
                    </IconButton>
                    </Tooltip>
                    <p style={{ textAlign: 'center', fontSize: '12px', marginBlockStart: '0rem', justifyContent: 'center', color: NavApproval && "#007AD4" }}>{State?.translationsDataObject["RULE SIMULATION_WR"]?.shortDescription?? State?.oTranslationsJSON["RULE SIMULATION_WR"]?.shortDescription ?? "Rule Simulation"}</p>
                </div>
                }


            </div>

        </Drawer>
        // <Outlet/> 
        //  </div>


    )
}
export default Home2;