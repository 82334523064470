import { useSelector, useDispatch } from 'react-redux';
import { Divider, IconButton } from '@mui/material';
import CustomIcon from "../../icons/CustomIcons";
import CircularProgress from '@material-ui/core/CircularProgress';
const ExpressionPopover = (props) => {
    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer)
    const fnOnCloseExpressionPopover = () => {
        let aFilteredTextRules = [...State.aFilteredTextRules];
        aFilteredTextRules.forEach(each => {
            each["showExpression"] = false
        })
        dispatch({
            type: "UPDATE_RULES_LIST", "value": [...State.aTextRulesList], "aFilteredTextRules": aFilteredTextRules
        })
        props.setShowExpression(false)
        dispatch({ type: "UPDATE_MODAL_VISIBILITY", value: false })
    }
    return (
        // <ModalComponent>
        <div style={{ background: '#F5F5F5', padding:'0rem 0.5rem 0.5rem 0rem', borderRadius: '7px', width: '32%', height: '100%',boxShadow: '0rem 0.438rem 0.5rem -0.25rem rgba(0, 0, 0, 0.2), 0rem 0.75rem 1.063rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 0.313rem 1.375rem 0.25rem rgba(0, 0, 0, 0.12)' }}>
            <div style={{  display: 'flex', justifyContent: 'space-between',  alignItems: 'center',backgroundColor:'#DBEEFF',height:'3.5rem' }}>

                <p style={{ fontWeight: '600',paddingLeft:'1rem' }}>Expressions</p>
                <div style={{ display: 'flex' }}>
                    {/* <p>Status: </p>
                        <p>{State.TextRuleDetails.status}</p>
                        <Divider orientation="vertical" style={{ margin: '0rem 0 0 0.5rem', height: '80%' }} /> */}
                    <IconButton size="small" onClick={() => {
                        fnOnCloseExpressionPopover()
                    }
                    } >
                        <CustomIcon style={{ width: '1.5rem', height: '1.5rem' }} icon={"MaterialIcon.MdOutlineClear"} />
                    </IconButton>
                </div>
            </div>
            <Divider style={{ width: '98%', height: '1px' }} />
            {!props.showExpLoader && <p style={{ margin: '0.75rem 0 0 0', fontWeight: '600' }}>{State.TextRuleDetails.ruleName}</p>}
            {props.showExpLoader ? <CircularProgress color="inherit" style={{ margin: '60% 0 0 40%' }} /> : <div style={{ overflowY: 'scroll', height: 'calc(100% - 4rem)',backgroundColor:'white' }}> {
                State.aBlocksBackUp.map(block => {
                    return <div style={{ padding: '0.5rem 0.5rem 0.5rem 0.5rem', margin: '2.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '5px', background: '#F5F5F5' }}>
                        <p style={{ margin: 0, textAlign: 'left',fontWeight:'600' }}>Block 1{block.Name}</p>
                        <Divider style={{ width: '98%', height: '1px', margin: '0.3rem 0 0.3rem 0' }}></Divider>
                        <p style={{ margin: 0, textAlign: 'left',paddingTop:'0.75rem'  }}>Block 1{block.expression}</p>
                    </div>
                })
            }
            </div>}
        </div>
        // </ModalComponent >
    )
}
export default ExpressionPopover