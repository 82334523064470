import { TextRules } from '@cw/rule-generator';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import features from "../../utility/features.json";

const RuleEditor = (props) => {
    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer);
    const [showTextrules, setShowTextrules] = useState(false)
    let ruleDetails = {
        applicationId: State.oSelectedDetails.oAppDetails.applicationId,
        applicationName: State.oSelectedDetails.oAppDetails.name,
        RMSid: State.oSelectedDetails.oSelectedRMS.id,
        RSid: State.oSelectedDetails.oSelectedRS.id,
        ruleId: State.oSelectedDetails.rule.id,
        userDetails: {
            emailId: State.userDetails.emailId,
            user_id: State.userDetails.user_id
        },
        ruleName: '',
        token: State.requestOptions.headers?.Authorization?.replaceAll("Bearer", "").trim(),
        isDefaultConditions: false,
        isDefaultActions: false
    }
    const fnNavBackToViewRules = () => {
        props.setActivityLog({
            "showActivityLog": false,
            "ActivityLogData": { ...props.activityLog.ActivityLogData }
        })
        props.setAuditLog({
            "showAuditLog": false,
            "AuditLogData": { ...props.auditLog.AuditLogData }
        })
        dispatch({
            "type": "SET_DETAILS_VIEW", value: {
                "showRuleChain": false,
                "showRuleEditor": false,
                "showViewRules": true,
            }
        })
        let SelectedRMS = props.data.find(each => each.id === State.oSelectedDetails.oSelectedRMS.id)
        let SelectedRS = SelectedRMS.childs.find(each => each.id === State.oSelectedDetails.oSelectedRS.id)
        if (SelectedRS.textrules) {
            SelectedRS.textrules.forEach(each => each["showicon"] = false)
            dispatch({
                type: "UPDATE_RULES_LIST", "value": SelectedRS.textrules, "aFilteredTextRules": SelectedRS.textrules
            })
        }

        props.setShowHeader(true)
    }
    const Onsave = () => {
        props.getDecisionTable(State.oSelectedDetails.oAppDetails.applicationId)
    }
    return <TextRules translationDataObjects={State.worktextResponse} NavBackHandler={fnNavBackToViewRules} orchestration={true} ruleDetails={ruleDetails} destinations={State.destinationsList} saveHandler={data => Onsave(data)} />
}
export default RuleEditor;