
import CustomIcon from "../icons/CustomIcons";
import { useState } from 'react'
import { Tooltip, Popover, ClickAwayListener, InputAdornment, IconButton, Button, Stack, TextField, Chip, Breadcrumbs, Link, Select, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LookUpTable from "./cw_wr_lookupTable";
import LookupService from "./lookupServices";
import DatePicker from './cw_wr_DatePicker';
import DateTimePicker from './cw_wr_DateTimePicker';
import OperatorsPopup from './cw_wr_operatorsPopup';
import RangeOperatorPopover from './cw_wr_RangeOperatorsPopup'
import MessageStrip from './cw_wr_MessageStrip';
import MessageDialog from "./cw_wr_MessageDialog";
import BusyLoader from "../textrules/components/busyLoader";
import fetchWrapper from "../utility/fetchWrapper";
import FormatDate from "../functions/cw_wr_DateFormatter";
import Comments from "./cw_wr_comments";
const RuleListCreation = (props) => {
    const State = useSelector((state) => state.rootReducer);
    const dispatch = useDispatch();
    const [selectedFlexibleCondition, setSelectedFlexibleCondition] = useState(null)
    const [selectedFlexibleAction, setSelectedFlexibleAction] = useState(null);
    const [showLookup, setShowLookup] = useState(false);
    const [valueHelpData, setValueHelpData] = useState({});
    const [column, setCurrentColumn] = useState()
    const [operatorValue, setOperatorValue] = useState('')
    const [ValueHelpTitle, setValueHelpTitle] = useState('')
    const [selectedRow, setSelectedrow] = useState([]);
    const [showOperatorsPopover, setShowOperatorsPopover] = useState(false);
    const [OperatorsPopoverAnchor, setOperatorsPopoverAnchor] = useState(null)
    const [operatorsPopupDetails, setOperatorsPopupDetails] = useState({
        column: null,
        operator: null,
        operatorsList: []
    })
    const [showRangeOperatorsPopup, setShowRangeOperatorsPopup] = useState(false);
    const [RangeOperatorsPopoverAnchor, setRangeOperatorsPopoverAnchor] = useState(null)
    const [RangeOperatorPopupDetails, setRangeOperatorPopupDetails] = useState({
        fromValue: null,
        toValue: null,
        fromSelection: "[",
        toSelection: "]",
        field: {},
        token: {}
    })
    const [validityFromDate, setValidityFromDate] = useState('01/02/1970');
    const [validityToDate, setValidityToDate] = useState('12/31/9999');
    const [comments, setComments] = useState(null)
    const [MessageDialogContent, setMessageDialog] = useState({
        "open": false,
        "data": "",
        "onConfirm": {}
    });
    const [MessageStripData, setMessageStrip] = useState({
        "show": false,
        "message": "",
        "type": ""

    })
    const [Loading, setLoading] = useState(false);
    const [bValidation, setbValidation] = useState(true)

    const [anchorElementComments, setAnchorElementComments] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const [anchorElement, handleAnchorElement] = useState(null);
    const [TokensData, setTokensData] = useState(null)
    const fnStringToBoolean = (str) => {
        var boolean;
        if (typeof (str) === "string") {
            if (str.toUpperCase() === "TRUE" || str === "True") {
                boolean = true;
            } else {
                boolean = false;
            }
            return boolean;
        } else {
            return str;
        }
    }
    const StringToInteger = (str) => {
        if (str) {
            var integer = parseInt(str, 10);
            return integer;
        } else {
            return "";
        }

    }
    const StringToFloat = (str) => {
        if (str) {
            var Float = parseFloat(str);
            return Float;
        }
        return "";

    }
    const fnSaveRuleList = (saveOrSubmit) => {
        console.log(props)
        let oldValues = structuredClone(props.variantInstanceDetails.values);
        let conditionListArray = [], aConditions = structuredClone(props.variantInstanceDetails.aConditions), aActions = structuredClone(props.variantInstanceDetails.aActions), array = [], aConvertedRecords = [], aDeletedCombinations = [];
        aConditions.forEach((condition, cIndex) => {
            array.push(condition.tokens)
        })
        var obj = {}, oLookUpTexts = {}
        for (var i = 0; i < aConditions.length; i++) {
            aConditions[i].objs = [];
            for (var j = 0; j < aConditions[i].tokens.length; j++) {
                if (aConditions[i].dataType === "NVARCHAR" && !aConditions[i].propertyDto.isLookup && props.variantInstanceDetails.isExpression) {
                    aConditions[i].tokens[j].key = "'" + aConditions[i].tokens[j].key + "'"
                }
                else if ((aConditions[i].dataType === "NVARCHAR" && aConditions[i].propertyDto.isLookup)) {
                    oLookUpTexts[aConditions[i].columnName + "_TEXT"] = aConditions[i].tokens[j].text
                }
                else if (aConditions[i].dataType === "TIMESTAMP" && !props.variantInstanceDetails.isExpression) {
                    // timestamp payload format yyyy- hr:min:sec
                    // aConditions[i].tokens[j].key = aConditions[i].tokens[j].key.replaceAll("T", " ");
                    // aConditions[i].tokens[j].key = aConditions[i].tokens[j].key.split(" ")[0].split("-")[1] + "/" + aConditions[i].tokens[j].key.split(" ")[0].split("-")[2] + "/" + aConditions[i].tokens[j].key.split(" ")[0].split("-")[0] + " " + aConditions[i].tokens[j].key.split(" ")[1]
                    aConditions[i].tokens[j].key = aConditions[i].tokens[j].key.replaceAll("T", " ") + ":00"
                }
                else if (aConditions[i].dataType === "TIMESTAMP" && props.variantInstanceDetails.isExpression && aConditions[i].tokens[j].operator !== "IN" && aConditions[i].tokens[j].operator !== "NOTIN" && aConditions[i].tokens[j].operator !== "EXISTSIN" && aConditions[i].tokens[j].operator !== "NOTEXISTSIN") {
                    // timestamp payload format MM/DD/YYYY hr:min:sec
                    aConditions[i].tokens[j].key = aConditions[i].tokens[j].key.replace(aConditions[i].tokens[j].key.split("T")[0], FormatDate({
                        value: aConditions[i].tokens[j].key.split("T")[0],
                        format: "MM/dd/yyyy"
                    }))
                    aConditions[i].tokens[j].key = "'" + aConditions[i].tokens[j].key.replaceAll("T", " ") + ":00'"
                }

                else if (aConditions[i].dataType === "DATE" && props.variantInstanceDetails.isExpression && aConditions[i].tokens[j].operator !== "IN" && aConditions[i].tokens[j].operator !== "NOTIN" && aConditions[i].tokens[j].operator !== "EXISTSIN" && aConditions[i].tokens[j].operator !== "NOTEXISTSIN") {
                    // MM/DD/YYYY
                    aConditions[i].tokens[j].key = "'" + FormatDate({
                        value: aConditions[i].tokens[j].key,
                        format: "MM/dd/yyyy"
                    }) + "'"

                }
                if (props.variantInstanceDetails.isExpression && (aConditions[i].tokens[j].operator === "EXISTSIN" || aConditions[i].tokens[j].operator === "NOTEXISTSIN")) {
                    let aTemp = [];
                    aTemp = aConditions[i].tokens[j].data.map(each => {
                        if (aConditions[i].dataType === "NVARCHAR" && aConditions[i].propertyDto.isLookup) {
                            return "'" + each.text + "{" + each.key + "}'"

                        }
                        else if (aConditions[i].dataType === "NVARCHAR" && !aConditions[i].propertyDto.isLookup) {
                            return "'" + each.key + "'"
                        }
                        else {
                            return each.key
                        }
                    })
                    aConditions[i].tokens[j].key = "[" + aTemp.join(",") + "]"
                }
                if (props.variantInstanceDetails.isExpression) {
                    aConditions[i].objs.push(aConditions[i].tokens[j].operator + aConditions[i].tokens[j].key);
                }
                else {
                    aConditions[i].objs.push(aConditions[i].tokens[j].key);
                }
            }
            obj[aConditions[i].columnName] = aConditions[i].objs;
        }
        conditionListArray = getCombinations(obj, 0, [], {});
        console.log(conditionListArray);

        conditionListArray.forEach(function (condition) {
            let bConditionAlreadyExists = false;
            if (oLookUpTexts[Object.keys(condition)[0] + "_TEXT"]) {
                condition[Object.keys(condition)[0] + "_TEXT"] = oLookUpTexts[Object.keys(condition)[0] + "_TEXT"]
            }
            let object = { ...condition }
            props.variantInstanceDetails.aFlexibleFormConditions.forEach(flexiblecondition => {
                if (flexiblecondition.dataType === "NVARCHAR") object[flexiblecondition.columnName] = "*"
                else object[flexiblecondition.columnName] = null
            })
            aActions.forEach(function (action) {
                object[action.columnName] = action[action.columnName];
                if (action.propertyDto.isLookup && !props.variantInstanceDetails.isExpression) {
                    object[action.columnName + "_TEXT"] = action[action.columnName + "_TEXT"];
                }
                else if (action.propertyDto.isLookup && props.variantInstanceDetails.isExpression) {
                    object[action.columnName] = "'" + action[action.columnName + "_TEXT"] + "{" + action[action.columnName] + "}'";
                }
                else if (props.variantInstanceDetails.isExpression && (action.dataType === "NVARCHAR" || action.dataType === "DATE" || action.dataType === "TIMESTAMP")) {
                    object[action.columnName] = "'" + object[action.columnName] + "'"
                }
            })

            oldValues?.forEach(oldCombination => {
                let temp = structuredClone(oldCombination);
                delete temp.CREATED_BY
                delete temp.CREATED_ON
                delete temp.DT_ID
                delete temp.RULE_RECORD_ID
                delete temp.RULE_NAME
                delete temp.SEQUENCE_NO
                delete temp.STATUS
                delete temp.UPDATED_BY
                delete temp.UPDATED_ON
                delete temp.VALID_FROM
                delete temp.VALID_TO
                if (JSON.stringify(object) == JSON.stringify(temp)) {
                    bConditionAlreadyExists = true
                    object["STATUS"] = "Modified";
                    object["RULE_RECORD_ID"] = oldCombination["RULE_RECORD_ID"];
                    object["CREATED_BY"] = oldCombination["CREATED_BY"];
                    object["CREATED_ON"] = oldCombination["CREATED_ON"]
                }
            })
            if (!bConditionAlreadyExists) {
                object["STATUS"] = "Created";
                object["RULE_RECORD_ID"] = null;
                object["CREATED_ON"] = new Date(new Date().toUTCString()).getTime()
                object["CREATED_BY"] = State.userDetails.user_id
            }
            object["VALID_FROM"] = props.RuleListDetails.validityFromDate
            object["VALID_TO"] = props.RuleListDetails.validityToDate
            object["RULE_NAME"] = props.RuleListDetails.name;
            object["UPDATED_BY"] = State.userDetails.user_id;
            object["UPDATED_ON"] = new Date(new Date().toUTCString()).getTime();
            var oComment = {
                "comments": props.CommentsDetails.data,
                "data": object,
                "action": saveOrSubmit
            };
            aConvertedRecords.push(oComment)
        })
        oldValues?.forEach(old => {
            let bExistsNow = false;
            let oOldConditionsAndActions = structuredClone(old);
            aConvertedRecords?.forEach(newCombination => {
                let oNewConditionsAndActions = structuredClone(newCombination.data);
                // removing all extra fields and keeping only conditions and actions
                delete oOldConditionsAndActions.CREATED_BY
                delete oOldConditionsAndActions.CREATED_ON
                delete oOldConditionsAndActions.DT_ID
                delete oOldConditionsAndActions.RULE_RECORD_ID
                delete oOldConditionsAndActions.RULE_NAME
                delete oOldConditionsAndActions.SEQUENCE_NO
                delete oOldConditionsAndActions.STATUS
                delete oOldConditionsAndActions.UPDATED_BY
                delete oOldConditionsAndActions.UPDATED_ON
                delete oOldConditionsAndActions.VALID_FROM
                delete oOldConditionsAndActions.VALID_TO

                // removing all extra fields and keeping only conditions and actions
                delete oNewConditionsAndActions.CREATED_BY
                delete oNewConditionsAndActions.CREATED_ON
                delete oNewConditionsAndActions.DT_ID
                delete oNewConditionsAndActions.RULE_RECORD_ID
                delete oNewConditionsAndActions.RULE_NAME
                delete oNewConditionsAndActions.SEQUENCE_NO
                delete oNewConditionsAndActions.STATUS
                delete oNewConditionsAndActions.UPDATED_BY
                delete oNewConditionsAndActions.UPDATED_ON
                delete oNewConditionsAndActions.VALID_FROM
                delete oNewConditionsAndActions.VALID_TO

                if (JSON.stringify(oOldConditionsAndActions) === JSON.stringify(oNewConditionsAndActions)) {
                    bExistsNow = true;

                }
            })
            if (!bExistsNow) {
                oOldConditionsAndActions["STATUS"] = "Deactivated";
                oOldConditionsAndActions["RULE_RECORD_ID"] = old["RULE_RECORD_ID"];
                oOldConditionsAndActions["CREATED_BY"] = old["CREATED_BY"];
                oOldConditionsAndActions["CREATED_ON"] = old["CREATED_ON"]
                aDeletedCombinations.push({
                    "comments": comments,
                    "data": oOldConditionsAndActions,
                    "action": saveOrSubmit
                })
            }
        })

        aConvertedRecords = [...aConvertedRecords, ...aDeletedCombinations]
        fnSaveDT(props.decisionTableFormType, aConvertedRecords)

    }
    const fnCreateActivityLog = (entityId, entityType, desc) => {
        let oCreatedDate = new Date().toISOString().slice(0, 19);
        let oPayload = [{
            "createdBy": State.userDetails.displayName,
            "createdOn": oCreatedDate,
            "description": desc,
            "entityId": entityId,
            "entityType": entityType,
            "logId": null,
            "name": null,
            "type": null
        }];
        let requestOptionsPOST = { ...State.requestOptions };
        requestOptionsPOST['method'] = 'POST';
        requestOptionsPOST["body"] = JSON.stringify(oPayload)
        let sUrl = "/WorkRulesServices/v1/logs/activity";
        Promise.all([
            fetchWrapper(sUrl, requestOptionsPOST).then((res) => res.json()),

        ])
            .then(([oData]) => {
                dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
                if (oData.statusCode === 401 || oData.statusCode === '401') {
                    let message = "Session Timed Out.Kindly Refresh";
                    setMessageDialog({
                        "open": true,
                        "data": message,
                        onConfirm: () => { window.location.reload() },
                        type: 'Error'

                    })
                }





            })

            .catch((error) => {
                console.log(error);
                dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
            });

    }
    const fnSaveDT = (form, aSaveModelData) => {
        let saveProperties = {
            "mode": "DT",
            "taskOwner": null,
            "action": "Save"
        };
        let selectedVarientId = props.variantInstanceDetails.variantId;
        let selectedRuleSetId = State.oSelectedDetails.oSelectedRS.id;
        let selectedRuleMaintSetId = State.oSelectedDetails.oSelectedRMS.id;
        let selectedApplicationId = State.oSelectedDetails.oAppDetails.applicationId
        // let aSaveModelData = [...State.aSaveModalDataTemp];
        let aVariantInstanceDetails = { ...props.variantInstanceDetails }
        if (!bValidation) {
            console.log("Please fill the all records Properly");
            setMessageStrip({
                "type": "error",
                "show": true,
                "message": "Please fill the all records Properly"
            }
            )

        } else {
            let valid = fnValidationsBeforeSave(aSaveModelData);
            if (valid) {
                setMessageDialog({
                    "open": true,
                    "data": "Are you sure you want to save the records?",
                    type: "Confirmation",
                    onConfirm: () => {
                        props.setLoading(true)
                    
                        if (aSaveModelData.length !== 0) {
                            let aMetaDataFields = [];
                            let oMetaDataFields = {};
                            aVariantInstanceDetails.fields.forEach(function (elements) {
                                if (elements.controlName !== "ICON") {
                                    oMetaDataFields.dataType = elements.dataType;
                                    oMetaDataFields.columnName = elements.columnName;
                                    oMetaDataFields.fieldId = elements.columnId;
                                    oMetaDataFields.maxLength = elements.maxLength;
                                    oMetaDataFields.isEditable = elements.propertyDto.isEditable;
                                    oMetaDataFields.isMandatory = elements.propertyDto.isMandatory;
                                    oMetaDataFields.businessType = elements.propertyDto.businessType;
                                    oMetaDataFields.isOperatorColumn = elements.propertyDto.isOperatorColumn;
                                    oMetaDataFields.operator = elements.propertyDto.operator;
                                    aMetaDataFields.push(oMetaDataFields);
                                    oMetaDataFields = {};
                                }
                            });
                            let definitionId = aVariantInstanceDetails.definitionId
                            let decisionTableId = definitionId.replace(definitionId.substring(definitionId.lastIndexOf("_"), definitionId.length), "")
                            let dtVersion = definitionId.substring(definitionId.lastIndexOf("_") + 1, definitionId.length)
                            let oPayload = {
                                "mode": saveProperties.mode,
                                "action": saveProperties.action,
                                "ruleRecords": aSaveModelData,
                                "decisionTableId": decisionTableId,
                                "metadata": aMetaDataFields,
                                "updatedBy": State.userDetails.user_id,
                                "updatedOn": new Date().toISOString().slice(0, 19),
                                "rulesetId": selectedRuleSetId,
                                "ruleMaintenanceSetId": selectedRuleMaintSetId,
                                "isScale": aVariantInstanceDetails.isScales,
                                "applicationId": selectedApplicationId,
                                "taskOwner": State.userDetails.user_id,
                                "dtVersion": dtVersion

                            };
                            let sUrl = "/WorkRulesServices/v1/authoring/save";
                            let requestOptionsPOST = { ...State.requestOptions };
                            requestOptionsPOST['method'] = 'POST';
                            requestOptionsPOST["body"] = JSON.stringify(oPayload)
                            Promise.all([
                                fetchWrapper(sUrl, requestOptionsPOST).then((res) => res.json())
                            ])
                                .then(([oData]) => {
                                    props.setLoading(false);
                                    dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
                                    if (oData.statusCode === 401 || oData.statusCode === '401') {
                                        let message = "Session Timed Out.Kindly Refresh";
                                        setMessageDialog({
                                            "open": true,
                                            "data": message,
                                            onConfirm: () => { window.location.reload() },
                                            type: 'Error'

                                        })
                                    }
                                    if (oData.status && oData.statusCode === 200) {
                                        // props.setaSaveModalData([])
                                        // dispatch({ "type": "SETMODALDATATEMP", value: [] })
                                        // props.fnGetDecisionTableDetails(props.selectedVarientId, props.variantInstanceDetails.isExpression)
                                        fnCreateActivityLog(props.variantInstanceDetails.definitionId, "DecisionTable", "Records Saved")
                                        //createActivityLog
                                        setMessageStrip({
                                            "type": "success",
                                            "show": true,
                                            "message": "Records Saved Succesfully"
                                        });
                                        // props.setTableActionsPermissions({
                                        //     ...props.oTableActionsPermissions,
                                        //     "bSaveBtnEnable": false,
                                        //     bValidateBtnEnable: false
                                        // })
                                    }

                                })


                                .catch((error) => {
                                    console.log(error);
                                    props.setLoading(false);
                                    dispatch({ type: 'UPDATE_BUSY_LOADER', value: false })
                                });
                        }
                        else {
                            props.setLoading(false)
                        }
                    }
                }
                )

            }
        }

    }
    const fnValidationsBeforeSave = (aSaveModalData) => {
        let aVariantInstanceDetails = { ...props.variantInstanceDetails };
        // let aSaveModalData = [...props.aSaveModalData];
        let somethingMissing = false;
        let msgs1 = "";
        let ValidatedFrom_to = true;
        let validFormDate, validToDate;
        let errorMsg;
        aSaveModalData.find(function (element) {
            element = element.data;
            if (element.VALID_FROM) {
                validFormDate = new Date(new Date(element.VALID_FROM).toUTCString()).getTime();
            }
            if (element.VALID_TO) {
                validToDate = new Date(new Date(element.VALID_TO).toUTCString()).getTime();
            }
            if (element.VALID_FROM && element.VALID_TO) {
                if (validFormDate > validToDate) {
                    ValidatedFrom_to = false;
                    errorMsg = "VALIDITY_DATE_NOT_PROPER";
                    setMessageDialog({
                        "data": errorMsg,
                        "open": true,
                        'onConfirm': () => { },
                        type: 'Error'
                    })
                    return false;
                }
            }
            if (!element.VALID_FROM) {
                if (element.DATAB) {
                    validFormDate = new Date(new Date(element.DATAB).toUTCString()).getTime();
                }
            }
            if (!element.VALID_TO) {
                if (element.DATBI) {
                    validToDate = new Date(new Date(element.DATBI).toUTCString()).getTime();
                }
            }
            if (!element.VALID_FROM && !element.VALID_TO) {
                if (element.DATAB && element.DATBI) {
                    // if (element.DATAB > element.DATBI) {
                    if (validFormDate > validToDate) {
                        ValidatedFrom_to = false;
                        errorMsg = "VALIDITY_DATE_NOT_PROPER";
                        setMessageDialog({
                            "data": errorMsg,
                            "open": true,
                            'onConfirm': () => { },
                            type: 'Error'
                        })
                        return false;
                    }
                }
            }
        });
        if (ValidatedFrom_to) {

            aSaveModalData.forEach(function (rowEelments) {
                rowEelments = rowEelments.data;
                delete rowEelments.flag;
                // delete rowEelments.popupComments;
                aVariantInstanceDetails.fields.forEach(function (element) {
                    if (element.propertyDto.businessType !== "I") {
                        if (element.propertyDto.businessType === "C") {
                            if (element.propertyDto.isVisible === false) {
                                rowEelments[element.columnName] = element.propertyDto.defaultValue;
                            }
                        }
                        if (!aVariantInstanceDetails.isExpression) {
                            if (element.dataType === "BOOLEAN") {
                                rowEelments[element.columnName] = fnStringToBoolean(rowEelments[element.columnName]);
                            } else if (element.dataType === "INTEGER") {
                                rowEelments[element.columnName] = StringToInteger(rowEelments[element.columnName]);
                            } else if (element.dataType === "DECIMAL") {
                                rowEelments[element.columnName] = StringToFloat(rowEelments[element.columnName]);
                            } else if (element.dataType === "TIMESTAMP") {
                                rowEelments[element.columnName] = new Date(new Date(rowEelments[element.columnName])).getTime();
                            }
                        }
                        if (rowEelments[element.columnName] === "") {
                            rowEelments[element.columnName] = null
                        }
                        if (element.propertyDto.isEditable === true && element.propertyDto.isMandatory === true) {
                            if (rowEelments[element.columnName] === null || rowEelments[element.columnName] === "") {
                                msgs1 += ("Please enter " + element.label + " value, " + "\n");
                                somethingMissing = true;
                            }
                        }
                    }
                });
            });
            dispatch({ "type": "SETMODALDATATEMP", value: aSaveModalData })
            // props.setaSaveModalData(aSaveModalData);
            // ruleAuthoringModel.setProperty("/saveValidatedRuleRecords", aSaveModelData);
            if (somethingMissing) {
                if (msgs1.length !== 0) {
                    setMessageDialog({
                        "data": msgs1,
                        "open": true,
                        'onConfirm': () => {
                            props.setLoading(false)
                            setLoading(false)
                        },
                        type: 'Error'
                    })
                   
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    function getCombinations(options, optionIndex, results, current) {
        var allKeys = Object.keys(options);
        var optionKey = allKeys[optionIndex];
        var vals = options[optionKey];
        for (var i = 0; i < vals.length; i++) {
            current[optionKey] = vals[i];
            if (optionIndex + 1 < allKeys.length) {
                getCombinations(options, optionIndex + 1, results, current);
            } else {
                var res = JSON.parse(JSON.stringify(current));
                results.push(res);
            }
        }
        return results;
    }
    const onSelectFlexibleConditon = (event, condition) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        variantInstanceDetails.aConditions.push(condition);
        variantInstanceDetails.aFlexibleFormConditions.map((each, index) => {
            if (each.columnId === condition.columnId) {
                variantInstanceDetails.aFlexibleFormConditions.splice(index, 1)
            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const onSelectFlexibleAction = (event, action) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        variantInstanceDetails.aActions.push(action);
        variantInstanceDetails.aFlexibleFormActions.map((each, index) => {
            if (each.columnId === action.columnId) {
                variantInstanceDetails.aFlexibleFormActions.splice(index, 1)
            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const onChangeValue = (event, field, chipToDelete) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        
        let aChipData = field.tokens;
        let updatedChips = [];
        if (chipToDelete) {
            aChipData = aChipData.filter((chip) => chip.id !== chipToDelete.id);
            aChipData.forEach(each => {
                if (each.id !== chipToDelete.id) {
                    updatedChips.push(each)
                }
            })
        }

        aList.map(each => {
            if (each.columnId === field.columnId) {
                if (field.propertyDto.businessType === "C" && event.key === "Enter" && each[each.columnName + "_TEXT"]) {
                    each.tokens.push({
                        key: each[each.columnName + "_TEXT"],
                        text: each[each.columnName + "_TEXT"],
                        id: Math.random().toString()
                    })
                    each[each.columnName + "_TEXT"] = ""
                }
                else {
                    each[each.columnName + "_TEXT"] = event.target.value;
                    each[each.columnName] = event.target.value;

                }
                if (chipToDelete) {
                    each.tokens = updatedChips;
                }

            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)

    }
    const handledelete = (chipToDelete, field) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C" && field.propertyDto.isMandatory) {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "C" && !field.propertyDto.isMandatory) {
            aList = variantInstanceDetails.aFlexibleFormConditions
        }
        if (field.propertyDto.businessType === "A" && field.propertyDto.isMandatory) {
            aList = variantInstanceDetails.aActions
        }
        if (field.propertyDto.businessType === "A" && !field.propertyDto.isMandatory) {
            aList = variantInstanceDetails.aFlexibleFormActions
        }
        let aChipData = field.tokens;
        aChipData = aChipData.filter((chip) => chip.key !== chipToDelete.key);
        let updatedChips = [];
        aChipData.forEach(each => {
            if (each.key !== chipToDelete.key) {
                updatedChips.push(each)
            }
        })

    }
    const onDeleteCondition = (condition) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        variantInstanceDetails.aConditions.map((each, index) => {
            if (each.columnId === condition.columnId) {
                variantInstanceDetails.aConditions.splice(index, 1);
                condition.tokens = []
                variantInstanceDetails.aFlexibleFormConditions.push(condition);
            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const onDeleteAction = (action) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        variantInstanceDetails.aActions.map((each, index) => {
            if (each.columnId === action.columnId) {
                variantInstanceDetails.aActions.splice(index, 1);
                action.tokens = [];
                variantInstanceDetails.aFlexibleFormActions.push(action);
            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const onLookupSelection = (key, text) => {
        let field = structuredClone(column?.field);
        let currentToken = structuredClone(column.token);
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        aList.map((each, index) => {
            if (each.columnId === field.columnId) {
                if (field.propertyDto.businessType === "C" && variantInstanceDetails.isExpression) {
                    each.tokens.forEach((token, tokenIndex) => {
                        if (token.id === currentToken.id) {
                            if (token.operator === "EXISTSIN" || token.operator === "NOTEXISTSIN") {
                                token.data = key.map(value => {
                                    return {
                                        id: Math.random().toString(),
                                        key: value.key,
                                        text: value.value
                                    }
                                })
                            }
                            else {
                                token["key"] = key;
                                token["text"] = text
                            }
                        }
                    })
                }
                else if (field.propertyDto.businessType === "C" && !variantInstanceDetails.isExpression) {
                    each.tokens = key.map(value => {
                        return {
                            id: Math.random().toString(),
                            key: value.key,
                            text: value.value
                        }
                    })
                }
                else if (field.propertyDto.businessType === "A") {
                    each[field.columnName] = key;
                    each[field.columnName + "_TEXT"] = text;
                }
            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const handleValueHelp = (event, field, token) => {
        setCurrentColumn({ field: field, token: token });
        if (props.variantInstanceDetails.isExpression && field.propertyDto.businessType === "C") { setOperatorValue(token.operator) }
        else if (!props.variantInstanceDetails.isExpression && field.propertyDto.businessType === "C") {
            setOperatorValue("EXISTSIN")
        }
        else {
            setOperatorValue("")
        }
        LookupService({
            setLoading: props.setLoading,
            column: field,
            tableData: props.variantInstanceDetails,
            row: field,
            setSelectedrow: (row) => setSelectedrow(row),
            setValueHelpData: (data) => setValueHelpData(data),
            requestOptions: State.requestOptions,
            setShowLookup: (val) => setShowLookup(val)

        })
    }
    const onCloseLookup = () => {
        setShowLookup(false)
    }

    const fnOpenOperatorsPopup = (event, field, token) => {
        setShowOperatorsPopover(true)
        setOperatorsPopoverAnchor(event.currentTarget)
        setOperatorsPopupDetails({
            column: field,
            operator: token.operator,
            operatorsList: field.operatorsList,
            token: token
        })
    }
    const handleDateSelection = (event, field, token) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        aList.map(each => {
            if (each.columnId === field.columnId) {
                if (token) {
                    each.tokens.map(element => {
                        if (element.id === token.id) {
                            element.text = event.target.value;
                            element.key = event.target.value;
                        }
                    })
                }
                else {
                    each.tokens = [{
                        key: event.target.value,
                        text: event.target.value,
                        id: Math.random().toString()
                    }]
                    each[each.columnName + "_TEXT"] = event.target.value
                }

            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const fnAddNewRow = (field, DeletedToken) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        aList.map((each, index) => {
            if (each.columnId === field.columnId) {
                DeletedToken ? each.tokens.forEach((token, tokenIndex) => {
                    token.id === DeletedToken.id && each.tokens.splice(tokenIndex, 1)
                }) :
                    each.tokens.push({
                        key: null,
                        text: null,
                        id: Math.random().toString(),
                        operator: field.propertyDto.operator ? field.propertyDto.operator : null
                    })
            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const fnOpenPopover = (event, aChipData, field, token) => {
        setOpenPopover(true);
        handleAnchorElement(event.currentTarget)
        setTokensData({ field: field, token: token, aChipData: aChipData })
    }
    const TokensPopover =
        <Popover
            placement="bottom"
            open={openPopover}
            anchorEl={anchorElement}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: "true",
                    boundariesElement: 'scrollParent'
                },
            }}
        >
            <ClickAwayListener onClickAway={() => {
                setOpenPopover(false);
                handleAnchorElement(null)
            }}>
                <div style={{ backgroundColor: 'white' }}>
                    {
                        TokensData?.aChipData?.map((option, index) => {

                            return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ margin: '0 0.3rem 0 ' }}>{option.text}</p>
                                <IconButton className="styleRedButton" size="small" onClick={(event) => { props.variantInstanceDetails.isExpression ? handleChangeValueForArray(event, TokensData.field, TokensData.token, option) : onChangeValue(event, TokensData.field, option); setOpenPopover(false) }}>  <CustomIcon style={{ width: '1.2rem', height: '1.2rem' }} icon={"MaterialIcon.MdOutlineClear"} /></IconButton>
                            </div>

                        })
                    }
                </div>
            </ClickAwayListener>
        </Popover>

    const getElementForExpDT = (field, token) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [], control;
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        aList.map(each => {
            if (each.columnId === field.columnId) {
                each.tokens.forEach(element => {

                    if (token.id === element.id) {
                        if (token.operator === "EXISTSIN" || token.operator === "NOTEXISTSIN") {
                            let aChipData = element.data;
                            control = <TextField
                                autoComplete="off"
                                className="styleDTname"
                                value={element.text}
                                style={{ width: '13rem' }}
                                onChange={event => handleChangeValueForArray(event, field, token)}
                                onKeyUp={event => handleChangeValueForArray(event, field, token)}
                                InputProps={
                                    {
                                        readOnly: field.propertyDto.isLookup,
                                        limitTags: 1,
                                        endAdornment: (<InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className='buttonStyle'
                                                onClick={field.propertyDto.isLookup && ((event) => handleValueHelp(event, field, token))}
                                            >
                                                {field.propertyDto.isLookup && <CustomIcon style={{
                                                    width: '0.9rem',
                                                    height: '1rem',
                                                    marginRight: '2px'
                                                }
                                                } icon={"SVGIcons.LookUp"} />}
                                            </IconButton>
                                        </InputAdornment>),
                                        startAdornment:
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {aChipData.length === 1 && <Tooltip title={aChipData[0].text}><Chip className="styleChip" variant="outlined" label={aChipData[0].text} onDelete={(event) => handleChangeValueForArray(event, field, token, aChipData[0])} /></Tooltip>}
                                                    {aChipData.length > 1 && <Link onClick={(event) => fnOpenPopover(event, aChipData, field, token)} component="button" variant="body2" style={{ width: '2.8rem', marginLeft: '5px' }}
                                                    >
                                                        {aChipData.length} more
                                                    </Link>
                                                    }

                                                </div>

                                            </div>
                                        // aChipData && aChipData.map(option => <Chip variant="outlined" label={option.text} onDelete={(event) => handleChangeValueForArray(event, field, token, option)} style={{ height: 'auto' }} />)
                                    }
                                }
                            />
                        }
                        else if (token.operator === "IN" || token.operator === "NOTIN") {
                            control = <TextField
                                autoComplete="off"
                                className="styleDTname"
                                value={element.text}
                                style={{ width: '13rem' }}
                                InputProps={
                                    {
                                        readOnly: true,
                                        limitTags: 1,
                                        endAdornment: (<InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className='buttonStyle'
                                                onClick={(event) => fnOpenRangeOperatorsPopup(event, field, token)}
                                            >
                                                <CustomIcon style={{
                                                    width: '0.9rem',
                                                    height: '1rem',
                                                    marginRight: '2px'
                                                }
                                                } icon={"MaterialIcon.MdModeEdit"} />
                                            </IconButton>
                                        </InputAdornment>)

                                    }
                                }
                            />
                        }
                        else {
                            switch (field.dataType) {
                                case "DATE":
                                    control = <div style={{ width: '13rem' }}><DatePicker value={element.text} column={field} handleDateSelection={(event) => handleDateSelection(event, field, token)} source="RuleList" /></div>
                                    break;
                                case "TIMESTAMP":
                                    control = <div style={{ width: '13rem' }}><DateTimePicker value={element.text} column={field} handleDateSelection={(event) => handleDateSelection(event, field, token)} source="RuleList" /></div>
                                    break;
                                default:

                                    control = <TextField
                                        autoComplete="off"
                                        className="styleDTname"
                                        value={element.text}
                                        style={{ width: '13rem' }}
                                        onChange={event => handleValueChangeForExpFields(event, field, token)}
                                        InputProps={
                                            {
                                                readOnly: field.propertyDto.isLookup,
                                                limitTags: 1,
                                                endAdornment: (<InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className='buttonStyle'
                                                        onClick={field.propertyDto.isLookup && ((event) => handleValueHelp(event, field, token))}
                                                    >
                                                        {field.propertyDto.isLookup && <CustomIcon style={{
                                                            width: '0.9rem',
                                                            height: '1rem',
                                                            marginRight: '2px'
                                                        }
                                                        } icon={"SVGIcons.LookUp"} />}
                                                    </IconButton>
                                                </InputAdornment>)
                                            }
                                        }
                                    />
                            }
                        }
                    }
                })

            }
        })
        return control;
        // props.setVariantInstanceDetails(variantInstanceDetails)

    }

    // on change value for array operator fields
    const handleChangeValueForArray = (event, field, token, chipToDelete) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [], updatedChips = [], aChipData = token.data;
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        if (chipToDelete) {
            aChipData = aChipData.filter((chip) => chip.id !== chipToDelete.id);
            aChipData.forEach(each => {
                if (each.id !== chipToDelete.id) {
                    updatedChips.push(each)
                }
            })
        }
        aList.map(each => {
            if (each.columnId === field.columnId) {
                each.tokens.map(element => {
                    if (element.id === token.id) {
                        element.text = event.target.value;
                        if (event.key === "Enter" && event.target.value) {
                            element.text = "";
                            element.data.push({
                                id: Math.random().toString(),
                                text: event.target.value,
                                key: event.target.value
                            })

                        }
                        if (chipToDelete) {
                            element.data = updatedChips;
                        }
                    }
                })



            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }


    // on click of edit icon for range operator fields
    const fnOpenRangeOperatorsPopup = (event, field, token) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        setShowRangeOperatorsPopup(true)
        setRangeOperatorsPopoverAnchor(event.currentTarget)
        let fromValue = null, toValue = null;
        if (token.text) {
            if (field.dataType === "NVARCHAR" || field.dataType === "DATE" || field.dataType === "TIMESTAMP") {
                fromValue = token.text.slice(2, token.text.indexOf(".") - 1)
                toValue = token.text.slice(token.text.lastIndexOf(".") + 2, token.text.length - 2)
            }
            else {
                fromValue = token.text.slice(1, token.text.indexOf("."))
                toValue = token.text.slice(token.text.lastIndexOf(".") + 1, token.text.length - 1)
            }
        }
        setRangeOperatorPopupDetails({
            fromValue: fromValue,
            toValue: toValue,
            fromSelection: token.text ? token.text.charAt(0) : "[",
            toSelection: token.text ? token.text.charAt(token.text.length - 1) : "]",
            field: field,
            token: token,
            fromValueErrorState: "None",
            fromValueError: "",
            toValueErrorState: "None",
            toValueError: "",

        })

    }

    // on change value for Functional / Comparision operator fields  
    const handleValueChangeForExpFields = (event, field, token) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        aList.map(each => {
            if (each.columnId === field.columnId) {
                each.tokens.map(element => {
                    if (element.id === token.id) {
                        element.text = event.target.value;
                        element.key = event.target.value
                    }
                })



            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
    }
    const getElement = (field, type) => {
        let element;
        let aChipData = field.tokens;
        element = <TextField
            autoComplete="off"
            onKeyUp={(event) => onChangeValue(event, field)}
            variant="outlined"
            onChange={(event) => onChangeValue(event, field)}
            value={field[field.columnName + "_TEXT"]}
            className="styleDTname"
            style={{ width: '13rem' }}
            // style={{ width: '100%' }}
            // type={type}
            error={field[field.columnName + "_VALIDATION_STATUS"] === "Error" ? true : false}
            helperText={field[field.columnName + "_VALIDATION_ERROR"]}
            InputProps={
                {
                    readOnly: field.propertyDto.isLookup,
                    limitTags: 1,
                    endAdornment: (<InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            className='buttonStyle'
                            onClick={field.propertyDto.isLookup && ((event) => handleValueHelp(event, field))}
                        >
                            {field.propertyDto.isLookup && <CustomIcon style={{
                                width: '0.9rem',
                                height: '1rem',
                                marginRight: '2px'
                            }
                            } icon={"SVGIcons.LookUp"} />}
                        </IconButton>
                    </InputAdornment>),
                    startAdornment:
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {aChipData.length === 1 && <Tooltip title={aChipData[0].text}><Chip className="styleChip" variant="outlined" label={aChipData[0].text} onDelete={(event) => onChangeValue(event, field, aChipData[0])} /></Tooltip>}
                                {aChipData.length > 1 && <Link onClick={(event) => fnOpenPopover(event, aChipData, field)} component="button" variant="body2" style={{ width: '2.8rem', marginLeft: '5px' }}
                                >
                                    {aChipData.length} more
                                </Link>
                                }

                            </div>

                        </div>
                    // aChipData && aChipData.map(option => <Chip variant="outlined" label={option.text?.toString()} onDelete={(event) => onChangeValue(event, field, option)} style={{ height: 'auto' }} />)
                }
            }
        />
        if (field.dataType === "DATE") {
            element = <div style={{ width: '13rem' }}>
                <DatePicker value={field[field.columnName + "_TEXT"]} column={field} handleDateSelection={(event) => handleDateSelection(event, field)} source="RuleList" />
            </div>
        }
        if (field.dataType === "TIMESTAMP") {
            element = <div style={{ width: '13rem' }}><DateTimePicker value={field[field.columnName + "_TEXT"]} column={field} handleDateSelection={(event) => handleDateSelection(event, field)} source="RuleList" /></div>
        }
        if (field.propertyDto.businessType === "C" && props.variantInstanceDetails.isExpression) {
            let x = <div>
                {field.tokens.map((token, tokenIndex) => {
                    return (<div style={{ display: 'flex', alignItems: "center", marginBottom: '0.5rem' }}><TextField autoComplete="off"
                        style={{ width: '13rem' }}
                        variant="outlined"
                        value={token.operator}
                        className="styleDTname"
                        disabled={field.propertyDto.operator}
                        InputProps={
                            {
                                readOnly: true,
                                limitTags: 1,
                                endAdornment: (<InputAdornment position="end">
                                    <IconButton
                                        disabled={field.propertyDto.operator}
                                        aria-label="toggle password visibility"
                                        className='buttonStyle'
                                        onClick={(event) => fnOpenOperatorsPopup(event, field, token)}
                                    >
                                        <CustomIcon style={{
                                            width: '0.9rem',
                                            height: '1rem',
                                            marginRight: '2px'
                                        }
                                        } icon={"MaterialIcon.MdExpandMore"} />
                                    </IconButton>
                                </InputAdornment>)
                            }
                        }
                    />
                        {/* {element} */}
                        {token.operator && getElementForExpDT(field, token)}
                        {tokenIndex === field.tokens.length - 1 && <IconButton
                            className='buttonStyle'
                            onClick={(event) => fnAddNewRow(field)}
                        >
                            <CustomIcon style={{
                                width: '0.9rem',
                                height: '1rem',
                                marginRight: '2px'
                            }
                            } icon={"MaterialIcon.MdAdd"} />
                        </IconButton>}
                        {field.tokens.length > 1 && <IconButton
                            className='buttonStyle'
                            onClick={(event) => fnAddNewRow(field, token)}
                        >
                            <CustomIcon style={{
                                width: '0.9rem',
                                height: '1rem',
                                marginRight: '2px'
                            }
                            } icon={"BoxIcon.BiMinus"} />
                        </IconButton>
                        }
                    </div>)
                })}
            </div>
            return x;
        }
        else {

        }
        return element;
    }

    const handleOperatorSelection = (field, operator, token) => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        if (field.propertyDto.businessType === "C") {
            aList = variantInstanceDetails.aConditions
        }
        if (field.propertyDto.businessType === "A") {
            aList = variantInstanceDetails.aActions
        }
        aList.map(each => {
            if (each.columnId === field.columnId) {
                each.tokens.forEach(element => {
                    if (element.id === token.id) {
                        element.operator = operator;
                        if (operator === "EXISTSIN" || operator === "NOTEXISTSIN") element.data = []
                    }
                })
            }
        })
        props.setVariantInstanceDetails(variantInstanceDetails)
        setShowOperatorsPopover(false)
    }
    const discardRangeOperatorsData = () => {
        setShowRangeOperatorsPopup(false)
    }
    const saveRangeOperatorsData = () => {
        let variantInstanceDetails = structuredClone(props.variantInstanceDetails);
        let aList = [];
        let field = RangeOperatorPopupDetails.field, token = RangeOperatorPopupDetails.token, value = "";
        let bValidation = true;
        let details = { ...RangeOperatorPopupDetails }
        if (!RangeOperatorPopupDetails.fromValue) {
            bValidation = false;
            details["fromValueErrorState"] = "Error";
            details["fromValueError"] = "Please fill the data";

        }
        if (!RangeOperatorPopupDetails.toValue) {
            bValidation = false;
            details["toValueErrorState"] = "Error";
            details["toValueError"] = "Please fill the data";
        }
        if (RangeOperatorPopupDetails.fromValue && RangeOperatorPopupDetails.toValue && RangeOperatorPopupDetails.toValue < RangeOperatorPopupDetails.fromValue) {
            bValidation = false
            details["fromValueErrorState"] = "Error";
            details["fromValueError"] = "To Value should be greater than From Value";
            details["toValueErrorState"] = "Error";
            details["toValueError"] = "To Value should be greater than From Value";
        }
        if (bValidation) {
            if (field.dataType === "NVARCHAR" || field.dataType === "DATE" || field.dataType === "TIMESTAMP") {
                RangeOperatorPopupDetails.fromValue = "'" + RangeOperatorPopupDetails.fromValue + "'";
                RangeOperatorPopupDetails.toValue = "'" + RangeOperatorPopupDetails.toValue + "'"
            }
            value = RangeOperatorPopupDetails.fromSelection + RangeOperatorPopupDetails.fromValue + ".." + RangeOperatorPopupDetails.toValue + RangeOperatorPopupDetails.toSelection;
            if (field.propertyDto.businessType === "C") {
                aList = variantInstanceDetails.aConditions
            }
            if (field.propertyDto.businessType === "A") {
                aList = variantInstanceDetails.aActions
            }
            aList.map(each => {
                if (each.columnId === field.columnId) {
                    each.tokens.forEach(element => {
                        if (element.id === token.id) {
                            element.text = value;
                            element.key = value
                        }
                    })
                }
            })
            props.setVariantInstanceDetails(variantInstanceDetails)
            setShowRangeOperatorsPopup(false)
        }
        else {
            setRangeOperatorPopupDetails(details)
        }

    }
    const handleChangeRangeOperatorValues = (event, property) => {
        let details = { ...RangeOperatorPopupDetails }
        details[property] = event.target.value;
        setRangeOperatorPopupDetails(details);
    }
    const getElementForRangeOperator = (property) => {
        let control, type;
        switch (RangeOperatorPopupDetails.field.dataType) {
            case "DATE":
                type = "date"
                break;
            case "TIMESTAMP":
                type = "datetime-local"
                break;
            default:
                type = "text"

        }
        control = <TextField className="styleDTname" type={type} value={RangeOperatorPopupDetails[property]} onChange={(event) => handleChangeRangeOperatorValues(event, property)}
            error={RangeOperatorPopupDetails[property + "ErrorState"] === "Error" ? true : false}
            helperText={RangeOperatorPopupDetails[property + "Error"]}
        />
        return control;
    }

    // on selection of exclude/include in range operator popover
    const handleChangeSelection = (event, selection) => {
        let details = { ...RangeOperatorPopupDetails }
        details[selection] = event.target.value;
        setRangeOperatorPopupDetails(details);
    }
    const RangeOperatorPopover = <div style={{ backgroundColor: 'white' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
            <p style={{ margin: 0 }}>From</p>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ height: '1.5rem' }}
                name="from selection"
                onChange={(event) => handleChangeSelection(event, "fromSelection")}
                value={RangeOperatorPopupDetails.fromSelection}
            >
                <MenuItem value="[" key="["  >Include</MenuItem>
                <MenuItem value="(" key="("  >Exclude</MenuItem>
            </Select>
            {getElementForRangeOperator("fromValue")}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ margin: 0 }}>To</p>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ height: '1.5rem' }}
                name="to selection"
                onChange={(event) => handleChangeSelection(event, "toSelection")}
                value={RangeOperatorPopupDetails.toSelection}
            >
                <MenuItem value="]" key="]"  >Include</MenuItem>
                <MenuItem value=")" key=")"  >Exclude</MenuItem>
            </Select>
            {getElementForRangeOperator("toValue")}
        </div>
        <div className=" styleFooter" style={{ backgroundColor: 'white', boxShadow: '0rem 0.438rem 0.5rem -0.25rem rgba(0, 0, 0, 0.2), 0rem 0.75rem 1.063rem 0.125rem rgba(0, 0, 0, 0.14), 0rem 0.313rem 1.375rem 0.25rem rgba(0, 0, 0, 0.12)', borderRadius: '0rem 0rem 0.5rem 0.5rem' }}>
            <Button disableRipple variant="text" color="primary" style={{ textTransform: 'none' }} onClick={discardRangeOperatorsData}>Cancel</Button>
            <Button disableRipple variant="contained" style={{ marginLeft: '0.5rem', textTransform: 'none', marginRight: '0.5rem', marginTop: '0.5rem', marginBottom: '0.5rem', backgroundColor: '#007AD4' }} color="primary" onClick={saveRangeOperatorsData}>save</Button>
        </div>
    </div>

    const handleChangeRuleListDetails = (event, property) => {
        let RuleListDetails = { ...props.RuleListDetails };
        RuleListDetails[property] = event.target.value;
        props.setRuleListDetails(RuleListDetails)
    }
    const fnDiscardRuleList = () => {
        let variantInstanceDetails = { ...props.variantInstanceDetails }
        props.setShowRuleListCreationView(false)
    }
    const closeComments = () => {
        props.setCommentsDetails(
            { ...props.CommentsDetails, "show": false }
        )
        setAnchorElementComments(null)

    }
    const fnOpenComments = (event) => {
        props.setCommentsDetails(
            { "show": true, "data": props.CommentsDetails.data, "row": {} }
        )
        setAnchorElementComments(event.currentTarget)
    }
    const fnaddComment = (comment) => {
        let comments = { ...props.CommentsDetails }
        comments.data.push({
            createdBy: State.userDetails.user_id,
            description: comment,
            createdOn: new Date().toISOString().slice(0, 19)

        })
        props.setCommentsDetails(comments)
    }
    return (<div style={{ height: '100%' }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ margin: '0.3rem 0.5rem' }}>
            <Link color="inherit" href="/" className="styleLink">
                {State.oSelectedDetails.oAppDetails.label ? State.oSelectedDetails.oAppDetails.label : State.oSelectedDetails.oAppDetails.name}
            </Link>
            <Link color="inherit" className="styleLink">
                {State.oSelectedDetails.oSelectedRMS.label}
            </Link>
            <Link color="inherit" className="styleLink">
                {State.oSelectedDetails.oSelectedRS.label}
            </Link>
            {/* <Link
                color="textPrimary"
                aria-current="page"
                className="styleLink"
                style={{ color: '#007AD4' }}
            >
                {State.oSelectedDetails.oSelectedDT.label}
            </Link> */}

        </Breadcrumbs>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
            <CustomIcon style={{ marginRight: '0.25rem', height: '0.875rem', width: '0.875rem' }} icon={"SVGIcons.Vector"} />
            <p style={{ margin: '0 0.5rem' }}>Add New Rule</p>
        </div>
        <div style={{
            background: '#FFFFFF', borderRadius: "8px", width: 'calc(100% - 1rem)',
            margin: '0.5rem', height: 'calc(100% - 5rem)',
            overflowY: 'scroll'
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', padding: '0.5rem', borderBottom: '1px solid #E0E0E0', marginBottom: '0.5rem' }}>
                <p style={{ margin: 0, fontWeight: '500' }}> Rule Details</p>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Button startIcon={<CustomIcon icon={"Feather.FiSave"} />} className="styleButton styleStaticFormButtons" style={{ marginRight: "1rem" }} onClick={() => fnSaveRuleList("Save")}>Save</Button>
                    <Button startIcon={<CustomIcon icon={"BootstrapIcon.BsFileEarmarkCheck"} />} className="styleButton styleStaticFormButtons" style={{ marginRight: "1rem" }} onClick={() => fnSaveRuleList("Submit")}>Submit</Button>
                    <Button startIcon={<CustomIcon icon={"MaterialIcon.MdOutlineDeleteOutline"} />} className="styleButton styleStaticFormButtons" style={{ marginRight: "1rem" }} onClick={() => fnDiscardRuleList()}>Discard</Button>
                    <Button startIcon={<CustomIcon icon={"MaterialIcon.MdOutlineModeComment"} />} className="styleButton styleStaticFormButtons" onClick={(event) => fnOpenComments(event)}>Show Comments</Button>
                </div>
            </div>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} p={0} alignItems="center">
                <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }}><p style={{ margin: '0 0.5rem' }}>1.Rule Name</p></Stack>
                <Stack direction="row" style={{ padding: '0 1rem 0 0rem' }} width={{ xs: "100%", sm: "66%", md: '66%' }}> <TextField style={{ width: '13rem' }} autoComplete="off" value={props.RuleListDetails.name} className="styleDTname" onChange={(event) => handleChangeRuleListDetails(event, "name")} /></Stack>
            </Stack>
            <p style={{ margin: '0.5rem' }}>2.Conditions</p>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }} p={0} alignItems="flex-start" justifyContent="flex-start">
                {
                    props.variantInstanceDetails.aConditions?.map(function (condition) {
                        return (
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} p={0} alignItems="start" style={{ width: "100%" }}>
                                <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                                    <p style={{ margin: "0.5rem", fontSize: '0.875rem' }}>{condition.label}</p>
                                </Stack>
                                <Stack direction="row" width={{ xs: "100%", sm: "35%", md: '35%' }}>
                                    {/* <TextField className="styleDTname" /> */}
                                    {getElement(condition)}
                                </Stack>
                                <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                                    <IconButton disabled={condition.propertyDto.isMandatory} size="small" className="styleButton styleRedButton" onClick={() => onDeleteCondition(condition)}>
                                        <CustomIcon style={{ width: '1.3rem', height: '1.3rem' }} icon={"BoxIcon.BiMinus"} />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        )
                    })
                }
            </Stack>
            {props.decisionTableFormType === "Flexible" && props.variantInstanceDetails.aFlexibleFormConditions.length !== 0 &&
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} p={0} alignItems="start" style={{ width: "100%" }}>
                    <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                    </Stack>
                    <Stack direction="row" width={{ xs: "100%", sm: "35%", md: '35%' }}><Select
                        className="styleSelect styleFlexibleDropdown "
                        value={selectedFlexibleCondition}
                        style={{ width: '13rem' }}
                    >
                        {
                            props.variantInstanceDetails.aFlexibleFormConditions.map(each => {
                                return <MenuItem key={each} onClick={(event) => onSelectFlexibleConditon(event, each)}>{each.label}</MenuItem>
                            })
                        }
                    </Select>
                    </Stack>
                </Stack>
            }
            <p style={{ margin: '0.5rem' }}>3.Validity Period</p>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }} p={0} alignItems="flex-start" justifyContent="flex-start">
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} p={0} alignItems="center" style={{ width: "100%" }}>
                    <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                        <p style={{ margin: "0.5rem", fontSize: '0.875rem' }}>Validity From</p>
                    </Stack>
                    <Stack direction="row" width={{ xs: "100%", sm: "35%", md: '35%' }}>
                        <TextField style={{ width: '13rem' }} className="styleDTname" type="date" value={props.RuleListDetails.validityFromDate} onChange={(event) => handleChangeRuleListDetails(event, "validityFromDate")} />
                    </Stack>

                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} p={0} alignItems="center" style={{ width: "100%" }}>
                    <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                        <p style={{ margin: "0.5rem", fontSize: '0.875rem' }}>Validity To</p>
                    </Stack>
                    <Stack direction="row" width={{ xs: "100%", sm: "35%", md: '35%' }}>
                        <TextField style={{ width: '13rem' }} className="styleDTname" type="date" value={props.RuleListDetails.validityToDate} onChange={(event) => handleChangeRuleListDetails(event, "validityToDate")} />
                    </Stack>

                </Stack>

            </Stack>

            <p style={{ margin: '0.5rem' }}>4.Actions</p>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }} p={0} alignItems="flex-start" justifyContent="flex-start">
                {
                    props.variantInstanceDetails.aActions?.map(function (action) {
                        return (
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} p={0} alignItems="center" style={{ width: "100%" }}>
                                <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                                    <p style={{ margin: "0.5rem", fontSize: '0.875rem' }}>{action.label}</p>
                                </Stack>
                                <Stack direction="row" width={{ xs: "100%", sm: "35%", md: '35%' }}>
                                    {/* <TextField className="styleDTname" /> */}
                                    {getElement(action)}
                                </Stack>
                                <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                                    <IconButton disabled={action.propertyDto.isMandatory} size="small" className="styleButton styleRedButton" onClick={() => onDeleteAction(action)}>
                                        <CustomIcon style={{ width: '1.3rem', height: '1.3rem' }} icon={"BoxIcon.BiMinus"} />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        )
                    })
                }
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 1 }} p={0} alignItems="start" style={{ width: "100%" }}>
                <Stack direction="row" style={{ padding: '0 1rem 0 0' }} width={{ xs: "100%", sm: "30%", md: '30%' }} justifyContent={{ xs: "flex-start", md: "flex-end", sm: "flex-end" }}>
                </Stack>
                <Stack direction="row" width={{ xs: "100%", sm: "35%", md: '35%' }}>
                    {props.decisionTableFormType === "Flexible" && props.variantInstanceDetails.aFlexibleFormActions.length !== 0 && <Select
                        className="styleSelect styleFlexibleDropdown"
                        value={selectedFlexibleAction}
                        style={{ width: '13rem' }}
                    >
                        {
                            props.variantInstanceDetails.aFlexibleFormActions.map(each => {
                                return <MenuItem key={each} onClick={(event) => onSelectFlexibleAction(event, each)}>{each.label}</MenuItem>
                            })
                        }
                    </Select>
                    }
                </Stack>
            </Stack>
        </div>
        {TokensPopover}
        {
            showOperatorsPopover && <Popover
                placement="bottom"
                open={showOperatorsPopover}
                anchorEl={OperatorsPopoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: "true",
                        boundariesElement: 'scrollParent'
                    },
                }}
            >
                <ClickAwayListener onClickAway={() => setShowOperatorsPopover(false)}>

                    <div style={{
                        padding: '0.5rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <OperatorsPopup setShowOperatorsPopover={setShowOperatorsPopover} operator={operatorsPopupDetails.operator} column={operatorsPopupDetails.column} operatorsList={operatorsPopupDetails.operatorsList} source="RuleList" handleOperatorSelection={(field, operator, token) => handleOperatorSelection(field, operator, token)} token={operatorsPopupDetails.token} />

                    </div>
                </ClickAwayListener>
            </Popover >
        }
        {
            showRangeOperatorsPopup && <Popover
                placement="bottom"
                open={showRangeOperatorsPopup}
                anchorEl={RangeOperatorsPopoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: "true",
                        boundariesElement: 'scrollParent'
                    },
                }}
            >

                <div style={{
                    padding: '0.5rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {/* <RangeOperatorPopover /> */}
                    {RangeOperatorPopover}

                </div>
            </Popover >
        }
        {showLookup && <LookUpTable source="RuleList" open={showLookup} handleClose={onCloseLookup} valueHelpData={valueHelpData} column={column.field} operatorValue={operatorValue} onLookupSelection={(key, text) => onLookupSelection(key, text)} ValueHelpTitle={ValueHelpTitle} tableData={props.variantInstanceDetails} />}
        <MessageDialog setMessageDialog={setMessageDialog} MessageDialogContent={MessageDialogContent} />
        <MessageStrip MessageStripData={MessageStripData} setMessageStrip={setMessageStrip} />
        <BusyLoader show={Loading}></BusyLoader>
        <Popover
            className="styleCommentsPopover"
            placement="bottom"
            open={props.CommentsDetails.show}
            anchorEl={anchorElementComments}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: "true",
                    boundariesElement: 'scrollParent'
                },
            }}
        >
            <div style={{ width: '100%' }}>

                <div style={{ backgroundColor: 'white' }}> {props.CommentsDetails.show && <Comments
                    data={props.CommentsDetails.data}
                    addComment={(comment, source, row) =>
                        fnaddComment(comment)
                    }
                    CommentsDetails={props.CommentsDetails}
                    onClose={() => closeComments()}
                />}
                </div>
            </div>
        </Popover>

    </div >)
}
export default RuleListCreation;