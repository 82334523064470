import { Avatar } from "@mui/material";
import './Avatar.css'
const DisplayAvatar = (props) =>{
    let avatarName = props.name;
    if (props.name?.split(" ")[0]) {
        avatarName = props.name?.split(" ")[0][0]
    }
    if (props.name?.split(" ")[1]) {
        avatarName = avatarName + props.name?.split(" ")[1][0]
    }
    return ( <Avatar style={{ width: '2rem', height: '2rem' }} className="styleAvartar" >{avatarName}</Avatar>)
    
}
export default DisplayAvatar;