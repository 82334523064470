import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import FormatDateobjToString from '../functions/cw_wr_DateTimeFormatter';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
let defaultValue;

const DateTimePicker =(props) => {
    defaultValue  = <FormatDateobjToString milliSec={props.value}/>
    const classes = useStyles();
    const onChangeDateTimePicker = (event) => {
      console.log(props);
      let updatedValue ="";
      if (!props.source) {
        if(props.tableData.isExpression && !props.column.propertyDto.isOperatorColumn){
          updatedValue = props.operatorValue + event.target.value
        }
        props.fnChangeDTinputCell(updatedValue, null, props.operatorValue, props.params.row, props.column);
      }
      if(props.source === "HeaderFilter"){
        props.setValue(event.target.value,props.column,true)
      }
      if (props.source === "To" || props.source === "From" || props.source === "MassAddvalidity" || props.source === "MassAddConditions" || props.source === "ColumnFilter") {
        
        props.setValue(event.target.value)
  
      }
      if(props.source === "RuleList"){
        props.handleDateSelection(event)
      }
     }
return (<TextField
    id="datetime-local"
    type="datetime-local"
    defaultValue={defaultValue}
    variant='outlined'
    disabled={( props.column.propertyDto.isEditable) && !(props.params?.row.STATUS==="Deactivated" )? false : true}
    style={{width:'100%'}}
    className="styleInput "
    onChange={onChangeDateTimePicker}
    InputLabelProps={{
      shrink: true,
    }}
  />)
}
export default DateTimePicker;
