import { Modelling } from '@cw/rule-generator';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import features from "../../utility/features.json";

const LandingPageModelling = (props) => {
    const dispatch = useDispatch();
    const State = useSelector(state => state.rootReducer);
 
    let userDetails = {
        emailId: State.userDetails.emailId,
        user_id: State.userDetails.user_id
    }
    if(State.requestOptions.headers?.Authorization){   
        let token = State.requestOptions.headers?.Authorization?.replaceAll("Bearer", "").trim();
        return ( State.requestOptions?.headers?.Authorization?.length > 0 && <Modelling features={features} translationDataObjects={State.worktextResponse} userDetails={userDetails} token={token} destinations={State.destinationsList} />)
    }
    else{
        return <Modelling translationDataObjects={State.worktextResponse} userDetails={userDetails} destinations={State.destinationsList} />
    }
}
export default LandingPageModelling;
