const PostOrder = function (root) {
    let res = [];
    
    const traverse = node => {
      if (!node) return;
      if (node.childs) {
        let len = node.childs.length;
        for (let i = 0; i < len; i++) {
          traverse(node.childs[i])
        }
      }
      res.push(node);
    }
    traverse(root)
    return res;
  };
export default PostOrder;